import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { PaymentDetailInterface } from "./models/PaymentDetailInterface";
import {
  createPayment,
  deletePayment,
  getApplyCreditsList,
  getPaymentDetail,
  getPaymentList,
  updateApplyCreditsList,
  updatePayment,
} from "./PaymentAPI";

export interface PaymentStateInterface {
  payments: PaymentDetailInterface[];
  favourites: PaymentDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: PaymentDetailInterface;
  applyCredits : any
}

const initialState: PaymentStateInterface = {
  payments: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
  },
  applyCredits : []
};

export const getPaymentListAsync = createAsyncThunk(
  "payment/list",
  async (data: any) => {
    const response = await getPaymentList(data);
    return response.data;
  }
);
export const getPaymentDetailAsync = createAsyncThunk(
  "payment/detail",
  async (index: string) => {
    const response = await getPaymentDetail(index);
    return response.data;
  }
);

export const createPaymentAsync = createAsyncThunk(
  "payment/create",
  async (data: PaymentDetailInterface) => {
    const response = await createPayment(data);
    return response.data;
  }
);

export const deletePaymentAsync = createAsyncThunk(
  "payment/delete",
  async (index: string) => {
    const response = await deletePayment(index);
    return response.data;
  }
);

export const updatePaymentAsync = createAsyncThunk(
  "payment/update",
  async (data: { index: string; data: PaymentDetailInterface }) => {
    const response = await updatePayment(data.index, data.data);
    return response.data;
  }
);

export const getApplyCreditsListAsync = createAsyncThunk(
  "applyCredits/list",
  async (id: string) => {
    const response = await getApplyCreditsList(id);
    return response.data;
  }
);

export const updateApplyCreditsListAsync = createAsyncThunk(
  "applyCredits/update",
  async (data: any) => {
    const response = await updateApplyCreditsList(data);
    return response.data;
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getPaymentListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPaymentListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.payments = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getPaymentListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getPaymentDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPaymentDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getPaymentDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createPaymentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPaymentAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createPaymentAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updatePaymentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePaymentAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updatePaymentAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deletePaymentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePaymentAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deletePaymentAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET Apply Credits DETAIL
      .addCase(getApplyCreditsListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getApplyCreditsListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.applyCredits = action.payload;
      })
      .addCase(getApplyCreditsListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE Apply Credits
      .addCase(updateApplyCreditsListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateApplyCreditsListAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.applyCredits = action.payload;
      })
      .addCase(updateApplyCreditsListAsync.rejected, (state) => {
        state.status = "failed";
      })
  },
});

export const {} = paymentSlice.actions;
export const PaymentState = (state: RootState) => state.payment;
export default paymentSlice.reducer;
