import { useEffect, useState } from "react"
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import {
  getPaymentListAsync,
  PaymentState,
  updateApplyCreditsListAsync,
} from "../PaymentSlice"
import { getApplyCreditsList } from "../PaymentAPI"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import { useAlert } from "react-alert"
import DatePicker from "react-datepicker"
/**
 * Paymenting list page
 */
const PaymentList = () => {
  const { payments, status, totalRecord , applyCredits } = useAppSelector(PaymentState)
  const [page, setPage] = useState(1)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [ContestPayementId, setContestPayementId] = useState("")
  const [searchText, setSearchText] = useState("")
  const alert = useAlert()
  const [applyContests, setApplyContests] = useState<any>({})
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    getPageData()
    setErrorMessage("")
  }, [dispatch, page , applyCredits])

  const getPageData = async () => {
    const filter = {
      page: page,
    }
    await dispatch(getPaymentListAsync(filter))
  }

  const UpdateContestCredit = async () => {
    var _totalAssignCredits = 0
    for (let i = 0; i < applyContests?.ContestCredits?.length; i++) {
      _totalAssignCredits =
        _totalAssignCredits + parseInt(applyContests?.ContestCredits[i]?.credit)
    }
    for (let i = 0; i < applyContests?.open_contests?.length; i++) {
      _totalAssignCredits =
        _totalAssignCredits + parseInt(applyContests?.open_contests[i]?.credit)
    }
    if (_totalAssignCredits != applyContests.credit) {
      setErrorMessage(
        "Contest credit total and total media credits Must be similar"
      )
    } else {
      if (_totalAssignCredits > applyContests?.amount / 1.5) {
        setErrorMessage("total media credits is higher than actual credit")
      } else {
        var _contests = []

        for (let i = 0; i < applyContests.ContestCredits.length; i++) {
          _contests.push({
            contest_id: applyContests.ContestCredits[i]?.Contest?.contest_id,
            credit: parseInt(applyContests.ContestCredits[i]?.credit),
          })
        }

        for (let i = 0; i < applyContests.open_contests.length; i++) {
          _contests.push({
            contest_id: applyContests.open_contests[i]?.contest_id,
            credit: parseInt(applyContests.open_contests[i]?.credit),
          })
        }

        var data = {
          user_payment_id: ContestPayementId,
          media_credit: parseInt(applyContests.credit),
          contests: _contests
        }
        dispatch(updateApplyCreditsListAsync(data))
        alert.success("Credits Update Successfully")
        setErrorMessage("")
        setContestPayementId("")
        setApplyContests({})
      }
    }
  }

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const docFormatter = (cell, row, rowIndex) => {
    return cell.toLocaleString()
  }

  const defaultSorted = {
    dataField: "created_at",
    order: "desc",
  }

  const columns = [
    {
      dataField: "User.nick_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: docFormatter,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: true,
      formatter: docFormatter,
    },
    {
      dataField: "created_at",
      text: "Payment Date",
      sort: true,
      formatter: (cellContent: any, row: any) =>
        moment(row?.created_at).format("MM/DD/YYYY, hh:mm a"),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => {
        var now = new Date()
        var yesterdayDate = now.setDate(now.getDate() - 1)
        if (
          new Date(row?.created_at) > new Date(yesterdayDate) &&
          row.status == "credit"
        ) {
          return (
            <div className="d-flex justify-content-around">
              <i
                className="feather icon-edit text-success fw-bold"
                onClick={async () => {
                  let res = await getApplyCreditsList(row.user_payment_id)
                  if (res?.data?.statusCode == 200) {
                    setApplyContests(res?.data?.data)
                    setContestPayementId(row.user_payment_id)
                  } else {
                    alert.error("Something Went Wrong")
                  }
                }}
              ></i>
            </div>
          )
        } else {
          return null
        }
      },
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const listData = payments.filter((e: any) => {
    return (
      (e.User?.nick_name.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
        e.amount.toString().indexOf(searchText.toString()) >= 0 ||
        e.credit.toString().indexOf(searchText.toString()) >= 0) &&
      ((new Date(startDate) < new Date(e.created_at) &&
        new Date(e.created_at) < new Date(endDate)) ||
        !(startDate != "" && endDate != ""))
    )
  })

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Payment</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Payment</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Payment</h5>
                    </Col>
                    {/* <Col md={2}>
                      <Link className="btn btn-primary" to="/payment/create">
                        Add Payment
                      </Link>
                    </Col> */}
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={listData}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <Col>
                              <input
                                placeholder="Search"
                                type={"text"}
                                value={searchText}
                                className="form-control"
                                onChange={(e) => {
                                  setSearchText(e.target.value)
                                }}
                              />
                            </Col>
                            <Col>
                              <DatePicker
                                placeholderText="Start Date"
                                name="minDate"
                                className="form-control"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                maxDate={new Date()}
                                showTimeSelect={true}
                                dateFormat="MM-d-yyyy h:mm aa"
                              />
                            </Col>
                            <Col>
                              <DatePicker
                                placeholderText="End Date"
                                name="MaxDate"
                                className="form-control"
                                selected={endDate}
                                onChange={(date) => {
                                  setEndDate(date)
                                  if (startDate > endDate) {
                                    alert.error("Enter Valid Date")
                                  }
                                }}
                                maxDate={new Date()}
                                showTimeSelect={true}
                                dateFormat="MM-d-yyyy h:mm aa"
                              />
                            </Col>
                            <Col>
                              <button
                                onClick={() => {
                                  setStartDate("")
                                  setSearchText("")
                                  setEndDate("")
                                }}
                                className="btn btn-primary"
                              >
                                Clear
                              </button>
                              {listData.length > 0 ? (
                                <ExportCSVButton
                                  {...props.csvProps}
                                  className="btn-success fw-bold ms-2"
                                >
                                  Export CSV
                                </ExportCSVButton>
                              ) : null}
                            </Col>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                              sort={defaultSorted}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {ContestPayementId && applyContests ? (
        <Modal show={true} onHide={() => setContestPayementId("")}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="d-flex justify-content-between">
                Total Media Credit :{" "}
                <input
                  className="form-control w-25"
                  type="number"
                  name="credit"
                  defaultValue={applyContests?.credit}
                  onChange={(e) => {
                    let _applyContests = { ...applyContests }
                    _applyContests.credit = e.target.value
                    setApplyContests(_applyContests)
                  }}
                />
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {applyContests &&
              applyContests?.ContestCredits.map(
                (contest: any, index: number) => {
                  return (
                    <div className="d-flex justify-content-between form mb-2">
                      <div className="form-label">
                        {contest?.Contest?.title?.en}
                      </div>
                      <input
                        className="form-control w-25"
                        type="number"
                        name="credit"
                        defaultValue={contest?.credit}
                        disabled={contest?.Contest?.status == "Close"}
                        onChange={(e) => {
                          let _applyContests = { ...applyContests }
                          const UpdatedData = applyContests?.ContestCredits.map(
                            (item, i) => {
                              if (index === i) {
                                return { ...item, credit: e.target.value }
                              } else {
                                return item
                              }
                            }
                          )
                          _applyContests.ContestCredits = UpdatedData
                          setApplyContests(_applyContests)
                        }}
                      />
                    </div>
                  )
                }
              )}
            {applyContests &&
              applyContests?.open_contests.map(
                (contest: any, index: number) => {
                  return (
                    <div className="d-flex justify-content-between form mb-2">
                      <div className="form-label">{contest?.title?.en}</div>
                      <input
                        className="form-control w-25"
                        type="number"
                        name="credit"
                        defaultValue={0}
                        onChange={(e) => {
                          let _applyContests = { ...applyContests }
                          const UpdatedData = applyContests?.open_contests.map(
                            (item, i) => {
                              if (index === i) {
                                return { ...item, credit: e.target.value }
                              } else {
                                return item
                              }
                            }
                          )
                          _applyContests.open_contests = UpdatedData
                          setApplyContests(_applyContests)
                        }}
                      />
                    </div>
                  )
                }
              )}
          </Modal.Body>
          <Modal.Footer>
            <div className="row col text-danger">{errorMessage}</div>
            <Button
              variant="secondary"
              onClick={() => {
                setContestPayementId("")
                setApplyContests({})
                setErrorMessage("")
              }}
            >
              Close
            </Button>
            <Button
              className="btn-primary"
              variant="primary"
              onClick={() => UpdateContestCredit()}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}
export default PaymentList
