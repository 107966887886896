import { Formik } from "formik"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  createContestRoundGame,
  updateContestRoundGame,
} from "../../contestroundgame/ContestRoundGameAPI"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import FormField from "../../common/FormField/index"
import ModuleConfig from "../../contestroundgame/config/ModuleConfig"
import { useAlert } from "react-alert"
import {
  ContestRoundGameState,
  getContestRoundGameDetailByRoundIdAsync,
} from "../../contestroundgame/ContestRoundGameSlice"
import Config from "../../../utils/config"
import moment from "moment"
import { ContestState, winnerDeclareAsync } from "../ContestSlice"
import MultiSelect from "../../common/FormField/MultiSelect"
import ContestRoundGameIsTeam from "./ContestRoundGameIsTeam"
import { winnerDeclare } from "./../ContestAPI"
import {
  ContestRoundState,
  getContestRoundDetailAsync,
  getContestRoundDetailByContestIdAsync,
} from "../../contestround/ContestRoundSlice"
import { Button, Modal } from "react-bootstrap"

const ContestRoundGames = (props: any) => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const alert = useAlert()
  const { detail } = useAppSelector(ContestState)
  const contestRoundData = useAppSelector(ContestRoundState)
  const [showAddGameModel, setShowAddGameModel] = useState(false)
  const { contestroundgames } = useAppSelector(ContestRoundGameState)
  const [gameItem, setGameItem] = useState<any>({})
  const [sort, setSort] = useState("created_at")
  const [order, setOrder] = useState("DESC")
  const [result, setResult] = useState<any>({})
  const [resultStatus, setResultStatus] = useState<any>({})
  const [contestRoundStartTime, setContestRoundStartTime] = useState("")
  const [showScorrerModal, setShowScorrerModal] = useState(false)
  const [winnerDetails, setWinnerDetails] = useState<any>({})

  useEffect(() => {
    getGameList()
    setContestRoundStartTime(props.contest_round_start_time)
  }, [props.contest_round_id])

  const callAPI = async (winner: any) => {
    const res: any = await winnerDeclare(winner)
    if (res.error && res.statusCode === 400) {
      if (res?.error && res?.error.length > 0) {
        res?.error.forEach((element: any) => {
          alert.error(element.msg)
        })
      }
    } else if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
      alert.success("Success.")
      getPageData()
      getGameList()
    }
  }

  const getGameList = () => {
    const filter = {
      sortField: sort,
      order: order,
    }
    dispatch(getContestRoundGameDetailByRoundIdAsync(props.contest_round_id))
  }

  const handleSubmit = async (values: any) => {
    // if (
    //   new Date(values.open_till).getTime() >
    //   new Date(contestRoundData?.detail?.reg_close_date_time).getTime()
    // ) {
    //   alert.error("Enter Valid End Allocation Date")
    // } else {
    if (gameItem?.contest_round_game_id && showAddGameModel) {
      let isConfirm = window.confirm(
        "Are you sure you wish to update this round?"
      )
      if (isConfirm) {
        const t1 = []
        for (var i = 0; i < values.teams.length; i++) {
          t1.push(values.teams[i].value)
        }
        const data: any = {
          contest_round_game_id: gameItem.contest_round_game_id,
          contest_round_id: props.contest_round_id,
          progress_status: "Draft",
          teams: t1,
          status: "Pending",
          game_time: values.game_time,
          open_till: values.open_till,
          place_name: values.place_name,
        }
        const res: any = await updateContestRoundGame(params.contest_id, data)
        if (res.error && res.statusCode === 400) {
          if (res?.error && res?.error.length > 0) {
            res?.error.forEach((element) => {
              alert.error(element.msg)
            })
          }
        } else if (
          res?.data?.statusCode === 201 ||
          res?.data?.statusCode === 200
        ) {
          setShowAddGameModel(false)
          alert.success("Contest Round Game updated successfully.")
          getGameList()
        }
      } else {
        alert.error("You rejected the request")
      }
    } else {
      let isConfirm = window.confirm(
        "Are you sure you wish to create this round?"
      )
      if (isConfirm) {
        const t1 = []
        for (var i = 0; i < values.teams.length; i++) {
          t1.push(values.teams[i].value)
        }
        const data: any = {
          contest_round_id: props.contest_round_id,
          progress_status: "Draft",
          teams: t1,
          status: "Pending",
          game_time: values.game_time,
          open_till: values.open_till,
          place_name: values.place_name,
        }
        const res: any = await createContestRoundGame(data)
        if (res.error && res.statusCode === 400) {
          if (res?.error && res?.error.length > 0) {
            res?.error.forEach((element) => {
              alert.error(element.msg)
            })
          }
        } else if (
          res?.data?.statusCode === 201 ||
          res?.data?.statusCode === 200
        ) {
          setShowAddGameModel(false)
          alert.success("Contest Round Game created successfully.")
          getGameList()
        }
      } else {
        alert.error("You rejected the request")
      }
    }
    // }
  }

  const handleSubmitRound = async (roundId: any) => {
    let isConfirm = window.confirm(
      "Are you sure you wish to update this round?"
    )
    if (isConfirm) {
      let winner = {
        contest_round_game_id: roundId,
        winner_team_ids: result[roundId] ? result[roundId] : [],
        status:
          resultStatus[roundId] && result[roundId].length != 0
            ? resultStatus[roundId]
            : "No result",
        progress_status: "Close",
      }
      setWinnerDetails(winner)
      if (winner.status != "Result") {
        setShowScorrerModal(true)
      } else {
        callAPI(winner)
      }
    } else {
      alert.error("You rejected the request")
    }
  }

  const getPageData = () => {
    if (params.contest_id) {
      dispatch(getContestRoundDetailByContestIdAsync(params.contest_id))
      dispatch(getContestRoundDetailAsync(params.contest_id))
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {props?.status !== "In progress" && props?.status !== "Close" ? (
            <div className="text-center mb-4">
              <h4>Content Round Games</h4>
              <button
                onClick={() => {
                  setGameItem({})
                  setShowAddGameModel(true)
                }}
                className="btn btn-primary"
              >
                Add Game
              </button>
            </div>
          ) : null}
          {showAddGameModel ? (
            <>
              <div className="content-round-games">
                <div className="card radius5">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <Formik
                          initialValues={{
                            teams: gameItem.teams ? gameItem.teams : [],
                            place_name:
                              gameItem && gameItem?.place_name
                                ? gameItem && gameItem?.place_name
                                : "",
                            status:
                              gameItem && gameItem?.status
                                ? gameItem && gameItem?.status
                                : "",
                            game_time:
                              gameItem && gameItem?.game_time
                                ? gameItem && gameItem?.game_time
                                : "",
                            open_till:
                              gameItem && gameItem?.open_till
                                ? gameItem && gameItem?.open_till
                                : "",
                          }}
                          validate={(values) => {
                            const errors: any = {}
                            const fields = Object.keys(ModuleConfig.formFields)
                            for (
                              let index = 0;
                              index < fields.length;
                              index++
                            ) {
                              if (
                                !values[fields[index]] &&
                                ModuleConfig.formFields[fields[index]]
                                  .isRequired
                              ) {
                                errors[fields[index]] = "Required"
                              }
                            }
                            if (values.teams.length < 2) {
                              errors[
                                "teams"
                              ] = `You need to select at least 2 ${
                                detail.is_team ? "Teams" : "Players"
                              }`
                            }
                            if (values.game_time) {
                              let GameTime = new Date(
                                values.game_time
                              ).getTime()
                              let startDate = new Date(
                                contestRoundStartTime
                              ).getTime()
                              if (GameTime < startDate) {
                                errors["game_time"] =
                                  "Must be greater than Start Date"
                              }
                            }

                            return errors
                          }}
                          enableReinitialize={true}
                          onSubmit={handleSubmit}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group mb-4">
                                    <div className="input-label">
                                      {detail.is_team ? "Teams" : "Players"}
                                    </div>

                                    <MultiSelect
                                      name={"teams"}
                                      fieldDetails={
                                        ModuleConfig.formFields.teams
                                      }
                                      values={values}
                                      errors={errors}
                                      maxLength={detail.is_team ? 2 : 99}
                                      touched={touched}
                                      filterParams={`contest_id=${params.contest_id}`}
                                      setFieldValue={setFieldValue}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                    {touched &&
                                    touched["teams"] &&
                                    typeof errors["teams"] === "string" ? (
                                      <div className="error">
                                        {errors["teams"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <FormField
                                    name={"place_name"}
                                    fieldDetails={
                                      ModuleConfig.formFields.place_name
                                    }
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <FormField
                                    name={"game_time"}
                                    fieldDetails={
                                      ModuleConfig.formFields.game_time
                                    }
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <FormField
                                    name={"open_till"}
                                    fieldDetails={
                                      ModuleConfig.formFields.open_till
                                    }
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                                {/* <div className="col-md-4">
                                  <FormField
                                    name={"status"}
                                    fieldDetails={
                                      ModuleConfig.formFields.status
                                    }
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div> */}
                                <div className="col-md-4">
                                  <div>&nbsp;</div>
                                  <button
                                    type="reset"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      setShowAddGameModel(false)
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </>
          ) : null}
        </div>
      </div>

      <div className="round-game-listing">
        {!detail.is_team ? (
          <div className="container">
            <div className="row">
              {contestroundgames && contestroundgames?.length > 0
                ? contestroundgames.map((game, index) => {
                    return (
                      <>
                        <div className="col-sm-12 mt-4">
                          {game?.open_till == null ? (
                            " "
                          ) : (
                            <>
                              <div className="text-center font15 text-lightblack borderbot-arrow">
                                Open For Allocation Till.{" "}
                                <span className="font-w600 text-black">
                                  {moment(game?.open_till).format(
                                    "hh:mm A, MM/DD/YYYY"
                                  )}
                                </span>
                              </div>
                            </>
                          )}
                          <div className="matchdatetime">
                            <div className="row align-items-center">
                              <div className="col-md-5">
                                <div className="font15 text-black font-w400">
                                  <div>Stadium name</div>
                                  <div className="font-w600">
                                    {game?.place_name}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-7 text-end">
                                <div className="row align-items-center my-3">
                                  <div className="col-md-9">
                                    <div className="font15 text-black my-2">
                                      <img
                                        src="public/assets/images/calander-icon.svg"
                                        alt=""
                                        className="img-fluid me-2"
                                      />{" "}
                                      {moment(game?.game_time).format(
                                        "hh:mm A, MM/DD/YYYY"
                                      )}
                                    </div>
                                  </div>
                                  {props?.status !== "In progress" &&
                                  game?.progress_status !== "Close" ? (
                                    <div className="col-md-3">
                                      <div className="text-end">
                                        <span
                                          className="blueiconbtn"
                                          onClick={() => {
                                            let _game = { ...game }
                                            _game.teams = []
                                            for (
                                              var i = 0;
                                              i <
                                              game.ContestRoundGameTeams
                                                ?.length;
                                              i++
                                            ) {
                                              _game.teams.push({
                                                logo: game
                                                  .ContestRoundGameTeams[i].Team
                                                  ?.logo,
                                                label:
                                                  game.ContestRoundGameTeams[i]
                                                    .Team?.name.en,
                                                value:
                                                  game.ContestRoundGameTeams[i]
                                                    .Team?.team_id,
                                              })
                                            }

                                            setGameItem(_game)
                                            setShowAddGameModel(true)
                                            window.scrollTo(0, 300)
                                          }}
                                        >
                                          <img
                                            src="/assets/images/edit.svg"
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  ) : props?.status === "In progress" &&
                                    game?.progress_status !== "Close" ? (
                                    <>
                                      <div className="col-md-3">
                                        <select
                                          className="form-control result_style"
                                          onChange={(e) => {
                                            const _resultStatus = {
                                              ...resultStatus,
                                            }
                                            _resultStatus[
                                              game?.contest_round_game_id
                                            ] = e.target.value
                                            if (e.target.value == "No result") {
                                              let _result = { ...result }
                                              _result[
                                                game?.contest_round_game_id
                                              ] = []
                                              setResult(_result)
                                            }
                                            setResultStatus(_resultStatus)
                                          }}
                                        >
                                          <option
                                            selected={
                                              resultStatus[
                                                game?.contest_round_game_id
                                              ] == "Result"
                                            }
                                            value={"Result"}
                                          >
                                            Result
                                          </option>
                                          <option
                                            selected={
                                              resultStatus[
                                                game?.contest_round_game_id
                                              ] == "No result"
                                            }
                                            value={"No result"}
                                          >
                                            No result
                                          </option>
                                        </select>
                                        <button
                                          className="btn btn-primary mt-2"
                                          onClick={() => {
                                            handleSubmitRound(
                                              game?.contest_round_game_id
                                            )
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="col-md-2">
                                      <div className="text-center text-black font-w600 mt-2">
                                        <b>{game?.status}</b>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="gridview-player mt-4">
                            <ul>
                              {game?.ContestRoundGameTeams &&
                              game?.ContestRoundGameTeams?.length > 0
                                ? game?.ContestRoundGameTeams.map((team, i) => {
                                    return (
                                      <>
                                        <li key={i}>
                                          <div className={"single-gridview"}>
                                            <div className="mb-3">
                                              <img
                                                src={`${Config.cdnUrl}${team?.Team?.logo}`}
                                                alt="photo"
                                                className={
                                                  "radius100 rounded-circle wid-150 hei-150"
                                                }
                                              />
                                            </div>
                                            <div className="font17 text-black">
                                              {team?.Team?.name.en}
                                            </div>
                                            {props?.status === "In progress" ? (
                                              <div className="font17 text-black">
                                                <input
                                                  type={"checkbox"}
                                                  name={team?.team_id}
                                                  value={team?.team_id}
                                                  disabled={
                                                    team?.is_winner == null
                                                      ? false
                                                      : true
                                                  }
                                                  checked={
                                                    team?.is_winner === true
                                                      ? true
                                                      : result[
                                                          team
                                                            ?.contest_round_game_id
                                                        ]?.indexOf(
                                                          team?.team_id
                                                        ) >= 0
                                                  }
                                                  onClick={(e) => {
                                                    let _result = { ...result }
                                                    let _resultGame =
                                                      _result[
                                                        team
                                                          ?.contest_round_game_id
                                                      ] || []
                                                    if (
                                                      _resultGame.indexOf(
                                                        team?.team_id
                                                      ) >= 0
                                                    ) {
                                                      _resultGame.splice(
                                                        _resultGame.indexOf(
                                                          team?.team_id
                                                        ),
                                                        1
                                                      )
                                                    } else {
                                                      _resultGame.push(
                                                        team?.team_id
                                                      )
                                                    }
                                                    _result[
                                                      team?.contest_round_game_id
                                                    ] = _resultGame

                                                    let _resultStatus = {
                                                      ...resultStatus,
                                                    }
                                                    if (
                                                      _resultGame.length > 0
                                                    ) {
                                                      _resultStatus[
                                                        team?.contest_round_game_id
                                                      ] = "Result"
                                                    } else {
                                                      _resultStatus[
                                                        team?.contest_round_game_id
                                                      ] = "No result"
                                                    }
                                                    setResultStatus(
                                                      _resultStatus
                                                    )
                                                    setResult(_result)
                                                  }}
                                                />
                                              </div>
                                            ) : props?.status === "Close" ? 
                                            
                                            <>{team?.is_winner? 
                                              <div className="result-won">Win</div>
                                               : 
                                                <div className="result-lose">Lose</div>}</>
                                            : null}
                                          </div>
                                        </li>
                                      </>
                                    )
                                  })
                                : null}
                            </ul>
                          </div>
                        </div>
                      </>
                    )
                  })
                : null}
            </div>
          </div>
        ) : (
          <ContestRoundGameIsTeam
            contest_round_id={props.contest_round_id}
            setShowAddGameModel={setShowAddGameModel}
            setGameItem={setGameItem}
            contestroundgames={contestroundgames}
            status={props?.status}
            contest_round_start_time={props.contest_round_start_time}
            RoundDetail={props?.RoundDetail}
            number_of_round={props.number_of_round}
          />
        )}
      </div>

      {showScorrerModal ? (
        <Modal show={true} onHide={() => setShowScorrerModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props?.RoundDetail?.round_sequence == props.number_of_round
              ? "This is the last round, so credits cannot be carried forward. Do you wish to declare this as a win or lose credits?"
              : "Do you want to carry forward credits to the next round?"}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-primary"
              variant="primary"
              onClick={() => {
                let _winnerDetails = winnerDetails
                _winnerDetails.creditForward = true
                setWinnerDetails(_winnerDetails)
                callAPI(_winnerDetails)
                setShowScorrerModal(false)
              }}
            >
              {props?.RoundDetail?.round_sequence == props.number_of_round
                ? "Win"
                : "Yes"}
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={() => {
                let _winnerDetails = winnerDetails
                _winnerDetails.creditForward = false
                setWinnerDetails(_winnerDetails)
                callAPI(_winnerDetails)
                setShowScorrerModal(false)
              }}
            >
              {props?.RoundDetail?.round_sequence == props.number_of_round
                ? "Lose"
                : "No"}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}

export default ContestRoundGames
