const ModuleConfig = {
  primaryKey: "sport_id",

  formDefaultValue: {
    sport_name: "",
    logo: "",
    status: "",
  },

  formFields: {
    sport_name: {
      label: "Sport Name",
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength:"50"
    },

    logo: {
      label: "Logo",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
      inputType:"file"
    },

    status: {
      label: "Status",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Active",
          value: "1",
        },
        {
          label: "Inactive",
          value: "0",
        },
      ],
    },
  },

  listColumnOrder: ["sport_name", "status"],
};

export default ModuleConfig;
