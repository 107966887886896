import { Field, FieldArray, Formik } from "formik"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "../../../stores/hooks"
import CustomPagination from "../../../utils/CustomPagination"
import {
  ContestRoundState,
  getContestRoundDetailByContestIdAsync,
} from "../../contestround/ContestRoundSlice"
import { useAppDispatch } from "./../../../stores/hooks"
import { useAlert } from "react-alert"
import CDatePicker from "../../common/FormField/DatePicker"
import {
  createContestRound,
  updateContestRound,
} from "../../contestround/ContestRoundAPI"
import { appState } from "../../../stores/appSlice"
import { getContestRoundDetailAsync } from "./../../contestround/ContestRoundSlice"
import ContestRoundGames from "./ContestRoundGames"
import MultiSelect from "../../common/FormField/MultiSelect"
import InputSelect from "../../common/FormField/InputSelect"
import ModuleConfig from "./../../contestround/config/ModuleConfig"
import moment from "moment"

const options = [
  {
    label: "Draft",
    value: "Draft",
  },
  {
    label: "Open",
    value: "Open",
  },
  {
    label: "In Progress",
    value: "In progress",
  },
]

const ContestRoundConfig = (props: any) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { contestrounds } = useAppSelector(ContestRoundState)
  const params = useParams()
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const alert = useAlert()
  const { detail } = useAppSelector(ContestRoundState)
  const { languages } = useAppSelector(appState)
  const [showEditModel, setShowEditModel] = useState(false)
  const [defaultValus, setDefaultValue] = useState({
    mulitLang: [],
    status: "",
    reg_start_date_time: "",
    sponsorship_id: "",
  })

  useEffect(() => {
    getPageData()
  }, [params.contest_id])

  useEffect(() => {
    if (
      contestrounds[currentPage - 1] &&
      contestrounds[currentPage - 1]?.contest_round_id
    ) {
      dispatch(
        getContestRoundDetailAsync(
          contestrounds[currentPage - 1]?.contest_round_id
        )
      )
    }
  }, [contestrounds, dispatch])

  useEffect(() => {
    let multiLang = []
    languages.forEach((element) => {
      multiLang.push({
        code: element.code,
        label: element.name,
        title: "",
      })
    })
    setDefaultValue({
      ...defaultValus,
      mulitLang: multiLang,
    })
  }, [])

  useEffect(() => {
    if (contestrounds[currentPage - 1]) {
      let multiLang = []
      languages.forEach((element) => {
        multiLang.push({
          code: element.code,
          label: element.name,
          title: contestrounds[currentPage - 1].title[element.code],
        })
      })
      setDefaultValue({
        reg_start_date_time: contestrounds[currentPage - 1].reg_start_date_time,
        status: contestrounds[currentPage - 1].status,
        sponsorship_id: contestrounds[currentPage - 1].sponsorship_id,
        mulitLang: multiLang,
      })
    }
  }, [detail, currentPage])

  const getPageData = () => {
    if (params.contest_id) {
      dispatch(getContestRoundDetailByContestIdAsync(params.contest_id))
    }
  }

  const handleContestRoundSubmit = async (values) => {
    if (
      contestrounds[currentPage - 1] &&
      contestrounds[currentPage - 1].contest_round_id
    ) {
      const data: any = {
        contest_id: params.contest_id,
        contest_round_id: contestrounds[currentPage - 1].contest_round_id,
        title: {},
        status: values.status,
        reg_start_date_time: values.reg_start_date_time,
        // reg_close_date_time: values?.reg_close_date_time,
        sponsorship_id: values.sponsorship_id,
      }
      values.mulitLang.forEach((element) => {
        data.title[element.code] = element.title
      })

      const res: any = await updateContestRound(
        contestrounds[currentPage - 1].contest_round_id,
        data
      )
      if (res.error && res.statusCode === 400) {
        if (res?.error && res?.error.length > 0) {
          res?.error.forEach((element) => {
            alert.error(element.msg)
          })
        }
      } else if (res.statusCode === 400) {
        alert.error(res.message)
        setShowEditModel(false)
      } else if (res.statusCode === 404) {
        alert.error(res.message)
        setShowEditModel(false)
      } else if (res.data.statusCode === 201 || res.data.statusCode === 200) {
        setShowEditModel(false)
        alert.success("Contest Round updated successfully.")
        getPageData()
      }
    } else {
      const data: any = {
        title: {},
        status: values.status,
        reg_start_date_time: values.reg_start_date_time,
        // reg_close_date_time: values.reg_close_date_time,
        sponsorship_id: values.sponsorship_id,
        contest_id: params.contest_id,
      }
      values.mulitLang.forEach((element) => {
        data.title[element.code] = element.title
      })

      const res: any = await createContestRound(data)
      if (res.error && res.statusCode === 400) {
        if (res?.error && res?.error.length > 0) {
          res?.error.forEach((element) => {
            alert.error(element.msg)
          })
        }
      } else if (res.statusCode === 201 || res.statusCode === 200) {
        setShowEditModel(false)
        alert.success("Contest Round created successfully.")
        getPageData()
      }
    }
  }

  return (
    <>
      <CustomPagination
        curretnPage={currentPage}
        total={props.detail?.number_of_round}
        limit={1}
        disableAfter={contestrounds.length}
        setPage={setCurrentPage}
      />

      <div className="row">
        <div className="col-sm-12">
          <div className="cc-match-title">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-sm-12 mobilebot2">
                    <label>Title</label>
                    <div className="font14 font-w600 text-black">
                      {contestrounds[currentPage - 1]?.title?.en}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 mobilebot2">
                    <label>Start Date/Time</label>
                    <div className="font14 font-w600 text-black">
                      {moment(
                        contestrounds[currentPage - 1]?.reg_start_date_time
                      ).format("hh:mm A, MM/DD/YYYY")}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 mobilebot2">
                    {/* <label>Close Date/Time</label> */}
                    {/* <div className="font14 font-w600 text-black">
                      {contestrounds[currentPage - 1]?.reg_close_date_time ==
                      null
                        ? ""
                        : moment(
                            contestrounds[currentPage - 1]?.reg_close_date_time
                          ).format("hh:mm A, MM/DD/YYYY")}
                    </div> */}
                  </div>
                  <div className="col-lg-2 col-sm-6 mobilebot2">
                    <label>Status</label>
                    <div className="font14 font-w600 text-green">
                      {contestrounds[currentPage - 1]?.status}
                    </div>
                  </div>
                  {contestrounds[currentPage - 1]?.status !== "Close" ? (
                    <div className="col-lg-2 col-sm-6">
                      {" "}
                      <span
                        className="blueiconbtn"
                        onClick={() => setShowEditModel(!showEditModel)}
                      >
                        <img src="/assets/images/edit.svg" />
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  {showEditModel ? (
                    <>
                      <div className="row border rounded p-4 mt-3">
                        <div className="col-md-12">
                          <Formik
                            initialValues={defaultValus}
                            validate={(values) => {
                              const errors: any = {}

                              if (!values.reg_start_date_time) {
                                errors.reg_start_date_time = "Required"
                              }
                              // if (!values.reg_close_date_time) {
                              //   errors.reg_close_date_time = "Required"
                              // }
                              if (!values.status) {
                                errors.status = "Required"
                              }
                              for (
                                var i = 0;
                                i < values.mulitLang.length;
                                i++
                              ) {
                                if (
                                  !values.mulitLang[i].title &&
                                  values.mulitLang[i].code == "en"
                                ) {
                                  if (!errors.mulitLang) {
                                    errors.mulitLang = []
                                  }
                                  errors[`mulitLang.${i}.title`] = "Required"
                                }
                              }
                              return errors
                            }}
                            enableReinitialize={true}
                            onSubmit={handleContestRoundSubmit}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="row">
                                  <FieldArray
                                    name="mulitLang"
                                    render={() => (
                                      <>
                                        {values.mulitLang &&
                                        values.mulitLang.length > 0
                                          ? values.mulitLang.map(
                                              (mulitLang, index) => (
                                                <div
                                                  key={index}
                                                  className={"col-md-3"}
                                                >
                                                  <h4 className="input-label">
                                                    {mulitLang.label}
                                                  </h4>

                                                  <div className="form-group mb-4">
                                                    <div className="input-label">
                                                      Title
                                                    </div>
                                                    <Field
                                                      className="form-control"
                                                      name={`mulitLang.${index}.title`}
                                                    />
                                                    {touched.mulitLang &&
                                                    touched.mulitLang[index] &&
                                                    touched.mulitLang[index]
                                                      .title &&
                                                    typeof errors[
                                                      `mulitLang.${index}.title`
                                                    ] === "string" ? (
                                                      <div className="error">
                                                        {
                                                          errors[
                                                            `mulitLang.${index}.title`
                                                          ]
                                                        }
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              )
                                            )
                                          : null}
                                      </>
                                    )}
                                  />

                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label>
                                          Registration Start Date/Time
                                        </label>
                                        <CDatePicker
                                          name="reg_start_date_time"
                                          values={values}
                                          errors={errors}
                                          touched={touched}
                                          disabled={
                                            values.status == "In progress"
                                              ? true
                                              : false
                                          }
                                          onChange={handleChange}
                                          setFieldValue={setFieldValue}
                                          onBlur={handleBlur}
                                          className="form-control"
                                          placeholder="Registration Start Date/Time"
                                        />
                                        {touched.reg_start_date_time &&
                                        typeof errors[`reg_start_date_time`] ===
                                          "string" ? (
                                          <div className="error">
                                            {errors[`reg_start_date_time`]}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label>Status</label>
                                        <Field
                                          as="select"
                                          className="form-control"
                                          name="status"
                                        >
                                          {options.map(
                                            (option: any, index: any) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={option?.value}
                                                >
                                                  {option?.label}
                                                </option>
                                              )
                                            }
                                          )}
                                        </Field>
                                        {touched.status &&
                                        typeof errors[`status`] === "string" ? (
                                          <div className="error">
                                            {errors[`status`]}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label>Sponsor</label>
                                        <InputSelect
                                          name={"sponsorship_id"}
                                          fieldDetails={
                                            ModuleConfig.formFields
                                              .sponsorship_id
                                          }
                                          values={values}
                                          className="form-control"
                                          errors={errors}
                                          touched={touched}
                                          setFieldValue={setFieldValue}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                        {touched.sponsorship_id &&
                                        typeof errors[`sponsorship_id`] ===
                                          "string" ? (
                                          <div className="error">
                                            {errors[`sponsorship_id`]}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="">
                                      <button
                                        type="reset"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          setShowEditModel(false)
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        // disabled={status === "loading"}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {contestrounds[currentPage - 1] &&
          contestrounds[currentPage - 1].contest_round_id ? (
            <ContestRoundGames
              contest_round_id={contestrounds[currentPage - 1].contest_round_id}
              status={contestrounds[currentPage - 1].status}
              number_of_round={props?.detail?.number_of_round}
              contest_round_start_time={
                contestrounds[currentPage - 1]?.reg_start_date_time
              }
              RoundDetail={contestrounds[currentPage - 1]}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ContestRoundConfig
