const Config = {
  API_URL: "https://safetysurvivorapi.alliancetek.net/v1/",
  sasToken: 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-12-31T18:27:26Z&st=2023-06-06T10:27:26Z&spr=https,http&sig=v7doE41YduBXfmyQmVf0z5o6sHtEMU3tNZ7ykkji7Ho%3D',
  StorageAccount: 'safetysurvivorqa',
  cdnUrl : "https://safetysurvivorqa.blob.core.windows.net/images/",
  enLanguageID : "aa618680-bd61-4692-8f1a-a75e5bbf3afb",

  // Live APIs
  // API_URL: "https://webapi.safetysurvivor.com/v1/",
  // enLanguageID: "1c31f909-e40c-4742-826d-c7f3bd121030",
  // sasToken:
  //   "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-08-08T19:08:21Z&st=2023-08-08T11:08:21Z&spr=https,http&sig=97EVAKI0lsWZihXo%2FZAq01csRV0qtEuyyIcyV90KTTQ%3D",
  // StorageAccount: "safetysurrvivormedia",
  // cdnUrl: "https://ssmediacdn.azureedge.net/images/",

  // Common
  STORE_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoiVTJGc2RHVmtYMThGNHNKWHdUL0xZaEJzYjBOUFBFL05jMlVWd1hiUkN4M1hIN3EvT0xoNE9WN1plZFdvZjQ5V1hsNUxrTXk4ODgveWZoMkw5ejI4Zm02aU9rYjRJVWJaZU80RnVFYWdrSzQ9IiwiaWF0IjoxNjkxOTg5ODU4LCJleHAiOjE2OTIwNzYyNTh9.PaDJyBsFq4oDWk4R7GW62jZSIM4XphQjxtk3CAf3bEA",
  STORE_ENDPOINT: "https://safetysurvivorapi.alliancetek.net/v1/",
  DEFAULT_STORE: 11102667,
}
export default Config
