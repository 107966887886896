import { Route } from "react-router-dom";
import WebHomeCreate from "./pages/WebHomeCreate";
import WebHomeList from "./pages/WebHomeList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "webhome"; 
const WebHomeRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<WebHomeList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<WebHomeCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<WebHomeCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default WebHomeRoute;