import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../stores"
import { ContestDetailInterface } from "./models/ContestDetailInterface"
import {
  createContest,
  deleteContest,
  getContestDetail,
  getContestList,
  getContestRoundDetail,
  updateContest,
  winnerDeclare,
} from "./ContestAPI"

export interface ContestStateInterface {
  contests: ContestDetailInterface[]
  favourites: ContestDetailInterface[]
  totalRecord: number
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  detail: ContestDetailInterface
}

const initialState: ContestStateInterface = {
  contests: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    contest_id: "",
    sport_id: "",
    banner: "",
    status: "",
    title: "",
    description: "",
    check_info: "",
    number_of_round: 0,
    reg_start_date_time: "",
    reg_close_date_time: "",
    price_pool: "",
    is_team: "",
    rules_file: "",
    complexity: "",
    sponsorship_id: "",
  },
}

export const getContestListAsync = createAsyncThunk(
  "contest/list",
  async (data: any) => {
    const response = await getContestList(data)
    return response.data
  }
)
export const getContestDetailAsync = createAsyncThunk(
  "contest/detail",
  async (index: string) => {
    const response = await getContestDetail(index)
    return response.data.data
  }
)


export const createContestAsync = createAsyncThunk(
  "contest/create",
  async (data: ContestDetailInterface) => {
    const response = await createContest(data)
    return response.data
  }
)

export const winnerDeclareAsync = createAsyncThunk(
  "contest/winner",
  async (data: any) => {
    const response = await winnerDeclare(data)
    return response.data
  }
)

export const deleteContestAsync = createAsyncThunk(
  "contest/delete",
  async (index: string) => {
    const response = await deleteContest(index)
    return response.data
  }
)

export const updateContestAsync = createAsyncThunk(
  "contest/update",
  async (data: { index: string; data: ContestDetailInterface }) => {
    const response = await updateContest(data.index, data.data)
    return response.data
  }
)

export const contestSlice = createSlice({
  name: "contest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getContestListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getContestListAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.contests = action.payload.data
        state.totalRecord = action.payload.total
      })
      .addCase(getContestListAsync.rejected, (state) => {
        state.status = "failed"
      })
      // GET DETAIL
      .addCase(getContestDetailAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getContestDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload
        state.status = "idle"
        
      })
      .addCase(getContestDetailAsync.rejected, (state) => {
        state.status = "failed"
      })
      
      // CREATE
      .addCase(createContestAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(createContestAsync.fulfilled, (state, action) => {
        state.status = "created"
        state.detail = action.payload
      })
      .addCase(createContestAsync.rejected, (state) => {
        state.status = "failed"
      })
      
      // winner
      .addCase(winnerDeclareAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(winnerDeclareAsync.fulfilled, (state, action) => {
        state.status = "created"
        state.detail = action.payload
      })
      .addCase(winnerDeclareAsync.rejected, (state) => {
        state.status = "failed"
      })
      // UPDATE
      .addCase(updateContestAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updateContestAsync.fulfilled, (state, action) => {
        state.status = "updated"
        state.detail = action.payload
      })
      .addCase(updateContestAsync.rejected, (state) => {
        state.status = "failed"
      })
      // DELETE
      .addCase(deleteContestAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteContestAsync.fulfilled, (state, action) => {
        state.status = "deleted"
        state.detail = action.payload
      })
      .addCase(deleteContestAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const {} = contestSlice.actions
export const ContestState = (state: RootState) => state.contest
export default contestSlice.reducer
