import { useEffect, useState } from "react"
import AxiosInstance from "../../../utils/AxiosInstance"
import Select from "react-select"
import Config from "../../../utils/config"

export interface OptionInterface {
  logo?: string
  value: string
  label: string
}
const MultiSelect = (props: any) => {
  const [options, setOptions] = useState<OptionInterface[]>([])

  useEffect(() => {
    if (!props.fieldDetails.asynchLoad) {
      setOptions(props.fieldDetails.options)
    } else {
      promiseOptions()
    }
  }, [])

  const promiseOptions = () => {
    return AxiosInstance.get(
      `${props?.fieldDetails?.dataUrl}?${props.filterParams}`
    ).then((res) => {
      const opts: OptionInterface[] = []
      for (let index = 0; index < res?.data?.data?.length; index++) {
        opts.push({
          logo: res?.data?.data[index].logo,
          value: res?.data?.data[index][props?.fieldDetails?.keyValue],
          label: res?.data?.data[index][props?.fieldDetails?.keylabel].en
            ? res?.data?.data[index][props?.fieldDetails?.keylabel].en
            : res?.data?.data[index][props?.fieldDetails?.keylabel],
        })
      }
      return setOptions(opts)
    })
  }
  return (
    <Select
      name={props.name}
      onBlur={props.handleBlur}
      onChange={(e) => {
       props.setFieldValue(props.name, e)
      }}
      formatOptionLabel={e => {
        return (
          <div className="list-item">
            {e.logo ? (
              <img className="rounded-circle" height="50" width="50" src={`${Config.cdnUrl}${e?.logo}`} />
            ) : (
              <span className="up mr-10">{e?.label[0]}</span>
            )}

            {e.label}
          </div>
        )
      }}
      options={ props.maxLength <= props.values[props.name].length ? [] : options}
      isMulti={true}
      value={props.values[props.name]}
      id={props.name}
      placeholder={props?.fieldDetails?.placeholder}
    />
   
  )
}

export default MultiSelect
