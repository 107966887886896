import { Route } from "react-router-dom";
import PrizeStatusUpdate from "./pages/PrizeCreate";
import PrizeList from "./pages/PrizeList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "prizes"; 
const PrizeRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<PrizeList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<PrizeStatusUpdate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default PrizeRoute;