import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { CMSDetailInterface } from "./models/CMSDetailInterface";
import {
  createCMS,
  deleteCMS,
  getCMSDetail,
  getCMSList,
  updateCMS,
} from "./CMSAPI";

export interface CMSStateInterface {
  cmss: CMSDetailInterface[];
  favourites: CMSDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: CMSDetailInterface;
}

const initialState: CMSStateInterface = {
  cmss: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    cms_id: "",
    title: "",
    credit: 0,
    content: "",  
    language_id: ""  
  },
};

export const getCMSListAsync = createAsyncThunk(
  "cms/list",
  async (data: any) => {
    const response = await getCMSList(data);
    return response.data;
  }
);
export const getCMSDetailAsync = createAsyncThunk(
  "cms/detail",
  async (index: string) => {
    const response = await getCMSDetail(index);
    return response.data;
  }
);

export const createCMSAsync = createAsyncThunk(
  "cms/create",
  async (data: CMSDetailInterface) => {
    const response = await createCMS(data);
    return response.data;
  }
);

export const deleteCMSAsync = createAsyncThunk(
  "cms/delete",
  async (index: string) => {
    const response = await deleteCMS(index);
    return response.data;
  }
);

export const updateCMSAsync = createAsyncThunk(
  "cms/update",
  async (data: { index: string; data: CMSDetailInterface }) => {
    const response = await updateCMS(data.index, data.data);
    return response.data;
  }
);

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getCMSListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCMSListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.cmss = action.payload.data;
        state.totalRecord = action.payload.total;
        state.detail = {}
      })
      .addCase(getCMSListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getCMSDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCMSDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload.data;
      })
      .addCase(getCMSDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createCMSAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCMSAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createCMSAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateCMSAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCMSAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateCMSAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteCMSAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCMSAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteCMSAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = cmsSlice.actions;
export const CMSState = (state: RootState) => state.cms;
export default cmsSlice.reducer;
