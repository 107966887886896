import { useEffect, useState } from "react"
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getUserListAsync, UserState } from "../../user/UserSlice"
import AxiosInstance from "../../../utils/AxiosInstance"
import { useAlert } from "react-alert"
import { SearchBar } from "@progress/kendo-react-dropdowns"

import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import {
  AchievementState,
  getAchievementDetailAsync,
} from "../AchievementSlice"
/**
 * Achievementing list page
 */
const AchievementUserList = (props: any) => {
  const { status } = useAppSelector(UserState)
  const { detail, products } = useAppSelector(AchievementState)
  const [page, setPage] = useState(1)
  const [userData, setUserData] = useState([])
  const dispatch = useAppDispatch()
  const params = useParams()
  const { ExportCSVButton } = CSVExport

  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    getPageData()
    setUserData(detail?.users)
  }, [dispatch, page])

  const getPageData = async () => {
    dispatch(getAchievementDetailAsync(params.achievement_id))
  }

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: userData.length,
      },
    ], 
  }

  const columns = [
    {
      dataField: "nick_name",
      text: "Username",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const listData = userData.filter((e: any) => {
    return (
      e.nick_name?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
      e?.email.toUpperCase().indexOf(searchText.toUpperCase()) >= 0
    )
  })

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">User</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>User</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>User</h5>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Something went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={listData}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <>
                            <Row>
                              <Col>
                                <input
                                  placeholder="Search"
                                  type={"text"}
                                  value={searchText}
                                  className="form-control"
                                  onChange={(e) => {
                                    setSearchText(e.target.value)
                                  }}
                                />
                              </Col>
                              <Col>
                                <button
                                  onClick={() => {
                                    setSearchText("")
                                  }}
                                  className="btn btn-primary"
                                >
                                  Clear
                                </button>
                                <ExportCSVButton
                                  {...props.csvProps}
                                  className="btn-success fw-bold"
                                >
                                  Export CSV
                                </ExportCSVButton>
                              </Col>
                            </Row>
                            <Row>
                              <BootstrapTable
                                filter={filterFactory()}
                                pagination={paginationFactory(options)}
                                {...props.baseProps}
                              />
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AchievementUserList
