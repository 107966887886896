import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { TeamDetailInterface } from "./models/TeamDetailInterface";
import {
  createTeam,
  deleteTeam,
  getTeamDetail,
  getTeamList,
  updateTeam,
} from "./TeamAPI";

export interface TeamStateInterface {
  teams: TeamDetailInterface[];
  favourites: TeamDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: TeamDetailInterface;
}

const initialState: TeamStateInterface = {
  teams: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    team_id: "",
    name: "",
    description: "",
    logo: "",
    status: "",
    sport_id: "" ,
    is_individual: "",
  },
};

export const getTeamListAsync = createAsyncThunk(
  "team/list",
  async (data: any) => {
    const response = await getTeamList(data);
    return response.data;
  }
);

export const getTeamDetailAsync = createAsyncThunk(
  "team/detail",
  async (index: string) => {
    const response = await getTeamDetail(index);
    return response.data.data;
  }
);

export const createTeamAsync = createAsyncThunk(
  "team/create",
  async (data: TeamDetailInterface) => {
    const response = await createTeam(data);
    return response.data;
  }
);

export const deleteTeamAsync = createAsyncThunk(
  "team/delete",
  async (index: string) => {
    const response = await deleteTeam(index);
    return response.data;
  }
);

export const updateTeamAsync = createAsyncThunk(
  "team/update",
  async (data: { index: string; data: TeamDetailInterface }) => {
    const response = await updateTeam(data.index, data.data);
    return response.data;
  }
);

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getTeamListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeamListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.teams = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getTeamListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getTeamDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeamDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getTeamDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createTeamAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTeamAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createTeamAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateTeamAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTeamAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateTeamAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteTeamAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTeamAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteTeamAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = teamSlice.actions;
export const TeamState = (state: RootState) => state.team;
export default teamSlice.reducer;
