import AxiosInstance from "../../utils/AxiosInstance";
import { SportDetailInterface } from "./models/SportDetailInterface";

const API_ROUTE = "sports";
export const getSportList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/sport?${prm}`);
};

export const getSportDetail = async (index: string) => {
  return await AxiosInstance.get(`/sport/get-by-id/${index}`);
};

export const createSport = async (data: SportDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  // return await AxiosInstance.post(`/${API_ROUTE}`, formData);
  return await AxiosInstance.post(`/sport/create`, data);
};

export const deleteSport = async (index: string) => {
  return await AxiosInstance.delete(`/sport/delete-By-Id/${index}`);
};
export const updateSport = async (index: string, data: SportDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.put(`/sport/update`, data);
};
