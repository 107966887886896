import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { ContestRoundDetailInterface } from "./models/ContestRoundDetailInterface";
import {
  createContestRound,
  deleteContestRound,
  getContestRoundDetail,
  getContestRoundDetailByContestId,
  getContestRoundList,
  updateContestRound,
} from "./ContestRoundAPI";

export interface ContestRoundStateInterface {
  contestrounds: ContestRoundDetailInterface[];
  favourites: ContestRoundDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: ContestRoundDetailInterface;
}

const initialState: ContestRoundStateInterface = {
  contestrounds: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    contest_round_id : "",
    contest_id : "",
    title : {},
    reg_start_date_time : "",
    reg_close_date_time : "",
    status : "",
    sponsorship_id : "",
  },
};

export const getContestRoundListAsync = createAsyncThunk(
  "contestround/list",
  async (data: any) => {
    const response = await getContestRoundList(data);
    return response.data;
  }
);
export const getContestRoundDetailAsync = createAsyncThunk(
  "contestround/detail",
  async (index: string) => {
    const response = await getContestRoundDetail(index);
    return response.data.data;
  }
);

export const getContestRoundDetailByContestIdAsync = createAsyncThunk(
  "contest-round/detail",
  async (index: string) => {
    const response = await getContestRoundDetailByContestId(index)
    return response.data
  }
)

export const createContestRoundAsync = createAsyncThunk(
  "contestround/create",
  async (data: ContestRoundDetailInterface) => {
    const response = await createContestRound(data);
    return response.data;
  }
);

export const deleteContestRoundAsync = createAsyncThunk(
  "contestround/delete",
  async (index: string) => {
    const response = await deleteContestRound(index);
    return response.data;
  }
);

export const updateContestRoundAsync = createAsyncThunk(
  "contestround/update",
  async (data: { index: string; data: ContestRoundDetailInterface }) => {
    const response = await updateContestRound(data.index, data.data);
    return response.data;
  }
);

export const contestroundSlice = createSlice({
  name: "contestround",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getContestRoundListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContestRoundListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.contestrounds = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getContestRoundListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getContestRoundDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContestRoundDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getContestRoundDetailAsync.rejected, (state) => {
        state.status = "failed";
      })

      // GET COntest Round DETAIL
      .addCase(getContestRoundDetailByContestIdAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getContestRoundDetailByContestIdAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.contestrounds = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getContestRoundDetailByContestIdAsync.rejected, (state) => {
        state.status = "failed"
      })
      // CREATE
      .addCase(createContestRoundAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createContestRoundAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createContestRoundAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateContestRoundAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateContestRoundAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateContestRoundAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteContestRoundAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteContestRoundAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteContestRoundAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = contestroundSlice.actions;
export const ContestRoundState = (state: RootState) => state.contestround;
export default contestroundSlice.reducer;
