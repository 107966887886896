import AxiosInstance from "../../utils/AxiosInstance";
import { MediaGalleryDetailInterface } from "./models/MediaGalleryDetailInterface";

export const getMediaGalleryList = async (params : any) => {
  // const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/media-gallery`);
};

export const getMediaGalleryDetail = async (index: string) => {
  return await AxiosInstance.get(`/media-gallery/get-by-id/${index}`);
};

export const createMediaGallery = async (data: MediaGalleryDetailInterface) => {
  return await AxiosInstance.post(`/media-gallery/create`, data);
};

export const deleteMediaGallery = async (index: string) => {
  return await AxiosInstance.delete(`/media-gallery/delete-by-id/${index}`);
};
export const updateMediaGallery = async (index: string, data: MediaGalleryDetailInterface) => {
  return await AxiosInstance.put(`/media-gallery/update`, data);
};
