import { useEffect, useState } from "react"
import { Formik } from "formik"
import AxiosInstance from "../utils/AxiosInstance"
import { useLocation, useNavigate } from "react-router-dom"
import { setProfile } from "../stores/appSlice"
import { useAppDispatch } from "../stores/hooks"
import { store } from "../stores"
import { useAlert } from "react-alert"

const VerifyOtp = () => {
  const navigate = useNavigate()
  const dispatchApp = useAppDispatch()
  const token = localStorage.getItem("token")
  const [loginSuccess, setLoginSuccess] = useState(token ? true : false)
  const alert = useAlert()
  const {state} = useLocation();
  const { email } = state; 
  useEffect(() => {
    if (loginSuccess) {
      navigate("/")
    }
  }, [loginSuccess])

  const onsubmit = async (values: any) => {
    const data = {
      email: email,
      email_otp: values.email_otp,
    }
    const res: any = await AxiosInstance.post("auth/verify-otp", data)
    if (res?.data?.statusCode === 200) {
      // if (res?.data?.data?.user?.role_name === "Admin") {
      //   setLoginSuccess(true)
        // localStorage.setItem("token", res?.data?.data?.access_token)
        // dispatchApp(setProfile(res?.data?.data?.user))
        navigate("/")
        // window.location.reload()
      // } 
      // else {
      //   alert(
      //     "You are not an administrator, Please try with another credentials."
      //   )
      //   navigate("/signin")
      // }
    } else if (res?.statusCode === 404) {
      alert.error("Please enter valid code.")
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r"></span>
          <span className="r s"></span>
          <span className="r s"></span>
          <span className="r"></span>
        </div>

        <Formik
          initialValues={{
            email_otp: "",
          }}
          enableReinitialize={true}
          validate={(values) => {
            const errors: any = {}

            if (!values.email_otp) {
              errors.email_otp = "Required"
            } 
            return errors
          }}
          onSubmit={onsubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="card">
                <div className="card-body text-center">
                  <div className="mb-4">
                    {/* <img className="login-logo" src="" /> */}
                    <i className="feather icon-unlock auth-icon"></i>
                  </div>
                  <h3 className="mb-4">Login</h3>
                  <div className="input-group mb-3">
                    <input
                      className="form-control"
                      type="number"
                      name="email_otp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email_otp}
                      placeholder="OTP"
                      autoFocus={true}
                    />
                    <div className="e-message">
                      {errors.email_otp && touched.email_otp && errors.email_otp}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary shadow-2 mb-4 mt-4"
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default VerifyOtp
