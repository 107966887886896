import { useEffect, useState } from "react"
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getSponsorshipListAsync, SponsorshipState } from "../SponsorshipSlice"
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa"
import { SponsorshipDetailInterface } from "../models/SponsorshipDetailInterface"
import ModuleConfig from "./../config/ModuleConfig"
import CustomPagination from "../../../utils/CustomPagination"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import Config from "../../../utils/config"

/**
 * Sponsorshiping list page
 */
const SponsorshipList = () => {
  const { sponsorships, status, totalRecord } = useAppSelector(SponsorshipState)
  const [page, setPage] = useState(1)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { SearchBar, ClearSearchButton } = Search
  const [sort, setSort] = useState("updated_at")
  const [order, setOrder] = useState("DESC")
  const [searchText, setSearchText] = useState("")
  const [searchStatus, setSearchStatus] = useState("")
  useEffect(() => {
    getPageData()
  }, [dispatch, page, sort, order])

  const getPageData = () => {
    const filter = {
      page: page,
      sortField: sort,
      order: order,
    }
    dispatch(getSponsorshipListAsync(filter))
  }
  
  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const columns = [
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
      classes: "col-auto",
      formatter: (cellContent: any, row: any) =>
        row?.logo ? (
          <div className="row">
            <span className="col-md-5 col-xl-auto">
              <img
                className="rounded-circle rounded-circle-border"
                height="50"
                width="50"
                src={`${Config.cdnUrl}${row?.logo}`}
              />{" "}
            </span>
            <div className="col-md-7"> {row.company_name} </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-5 col-xl-auto">
              <span className="up mr-10">{row.company_name[0]}</span>
            </div>
            <div className="col-md-7">
              <span> {row.company_name}</span>
            </div>
          </div>
        ),
    },
    {
      dataField: "website",
      text: "Website",
      classes: "col-auto",
      sort: true,
    },
    {
      dataField: "type_of_sponsor",
      text: "Type Of Sponsor",
      classes: "col-auto",
      sort: true,
    },
    {
      dataField: "budget",
      text: "Budget",
      classes: "col-1",
      sort: true,
      formatter: (cell, row, rowData) => {
        return cell.toLocaleString()
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) =>
        row.status == "Active" ? (
          <span className="label label-success rounded-pill"> Active </span>
        ) : row.status == "In active" ? (
          <span className="label label-danger rounded-pill"> In active </span>
        ) : (
          <span className="label label-warning rounded-pill"> Pending </span>
        ),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          <i
            className="feather icon-edit text-success fw-bold"
            onClick={() => {
              navigate(`/sponsorship/update/${row.sponsorship_id}`)
            }}
          ></i>
        </div>
      ),
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const listData = sponsorships.filter((e: any) => {
    return (
      (e.company_name?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
        e.type_of_sponsor?.toUpperCase().indexOf(searchText.toUpperCase()) >=
          0) &&
      (searchStatus == "" || e.status == searchStatus)
    )
  })

  return (
    <>
      <>
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title">
                  <h5 className="m-b-10">Sponsorship</h5>
                </div>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="feather icon-home"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a>Sponsorship</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <Row>
                      <Col auto>
                        <h5>Sponsorship</h5>
                      </Col>
                      <Col md={2}>
                        <Link
                          className="btn btn-primary"
                          to="/sponsorship/create"
                        >
                          Add Sponsorship
                        </Link>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-block table-border-style">
                    {status === "loading" ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : status === "failed" ? (
                      <Alert key={"danger"} variant={"danger"}>
                        Something went wrong please try again
                      </Alert>
                    ) : (
                      <>
                        <ToolkitProvider
                          keyField="id"
                          data={listData}
                          columns={columns}
                          selectRow={selectRow}
                          search
                          exportCSV
                        >
                          {(props: any) => (
                            <Row>
                              <Col>
                                <input
                                  placeholder="Search"
                                  type={"text"}
                                  value={searchText}
                                  className="form-control"
                                  onChange={(e) => {
                                    setSearchText(e.target.value)
                                  }}
                                />
                              </Col>
                              {/* <SearchBar {...props.searchProps} /> */}
                              <Col>
                                {" "}
                                <select
                                  className="form-control "
                                  onChange={(e) => {
                                    setSearchStatus(e.target.value)
                                  }}
                                >
                                  <option
                                    selected={searchStatus == ""}
                                    value=""
                                  >
                                    Select Status
                                  </option>
                                  <option selected={searchStatus == "Pending"}>
                                    Pending
                                  </option>
                                  <option selected={searchStatus == "Active"}>
                                    Active
                                  </option>
                                  <option
                                    selected={searchStatus == "In active"}
                                    value="In active"
                                  >
                                    Inactive
                                  </option>
                                </select>
                              </Col>
                              <Col>
                                <button
                                  onClick={() => {
                                    setSearchStatus("")
                                    setSearchText("")
                                  }}
                                  className="btn btn-primary"
                                >
                                  Clear
                                </button>
                              </Col>
                              <BootstrapTable
                                filter={filterFactory()}
                                pagination={paginationFactory(options)}
                                {...props.baseProps}
                              />
                            </Row>
                          )}
                        </ToolkitProvider>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}
export default SponsorshipList
