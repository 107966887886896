import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  BrowserRouter,
  useLocation,
} from "react-router-dom"

import UserRoute from "../modules/user/UserRoute"
import Signin from "../modules/Signin"
import Signup from "../modules/Signup"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { appState, getLoginLanguageListAsync } from "../stores/appSlice"
import Dashboard from "../modules/Dashboard"
import TeamRoute from "../modules/team/TeamRoute"
import SportRoute from "../modules/sport/SportRoute"
import CMSRoute from "../modules/cms/CMSRoute"
import ProductRoute from "../modules/product/ProductRoute"

import ContestRoute from "../modules/contest/ContestRoute"
import ContestRoundRoute from "../modules/contestround/ContestRoundRoute"
import ContestRoundGameRoute from "../modules/contestroundgame/ContestRoundGameRoute"
import ChangePassword from './../modules/ChangePassword';
import LanguageRoute from "../modules/language/LanguageRoute"
import Profile from './../modules/Profile';
import PageRoute from "../modules/page/PageRoute"
import { useAlert } from 'react-alert';
import VerifyOtp from "../modules/VerifyOtp"
import SponsorshipRoute from './../modules/sponsorship/SponsorshipRoute';
import PaymentRoute from "../modules/payment/PaymentRoute"
import { AppDispatch } from "../stores"
import ReportRoute from './../modules/report/ReportRoute';
import UserReportRoute from "../modules/userreport/UserReportRoute"
import WebHomeRoute from "../modules/webhome/WebHomeRoute"
import PrizeRoute from "../modules/prizes/PrizeRoute"
import MediaGalleryRoute from "../modules/mediaGallery/MediaGalleryRoute"
import AchievementRoute from "../modules/achievement/AchievementRoute"
import PodcastRoute from "../modules/podcast/PodcastRoute"
import VideosRoute from "../modules/videos/VideosRoute"
{
  /* Import New Entry */
}

/**
 * This will return all routes of the application
 */
const AppRoute = () => {
  const [token, setToken] = useState(localStorage.getItem("token"))
  const { isUserLoggedIn } = useSelector(appState)
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if(localStorage.getItem("token")){
      languageList()
    }
  }, [isUserLoggedIn])


  const languageList = () => {
    const data={}
    dispatch(getLoginLanguageListAsync(data));
  }

  return (
    <>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
      </Routes>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/profile" element={<Profile />} />
                {UserRoute}
                {TeamRoute}
                {SportRoute}
                {CMSRoute}
                {ProductRoute}
                {ContestRoute}
                {ContestRoundRoute}
                {ContestRoundGameRoute}
                {LanguageRoute}
                {PageRoute}
                {SponsorshipRoute}
                {PaymentRoute}
                {ReportRoute}
                {UserReportRoute}
                {WebHomeRoute}
                {PrizeRoute}
                {MediaGalleryRoute}
                {AchievementRoute}
                {PodcastRoute}
                {VideosRoute}
                {/* Add New Entry */}
              </Routes> 
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default AppRoute
