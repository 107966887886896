import { Route } from "react-router-dom";
import PaymentCreate from "./pages/PaymentCreate";
import PaymentList from "./pages/PaymentList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "payment"; 
const PaymentRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<PaymentList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<PaymentCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<PaymentCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default PaymentRoute;