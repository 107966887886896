const ModuleConfig = {
  primaryKey: "user_id",

  formDefaultValue: {
    // first_name : "",
    // last_name : "",
    nick_name: "",
    email: "",
    password: "",
    status: "",
    media_credit: "",
    profile_image: "",
    phone: "",
    birth_year: "",
    created_at: "",
  },

  formFields: {
    nick_name: {
      label: "User Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },

    email: {
      label: "Email",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },

    status: {
      label: "Status",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Active",
          value: "1",
        },
        {
          label: "Inactive",
          value: "0",
        },
      ],
    },

    media_credit: {
      label: "Media Credit",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "number",
      maxLength: "8",
      min: 1,
      disabled: true,
      max: 999999,
    },

    profile_image: {
      label: "Profile Image",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
      accept: "image/png, image/jpeg",
    },

    birth_year: {
      label: "Birth Year",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength: "8",
      min: 1,
      disabled: true,
      max: 999999,
    },

    created_at: {
      label: "Joining Date",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      disabled: true,
    },
  },

  listColumnOrder: [
    "email",
    "status",
    "nick_name",
    "media_credit",
    "profile_image",
    "birth_year",
    "created_at",
  ],
}

export default ModuleConfig
