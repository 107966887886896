import { useEffect, useState } from "react"
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { Link, Navigate, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import {
  getContestRoundListAsync,
  ContestRoundState,
  getContestRoundDetailByContestIdAsync,
} from "../ContestRoundSlice"

import { deleteContestRound } from "../ContestRoundAPI"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

/**
 * ContestRounding list page
 */
const ContestRoundList = () => {
  const { contestrounds, status, totalRecord } =
    useAppSelector(ContestRoundState)
  const [page, setPage] = useState(1)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { SearchBar, ClearSearchButton } = Search
  const params = useParams()
  const [deleteItem, setDeleteItem] = useState("")

  useEffect(() => {
    getPageData()
  }, [dispatch, params])

  const getPageData = () => {
    if (params.contest_id) {
      dispatch(getContestRoundDetailByContestIdAsync(params.contest_id))
    }
  }

  const removeContestRound = async () => {
    const res = await deleteContestRound(deleteItem)
    setDeleteItem("")
    getPageData()
  }

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const columns = [
    {
      dataField: "title.en",
      text: "Title",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) =>
        row.status == "Open" ? (
          <span className="label label-success rounded-pill"> Open </span>
        ) : row.status == "In progress" ? (
          <span className="label label-primary rounded-pill">
            {" "}
            In Progress{" "}
          </span>
        ) : row.status == "Close" ? (
          <span className="label label-danger rounded-pill"> Close </span>
        ) : (
          ""
        ),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          {row.status !== "In progress" && row.status !== "Close" ? (
            <i
              className="feather icon-trash-2 text-danger fw-bold"
              onClick={() => {
                setDeleteItem(row.contest_round_id)
              }}
            ></i>
          ) : (
            ""
          )}
          {row.status !== "In progress" && row.status !== "Close" ? (
            <i
              className="feather icon-edit text-success fw-bold"
              onClick={() => {
                navigate(
                  `/contest-round/update/${params.contest_id}/${row.contest_round_id}`
                )
              }}
            ></i>
          ) : (
            ""
          )}
          <i
            className={
              row.status == "Open"
                ? "feather icon-user-plus text-secondary fw-bold"
                : row.status == "In progress"
                ? "feather icon-user-check text-secondary fw-bold"
                : "feather icon-eye text-secondary fw-bold"
            }
            title={
              row.status == "Open"
                ? "Manage Teams"
                : row.status == "In progress"
                ? "Set Result"
                : "View Result"
            }
            onClick={() => {
              row.status == "Open"
                ? navigate(
                    `/contest-round-game/${params.contest_id}/${row.contest_round_id}`
                  )
                : row.status == "In progress"
                ? navigate(
                    `/contest-round-game/result/${params.contest_id}/${row.contest_round_id}`
                  )
                : navigate(
                    `/contest-round-game/${params.contest_id}/${row.contest_round_id}`
                  )
            }}
          ></i>
        </div>
      ),
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Contest Round</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/contest">Contests</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Contest Round</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Contest Round</h5>
                    </Col>
                    <Col md={2}>
                      <Link
                        className="btn btn-primary"
                        to={`/contest-round/create/${params.contest_id}`}
                      >
                        Add Contest Round
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Something went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={contestrounds}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <div>
                              <SearchBar {...props.searchProps} />
                              <ClearSearchButton
                                {...props.searchProps}
                                className="btn-primary fw-bold ms-2"
                              />
                            </div>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>

                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete Contest Round?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeContestRound}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}
export default ContestRoundList
