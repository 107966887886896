import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import config from './config';

const sasToken = config.sasToken
const storageAccountName = config.StorageAccount; 

export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}

const createBlobInContainer = async (containerClient, file, dir) => {
    const fn = file.name.replace(/[^a-zA-Z0-9\.]/g, "")
    const fileName = (new Date()).getTime() + "_" + Math.random() + "_" + fn;
    let fileLocation = "";

    if(dir){
      fileLocation= `${dir}/`
    }
    const blobClient = containerClient.getBlockBlobClient(`${fileLocation}` + fileName);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    const res =  await blobClient.uploadData(file, options);
    if(res?._response?.status === 201){
      return fileName;
    }
    return ""
}

const uploadFileToBlob = async (file, container: string, dir : string = "") => {
  if (!file) return [];
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient = blobService.getContainerClient(container);
  await containerClient.createIfNotExists({
    access: 'container',
  });
  const fileName = await createBlobInContainer(containerClient, file, dir);
  if(fileName){
    return `${fileName}`;
  }else{
    return ""
  }
  
};

export default uploadFileToBlob;