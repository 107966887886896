import { useEffect, useState } from "react"
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getPageListAsync, PageState } from "../PageSlice"
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa"
import { PageDetailInterface } from "../models/PageDetailInterface"
import ModuleConfig from "./../config/ModuleConfig"
import CustomPagination from "../../../utils/CustomPagination"
import { deletePage } from "../PageAPI"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

/**
 * Pageing list page
 */
const PageList = () => {
  const { pages, status, totalRecord } = useAppSelector(PageState)
  const [page, setPage] = useState(1)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  const [deleteItem, setDeleteItem] = useState("")

  useEffect(() => {
    getPageData()
  }, [dispatch, page])

  const getPageData = () => {
    const filter = {
      page: page,
    }
    dispatch(getPageListAsync(filter))
  }

  const removePage = async () => {
    const res = await deletePage(deleteItem)
    setDeleteItem("")
    getPageData()
  }

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "slug",
      text: "Slug",
      sort: true,
    },
    {
      dataField: "Language.language_name",
      text: "Language",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          <i
            className="feather icon-edit text-success fw-bold"
            onClick={() => {
              navigate(`/page/update/${row.page_id}`)
            }}
          ></i>
        </div>
      ),
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Page</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Pages</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Pages</h5>
                    </Col>
                    <Col md={2}>
                      <Link className="btn btn-primary" to="/page/create">
                        Add Page
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Something went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={pages}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <div>
                              <SearchBar {...props.searchProps} />
                              <ClearSearchButton
                                {...props.searchProps}
                                className="btn-primary fw-bold ms-2"
                              />
                            </div>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Page?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removePage}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}
export default PageList
