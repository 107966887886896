import AxiosInstance from "../../utils/AxiosInstance"
import { PodcastDetailInterface } from "./models/PodcastDetailInterface"

const API_ROUTE = "podcast"
export const getPodcastList = async (params: any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`)
}

export const getPodcastDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/get-by-id/${index}`)
}

export const createPodcast = async (data: PodcastDetailInterface) => {
  return await AxiosInstance.post(`/${API_ROUTE}/create`, data)
}

export const deletePodcast = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/delete-By-Id/${index}`)
}
export const updatePodcast = async (
  index: string,
  data: PodcastDetailInterface
) => {
  return await AxiosInstance.put(`/${API_ROUTE}/update`, data)
}
