import { useEffect, useState } from "react"
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getUserListAsync, UserState } from "../../user/UserSlice"
import AxiosInstance from "../../../utils/AxiosInstance"
import { useAlert } from "react-alert"
import { SearchBar } from "@progress/kendo-react-dropdowns"

import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import { DropDownButton } from "@progress/kendo-react-buttons"
import { Field } from "formik"
/**
 * Contesting list page
 */
const ContestUserList = (props: any) => {
  const { status } = useAppSelector(UserState)
  const [page, setPage] = useState(1)
  const [userData, setUserData] = useState([])
  const [creditData, setCreditData] = useState("")
  const [showEditModel, setShowEditModel] = useState(false)
  const [showModelUserId, setShowModelUserId] = useState("")
  const dispatch = useAppDispatch()
  const params = useParams()
  const alert = useAlert()
  const [showModel, setShowModel] = useState({ id: "", credit: "" })
  const { ExportCSVButton } = CSVExport

  const [searchText, setSearchText] = useState("")
  const [searchStatus, setSearchStatus] = useState("")

  useEffect(() => {
    getPageData()
  }, [dispatch, page])

  const getPageData = async () => {
    const res: any = await AxiosInstance.get(
      `users/get-by-contest-id/${params?.contest_id}`
    )
    if (res?.data?.statusCode === 200) {
      setUserData(res?.data?.data)
    }
  }

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const handleSubmitCredit = async () => {
    const data = {
      user_id: showModel?.id,
      contest_id: params?.contest_id,
      credit: creditData,
    }
    const res: any = await AxiosInstance.post("contest-credit/create", data)
    if (res?.data?.statusCode === 200) {
      alert.success("Credit added successfully")
      getPageData()
      setShowModel({ id: "", credit: "" })
      setCreditData("")
      setShowEditModel(false)
    } else if (res.message) {
      setShowModel({ id: "", credit: "" })
      alert.error(res.message)
    }
  }

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: userData.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const columns = [
    {
      dataField: "nick_name",
      text: "Username",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "ContestCredits[0].credit",
      text: "Credit",
      sort: true,
      formatter: (cell: any, row: any) => {
        if (cell == undefined) {
          return null
        } else {
          return cell.toLocaleString()
        }
      },
      csvFormatter: (cell: any, row: any) => {
        if (cell == undefined) {
          return ""
        } else {
          return cell.toLocaleString()
        }
      },
    },

    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          {props.constestStatus != "Close" ? (
            <i
              className="feather icon-edit text-success fw-bold"
              onClick={() => {
                setShowModel({
                  id: row.user_id,
                  credit:
                    row?.ContestCredits.length >= 1
                      ? row?.ContestCredits[0]?.credit
                      : 0,
                })
              }}
            ></i>
          ) : null}
        </div>
      ),
      csvFormatter: (cell: any, row: any) => "",
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const listData = userData.filter((e: any) => {
    return (
      (e.nick_name?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
        e?.email.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
        (e?.ContestCredits[0]?.credit != undefined
          ? e?.ContestCredits[0]?.credit
              .toString()
              .indexOf(searchText.toString()) >= 0
          : null)) &&
      (searchStatus == "Applied" ? e?.ContestCredits[0]?.credit >= 0 : e)
    )
  })

  return (
    <>
      <Modal
        centered
        show={showModel.id != ""}
        onHide={() => setShowModel({ id: "", credit: "" })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add user credit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="number"
            name="credit"
            min={1}
            max={999}
            value={creditData}
            className="form-control"
            onChange={(event) => {
              setCreditData(event.target.value)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowEditModel(false)
              setShowModel({ id: "", credit: "" })
            }}
          >
            Close
          </Button>
          <Button
            className="btn-success"
            variant="primary"
            onClick={() => {
              if (creditData == "") {
                alert.error("Credit is required")
                setShowEditModel(false)
                setShowModel({ id: "", credit: "" })
              } else {
                handleSubmitCredit()
              }
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">User</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>User</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>User</h5>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Something went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={listData}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <>
                            <Row>
                              <Col>
                                <input
                                  placeholder="Search"
                                  type={"text"}
                                  value={searchText}
                                  className="form-control"
                                  onChange={(e) => {
                                    setSearchText(e.target.value)
                                  }}
                                />
                              </Col>
                              <Col>
                                {" "}
                                <select
                                  className="form-control "
                                  onChange={(e) => {
                                    setSearchStatus(e.target.value)
                                  }}
                                >
                                  <option
                                    selected={searchStatus == ""}
                                    value=""
                                  >
                                    All
                                  </option>
                                  <option selected={searchStatus == "Applied"}>
                                    Applied
                                  </option>
                                </select>
                              </Col>
                              <Col>
                                <button
                                  onClick={() => {
                                    setSearchStatus("")
                                    setSearchText("")
                                  }}
                                  className="btn btn-primary"
                                >
                                  Clear
                                </button>
                                <ExportCSVButton
                                  {...props.csvProps}
                                  className="btn-success fw-bold"
                                >
                                  Export CSV
                                </ExportCSVButton>
                              </Col>
                            </Row>
                            <Row>
                              <BootstrapTable
                                filter={filterFactory()}
                                pagination={paginationFactory(options)}
                                {...props.baseProps}
                              />
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  // return (
  //   <div className="contest-user-table">
  //     <div className="row">
  //       <div className="col-xl-12">
  //         <div className="card">
  //           <div className="card-block table-border-style">
  //             {status === "loading" ? (
  //               <Spinner animation="border" role="status">
  //                 <span className="visually-hidden">Loading...</span>
  //               </Spinner>
  //             ) : status === "failed" ? (
  //               <Alert key={"danger"} variant={"danger"}>
  //                 Something went wrong please try again
  //               </Alert>
  //             ) : (
  //               <>
  //                 <table className="table table-bordered">
  //                   <thead>
  //                     <tr>
  //                       <th>Username</th>
  //                       <th>Email</th>
  //                       <th>Contest Credit</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     {userData && userData?.length > 0 ? (
  //                       userData.map((user, index) => {
  //                         return (
  //                           <>
  //                             <tr key={index}>
  //                               <td>{user?.nick_name}</td>
  //                               <td>{user?.email}</td>
  //                               <td>
  //                                 {!showEditModel ? (
  //                                   <>
  //                                     <div className="row">
  //                                       <div className="col-7">
  //                                         {user?.ContestCredits[0]?.credit
  //                                           ? user?.ContestCredits[0]?.credit
  //                                           : 0}
  //                                       </div>
  //                                       <div className="col-5">
  //                                         <span
  //                                           key={user?.user_id}
  //                                           className="blueiconbtn"
  //                                           onClick={() => {
  //                                             setShowModelUserId(user?.user_id)
  //                                             setShowEditModel(true)
  //                                           }}
  //                                         >
  //                                           <img src="/assets/images/edit.svg" />
  //                                         </span>
  //                                       </div>
  //                                       {showEditModel ? (
  //                                         <div className="row">
  //                                           <div className="col-7">
  //                                             {/* {showModelUserId} */}
  //                                             <input
  //                                               type="number"
  //                                               name="credit"
  //                                               min={1}
  //                                               max={999}
  //                                               value={
  //                                                 user?.ContestCredits?.credit
  //                                               }
  //                                               className="form-control"
  //                                               onChange={(event) => {
  //                                                 setCreditData(
  //                                                   event.target.value
  //                                                 )
  //                                               }}
  //                                             />
  //                                           </div>
  //                                           <div className="col-5">
  //                                             <button
  //                                               className="btn btn-primary"
  //                                               onClick={() => {
  //                                                 handleSubmitCredit()
  //                                                 // user?.user_id
  //                                               }}
  //                                             >
  //                                               Save
  //                                             </button>
  //                                           </div>
  //                                         </div>
  //                                       ) : null}
  //                                     </div>
  //                                   </>
  //                                 ) : (
  //                                   <div className="row">
  //                                     {showModelUserId === user?.user_id ? (
  //                                       <>
  //                                         <div className="col-7">
  //                                           <input
  //                                             type="number"
  //                                             name="credit"
  //                                             min={1}
  //                                             max={999}
  //                                             className="form-control"
  //                                             onChange={(event) => {
  //                                               setCreditData(
  //                                                 event.target.value
  //                                               )
  //                                             }}
  //                                           />
  //                                         </div>
  //                                         <div className="col-5">
  //                                           <button
  //                                             className="btn btn-primary"
  //                                             onClick={() => {
  //                                               handleSubmitCredit()
  //                                               // user?.user_id
  //                                             }}
  //                                           >
  //                                             Save
  //                                           </button>
  //                                         </div>
  //                                       </>
  //                                     ) : (
  //                                       <>
  //                                         <div className="col-7">
  //                                           {user?.ContestCredits?.credit}
  //                                         </div>
  //                                         <div className="col-5">
  //                                           <span
  //                                             key={user?.user_id}
  //                                             className="blueiconbtn"
  //                                             onClick={() => {
  //                                               setShowModelUserId(
  //                                                 user?.user_id
  //                                               )
  //                                               setShowEditModel(true)
  //                                             }}
  //                                           >
  //                                             <img src="/assets/images/edit.svg" />
  //                                           </span>
  //                                         </div>
  //                                       </>
  //                                     )}
  //                                   </div>
  //                                 )}
  //                               </td>
  //                             </tr>
  //                           </>
  //                         )
  //                       })
  //                     ) : (
  //                       <div>There is no Data</div>
  //                     )}
  //                     <tr></tr>
  //                   </tbody>
  //                 </table>
  //               </>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}
export default ContestUserList
