import { Route } from "react-router-dom";
import AchievementCreate from "./pages/AchievementCreate";
import AchievementList from "./pages/AchievementList";
import AchievementDetails from "./pages/AchievementDetails"

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "achievement";
const AchievementRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<AchievementList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<AchievementCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:achievement_id`} element={<AchievementCreate />}  key={`${MODULE_ROUTE}_edit`}  />,
    <Route path={`${MODULE_ROUTE}/details/:achievement_id`} element={<AchievementDetails />} key={`${MODULE_ROUTE}_view`} />,
  ];

export default AchievementRoute;