import AxiosInstance from "../../utils/AxiosInstance";
import { TeamDetailInterface } from "./models/TeamDetailInterface";

const API_ROUTE = "teams";
export const getTeamList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getTeamDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}//get-by-id/${index}`);
};

export const createTeam = async (data: TeamDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }

  return await AxiosInstance.post(`/${API_ROUTE}/create`, data);
};

export const deleteTeam = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/delete-by-id/${index}`);
};
export const updateTeam = async (index: string, data: TeamDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.put(`/${API_ROUTE}/update`, data);
};
