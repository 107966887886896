import { getPropByString } from "../../../utils/CommonFunction"

const TextInput = (props: any) => {
    return (
        <input 
            type={props.type}
            readOnly={props?.readOnly}
            name={props.name}
            id={props.name}
            disabled={props.disabled}
            maxLength={props?.fieldDetails?.maxLength}
            max={props?.fieldDetails?.max}
            min={props?.fieldDetails?.min}
            value={getPropByString(props.values, props.name)}
            className="form-control"
            pattern={props?.fieldDetails?.pattern}
            onBlur={props.handleBlur}
            onChange={(e) => {
                if(e.target.value && props?.fieldDetails.type ==  "number" && props?.fieldDetails.min && props?.fieldDetails.max) {
                    if(e.target.value >= props?.fieldDetails.min && e.target.value <= props?.fieldDetails.max){
                        props.handleChange(e)
                    }
                }else{
                    props.handleChange(e)
                }
                
            }}
            placeholder={props.fieldDetails.label} />
    )
}

export default TextInput;