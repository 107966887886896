import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDashboard, getLoginLanguageList, getProfile } from "./appAPI";

export interface AppStateInterface {
  profile: any;
  dashboard: any;
  isMobileMenuOpen: boolean;
  isUserLoggedIn: boolean;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  languages : any,
  loader : boolean
}

const initialState: AppStateInterface = {
  profile: {},
  dashboard : {},
  isMobileMenuOpen: false,
  isUserLoggedIn: false,
  status: "idle",
  languages: [
    {
      code : "en",
      name : "English"
    }
  ],
  loader: false
};

export const getProfileAsync = createAsyncThunk("/", async () => {
  const response = await getProfile();
  return response.data;
});

export const getDashboardAsync = createAsyncThunk("/dashboard", async () => {
  const response = await getDashboard();
  return response.data;
});

export const getLoginLanguageListAsync = createAsyncThunk(
  "language/list",
  async (data: any) => {
    const response = await getLoginLanguageList();
    return response.data;
  }
);


export const appSlice = createSlice({
  name: "app",
  initialState,

  reducers: {
    setProfile: (state, action: PayloadAction<[]>) => {
      state.profile = action.payload;
    },
    setLoader:( state, action:PayloadAction<boolean>) =>{
      state.loader = action.payload
    }
  },

  extraReducers: (builder) => {
    builder

      // get profile
      .addCase(getProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.profile = action.payload.data;
      })
      .addCase(getProfileAsync.rejected, (state) => {
        state.status = "failed";
      })

      // get Dashboard
      .addCase(getDashboardAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.dashboard = action.payload.data;
      })
      .addCase(getDashboardAsync.rejected, (state) => {
        state.status = "failed";
      })

      // get Language
      .addCase(getLoginLanguageListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLoginLanguageListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        let lang = [];
        for(var  i = 0; i < action.payload.data.length; i++){
          lang.push({
            code : action.payload.data[i].language_code,
            name :  action.payload.data[i].language_name,
          })
        };

        state.languages = lang;
      })
      .addCase(getLoginLanguageListAsync.rejected, (state) => {
        state.status = "failed";
      });

  },
});

export const { setProfile, setLoader } = appSlice.actions;
export const appState = (state: any) => state.app;
export default appSlice.reducer;
