const ModuleConfig = {
  primaryKey: "media_gallery_id",

  formDefaultValue: {
    title: "",
    url: "",
  },

  formFields: {
    title: {
      label: "Media Gallery Title",
      isRequired: true,
      colWidth: "col-md-6",
      type: "text",
    },

    url: {
      label: "Media Gallery Image",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
      inputType: "file",
    },
  },

  listColumnOrder: [
    "title",
    "url"
  ],
}

export default ModuleConfig
