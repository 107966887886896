import ArrayType from "./ArrayType"
import Checkbox from "./Checkbox"
import CkEditor from "./CkEditor"
import CDatePicker from "./DatePicker"
import FileInput from "./FileInput"
import ImagePreview from "./ImagePreview"
import InputSelect from "./InputSelect"
import MyDropzone from "./MyDropzone"
import TextAreaInput from "./TextAreaInput"
import TextInput from "./TextInput"
import Radio from "./Radio"
import CEditor from "./CEditor"
import MultiSelect from "./MultiSelect"
import MultiDropzone from "./MultiDropzone"

const FormField = (props: {
  name: string
  readOnly?: boolean
  isGrouped?: boolean
  fieldDetails: any
  values: any
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  setFieldValue?: any
  addMoreFields?: any
  maxLength?: any
  index?: any
  pattern?: any
  min?: any
  max?: any
  disabled?: any
  filterParams?: string
  multiple?: boolean
  accept?: string
}) => {
  const getField = () => {
    if (props.fieldDetails.inputType === "select") {
      return <InputSelect {...props} />
    }
    if (props.fieldDetails.inputType === "dropzone") {
      return <MultiDropzone {...props} />
    }
    if (props.fieldDetails.inputType === "multiselect") {
      return <MultiSelect {...props} />
    }
    if (props.fieldDetails.inputType === "textarea") {
      return <TextAreaInput {...props} />
    }
    if (props.fieldDetails.inputType === "ckeditor") {
      return <CkEditor {...props} />
    }
    if (props.fieldDetails.inputType === "editor") {
      return <CkEditor {...props} />
    }
    if (props.fieldDetails.inputType === "dropzone") {
      return <MyDropzone {...props} />
    }
    if (props.fieldDetails.inputType === "datepicker") {
      return <CDatePicker {...props} />
    }
    if (props.fieldDetails.inputType === "file") {
      return <FileInput {...props} />
    }
    if (props.fieldDetails.inputType === "ImagePreview") {
      return <ImagePreview {...props} />
    }
    if (props.fieldDetails.inputType === "array") {
      return <ArrayType {...props} />
    }
    if (props.fieldDetails.inputType === "checkbox") {
      return <Checkbox {...props} />
    }
    if (props.fieldDetails.inputType === "radio") {
      return <Radio {...props} />
    }
    return <TextInput type={props.fieldDetails.type} {...props} />
  }

  return (
    <div className="form-group mb-4">
      {props.fieldDetails.hideLabel !== true ? (
        <div className="input-label">{props.fieldDetails.label}</div>
      ) : (
        ""
      )}
      {getField()}
      {props.touched && props.touched[props.name] &&
      typeof props.errors[props.name] === "string" ? (
        <div className="error">{props.errors[props.name]}</div>
      ) : null}
    </div>
  )
}
export default FormField
