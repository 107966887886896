import AxiosInstance from "../utils/AxiosInstance";

export const getProfile = async () => {
  return await AxiosInstance.get("profile");
};

export const getDashboard = async () => {
  return await AxiosInstance.get("dashboard");
};
export const getLoginLanguageList = async () => {
  return await AxiosInstance.get("/language");
};
