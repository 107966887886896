import { CKEditor } from "ckeditor4-react"
import { useParams } from "react-router-dom"

const CkEditor = (props: any) => {

  
  return (
      <CKEditor
        initData={props.values[props.name]}
        name={props.name}
        onChange={(e) => {
          props.setFieldValue(props.name, e.editor.getData())
        }}
      />
  );
}

export default CkEditor
