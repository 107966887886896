import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../stores"
import { MediaGalleryDetailInterface } from "./models/MediaGalleryDetailInterface"
import {
  createMediaGallery,
  deleteMediaGallery,
  getMediaGalleryDetail,
  getMediaGalleryList,
  updateMediaGallery,
} from "./MediaGalleryAPI"

export interface MediaGalleryStateInterface {
  mediagalleries: MediaGalleryDetailInterface[]
  favourites: MediaGalleryDetailInterface[]
  totalRecord: number
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  detail: MediaGalleryDetailInterface
}

const initialState: MediaGalleryStateInterface = {
  mediagalleries: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    media_gallery_id: "",
    title: "",
    url: "",
  },
}

export const getMediaGalleryListAsync = createAsyncThunk(
  "home",
  async (data: any) => {
    const response = await getMediaGalleryList(data)
    return response.data
  }
)
export const getMediaGalleryDetailAsync = createAsyncThunk(
  "/home/get-by-id/:id",
  async (index: string) => {
    const response = await getMediaGalleryDetail(index)
    return response.data.data
  }
)

export const createMediaGalleryAsync = createAsyncThunk(
  "home/create",
  async (data: MediaGalleryDetailInterface) => {
    const response = await createMediaGallery(data)
    return response.data
  }
)

export const deleteMediaGalleryAsync = createAsyncThunk(
  "home/delete",
  async (index: string) => {
    const response = await deleteMediaGallery(index)
    return response.data
  }
)

export const updateMediaGalleryAsync = createAsyncThunk(
  "home/update",
  async (data: { index: string; data: MediaGalleryDetailInterface }) => {
    const response = await updateMediaGallery(data.index, data.data)
    return response.data
  }
)

export const mediagallerySlice = createSlice({
  name: "mediagallery",
  initialState,
  reducers: {
    resetDetailData: (state, action) => {
      state.detail = {}
    },
  },
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getMediaGalleryListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getMediaGalleryListAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.mediagalleries = action.payload.data
        state.totalRecord = action.payload.total
      })
      .addCase(getMediaGalleryListAsync.rejected, (state) => {
        state.status = "failed"
      })
      // GET DETAIL
      .addCase(getMediaGalleryDetailAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getMediaGalleryDetailAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.detail = action.payload
      })
      .addCase(getMediaGalleryDetailAsync.rejected, (state) => {
        state.status = "failed"
      })
      // CREATE
      .addCase(createMediaGalleryAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(createMediaGalleryAsync.fulfilled, (state, action) => {
        state.status = "created"
        state.detail = action.payload
      })
      .addCase(createMediaGalleryAsync.rejected, (state) => {
        state.status = "failed"
      })
      // UPDATE
      .addCase(updateMediaGalleryAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updateMediaGalleryAsync.fulfilled, (state, action) => {
        state.status = "updated"
        state.detail = action.payload
      })
      .addCase(updateMediaGalleryAsync.rejected, (state) => {
        state.status = "failed"
      })
      // DELETE
      .addCase(deleteMediaGalleryAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteMediaGalleryAsync.fulfilled, (state, action) => {
        state.status = "deleted"
        state.detail = action.payload
      })
      .addCase(deleteMediaGalleryAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const { resetDetailData } = mediagallerySlice.actions
export const MediaGalleryState = (state: RootState) => state.mediaGallery
export default mediagallerySlice.reducer
