const ModuleConfig = {
  primaryKey: "contest_id",

  formDefaultValue: {
    sport_id: "",
    banner: "",
    status: "",
    title: "",
    description: "",
    number_of_round: 0,
    reg_start_date_time: "",
    reg_close_date_time: "",
    price_pool: "",
    complexity: "",
    is_team: "",
    rules_file: "",
    check_info: "",
  },

  formFields: {
    sport_id: {
      label: "Sport",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Sport",
      asynchLoad: true,
      dataUrl: "sport/dropdown",
      filterParams:"filterField=status&filterValue=1",
      keyValue: "sport_id",
      keylabel: "sport_name",
      options: [],
    },

    banner: {
      label: "Banner",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },

    title: {
      label: "Title",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },

    description: {
      label: "Description",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "textarea",
      type: "textarea",
      maxLength: "300",
    },
    check_info: {
      label: "Prize Text",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "textarea",
      type: "textarea",
      maxLength: "300",
    },

    status_create: {
      label: "Status",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Draft",
          value: "Draft",
        },
        {
          label: "Open",
          value: "Open",
        }
      ],
    },

    status: {
      label: "Status",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Draft",
          value: "Draft",
        },
        {
          label: "Open",
          value: "Open",
        },
        {
          label: "In Progress",
          value: "In progress"
        },
        {
          label: "Close",
          value: "Close",
          isDisabled: true
        },
      ],
    },

    reg_start_date_time: {
      label: "Registration Start DateTime",
      isRequired: true,
      colWidth: "col-md-6",
      type: "date",
      inputType: "datepicker",
    },

    reg_close_date_time: {
      label: "Registration Close DateTime",
      isRequired: true,
      colWidth: "col-md-6",
      type: "date",
      inputType: "datepicker",
    },

    number_of_round: {
      label: "Number of Round",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "number",
      min:1,
      max:999,
    },

    price_pool: {
      label: "Prize Per Winning Entry",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "number",
      maxLength: "5",
      min:1,
      max:99999,
    },

    complexity: {
      label: "Complexity",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Complexity",
      options: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
    },

    is_team: {
      label: "Is Team?",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Is Team?",
      options: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
    },
    rules_file: {
      label: "Rules",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "file",
      type: "file",
    },

    sponsorship_id: {
      label: "Sponsor",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Sponsor",
      asynchLoad: true,
      dataUrl: "/sponsorship",
      filterParams:"filterField=status&filterValue=Active",
      keyValue: "sponsorship_id",
      keylabel: "company_name",
      options: [],
    },
  },

  listColumnOrder: [
    "is_team",
    "title",
    "complexity",
    "description",
    "sport_id",
    "status",
    "price_pool",
    "number_of_round",
    "reg_close_date_time",
    "reg_start_date_time",
  ],
}

export default ModuleConfig
