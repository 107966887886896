import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../stores"
import { VideosDetailInterface } from "./models/VideosDetailInterface"
import {
  createVideos,
  deleteVideos,
  getVideosDetail,
  getVideosList,
  updateVideos,
} from "./VideosAPI"

export interface VideosStateInterface {
  videoss: VideosDetailInterface[]
  favourites: VideosDetailInterface[]
  totalRecord: number
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  detail: VideosDetailInterface
}

const initialState: VideosStateInterface = {
  videoss: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    video_id: "",
    title: "",
    description: "",
    video_link : "",
    credit: null,
    language_id: "",
    author: "",
    sport_id: "",
    Sport: {},
    Language: {},
  },
}

export const getVideosListAsync = createAsyncThunk(
  "videos/list",
  async (data: any) => {
    const response = await getVideosList(data)
    return response.data
  }
)
export const getVideosDetailAsync = createAsyncThunk(
  "videos/detail",
  async (index: string) => {
    const response = await getVideosDetail(index)
    return response.data
  }
)

export const createVideosAsync = createAsyncThunk(
  "videos/create",
  async (data: VideosDetailInterface) => {
    const response = await createVideos(data)
    return response.data
  }
)

export const deleteVideosAsync = createAsyncThunk(
  "videos/delete",
  async (index: string) => {
    const response = await deleteVideos(index)
    return response.data
  }
)

export const updateVideosAsync = createAsyncThunk(
  "videos/update",
  async (data: { index: string; data: VideosDetailInterface }) => {
    const response = await updateVideos(data.index, data.data)
    return response.data
  }
)

export const videosSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getVideosListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getVideosListAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.videoss = action.payload.data
        state.totalRecord = action.payload.total
        state.detail = {}
      })
      .addCase(getVideosListAsync.rejected, (state) => {
        state.status = "failed"
      })
      // GET DETAIL
      .addCase(getVideosDetailAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getVideosDetailAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.detail = action.payload.data
      })
      .addCase(getVideosDetailAsync.rejected, (state) => {
        state.status = "failed"
      })
      // CREATE
      .addCase(createVideosAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(createVideosAsync.fulfilled, (state, action) => {
        state.status = "created"
        state.detail = action.payload
      })
      .addCase(createVideosAsync.rejected, (state) => {
        state.status = "failed"
      })
      // UPDATE
      .addCase(updateVideosAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updateVideosAsync.fulfilled, (state, action) => {
        state.status = "updated"
        state.detail = action.payload
      })
      .addCase(updateVideosAsync.rejected, (state) => {
        state.status = "failed"
      })
      // DELETE
      .addCase(deleteVideosAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteVideosAsync.fulfilled, (state, action) => {
        state.status = "deleted"
        state.detail = action.payload
      })
      .addCase(deleteVideosAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const {} = videosSlice.actions
export const VideosState = (state: RootState) => state.videos
export default videosSlice.reducer
