import AxiosInstance from "../../utils/AxiosInstance"
import { PrizeDetailInterface } from "./models/PrizeDetailInterface"

export const getPrizeList = async (params: any) => {
  // const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/contest-winners`)
}

export const getPrizeDetail = async (index: string) => {
  return await AxiosInstance.get(`/contest-winners/get-by-id/${index}`)
}

export const updatePrize = async (
  index: string,
  data: PrizeDetailInterface
) => {
  return await AxiosInstance.put(`/contest-winners/update`, data)
}
