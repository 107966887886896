import axios from "axios";

import { setLoader } from "../stores/appSlice"
import config from "./config";
import { store } from "../stores"
const baseURL = config.API_URL;

const AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 35000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer ",
    "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
  },
});

AxiosInstance.interceptors.request.use(
  async (config: any) => {
    store.dispatch(setLoader(true))
    axios.defaults.timeout = 35000;
    config.baseURL = baseURL;
    const token =  localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer ".concat(token);
    }
    return config;
  },
  (error) => Promise.reject(error.response.data)
);


AxiosInstance.interceptors.response.use(
  async (response) => {
    store.dispatch(setLoader(false))
    return response;
  },
  (errors) => {
    store.dispatch(setLoader(false))
    if(errors.response.data.statusCode === 401){
      localStorage.clear();
      window.location.href="/signin"
    }else if(errors.response.data.statusCode === 404 && window.location.href.indexOf("signin")){
      return errors.response.data; 
    }else if(errors.response.status === 404){
      window.location.href="/dashboard" 
    }
      else{
      return errors.response.data;  
    }
    
  }
);


export default AxiosInstance;
