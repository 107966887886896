const ModuleConfig = {
  primaryKey: "team_id",

  formDefaultValue: {
    name: "",
    description: "",
    logo: "",
    status: "" ,
    sport_id: "" ,
    is_individual:"" ,
  },

  formFields: {

    name: {
      label: "Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength:"50"
    },

    
    sport_id: {
      label: "Sport Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Sport",
      asynchLoad: true,
      dataUrl: "sport/dropdown",
      filterParams:"filterField=status&filterValue=1",
      keyValue:"sport_id",
      keylabel:"sport_name",
      options: [],
      disabled:true
    },
    
    description: {
      label: "Description",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "textarea",
      type: "text",
      maxLength:"300"
    },

    logo: {
      label: "Logo",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
      inputType:"file"
    },

    status: {
      label: "Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Active",
          value: "1",
        },
        {
          label: "Inactive",
          value: "0",
        },
      ],
    },

    is_individual: {
      label: "Is Individual ?",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Individual",
      options: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
    },


  },

  listColumnOrder: ["name", "description", "status"],
};

export default ModuleConfig;
