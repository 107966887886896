import { useEffect, useState } from "react"
import { Pagination, Row, Col } from "react-bootstrap"

const CustomPagination = (props: {
  total: number
  limit: number
  curretnPage: number
  setPage: Function
  setLimit?: Function,
  disableAfter?: number
}) => {
  const [pages, setPages] = useState<any[]>([1])

  useEffect(() => {
    if (props.total) {
      var min = props.curretnPage - 4
      var max = Math.ceil(props.total / props.limit)
      if (min <= 0) {
        min = 1
      }
      if (props.curretnPage < max - 4) {
        max = props.curretnPage + 4
      }
      var pg: any[] = []
      for (var i = min; i <= max; i++) {
        pg.push(i)
      }
      setPages(pg)
    }
  }, [props.total, props.limit, props.curretnPage])

  const updatePage = (page: number) => {
    props.setPage(page)
  }

  return (
    <div className="ss-pagination">
    <Row>
      <Col>
        {/* <Pagination className="justify-content-end"> */}
        <Pagination className="">
          {props.curretnPage > 5 ? (
            <>
              <Pagination.Item onClick={() => updatePage(1)}>
                {" "}
                1{" "}
              </Pagination.Item>
              {props.curretnPage > 6 ? (
                <Pagination.Item onClick={() => {}}> ... </Pagination.Item>
              ) : null}
            </>
          ) : null}

          {pages.map((page, indes) => {
            return (
              <Pagination.Item
                active={page === props.curretnPage}
                key={"apge" + indes}
                className={page > props.disableAfter ? "disabled" : ""}
                onClick={() => updatePage(page)}
              >
                {page}
              </Pagination.Item>
            )
          })}
          {pages[pages.length - 1] < props.total ? (
            <>
              {props.curretnPage < props.total - 5 ? (
                <Pagination.Item  onClick={() => {}}> ... </Pagination.Item>
              ) : null}
              <Pagination.Item className={props.total == props.curretnPage ? "" : "disabled"} onClick={() => updatePage(props.total)}>
                {" "}
                {props.total}{" "}
              </Pagination.Item>
            </>
          ) : null}
        </Pagination>
      </Col>
    </Row>
    </div>
  )
}

export default CustomPagination
