import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { SportDetailInterface } from "./models/SportDetailInterface";
import {
  createSport,
  deleteSport,
  getSportDetail,
  getSportList,
  updateSport,
} from "./SportAPI";

export interface SportStateInterface {
  sports: SportDetailInterface[];
  favourites: SportDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: SportDetailInterface;
}

const initialState: SportStateInterface = {
  sports: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    sport_id: "",
    logo: "",
    sport_name: "",
    status: "",
  },
};

export const getSportListAsync = createAsyncThunk(
  "sport/list",
  async (data: any) => {
    const response = await getSportList(data);
    return response.data;
  }
);
export const getSportDetailAsync = createAsyncThunk(
  "sport/detail",
  async (index: string) => {
    const response = await getSportDetail(index);
    return response.data;
  }
);

export const createSportAsync = createAsyncThunk(
  "sport/create",
  async (data: SportDetailInterface) => {
    const response = await createSport(data);
    return response.data;
  }
);

export const deleteSportAsync = createAsyncThunk(
  "sport/delete",
  async (index: string) => {
    const response = await deleteSport(index);
    return response.data;
  }
);

export const updateSportAsync = createAsyncThunk(
  "sport/update",
  async (data: { index: string; data: SportDetailInterface }) => {
    const response = await updateSport(data.index, data.data);
    return response.data;
  }
);

export const sportSlice = createSlice({
  name: "sport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getSportListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSportListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.sports = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getSportListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getSportDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSportDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload.data;
      })
      .addCase(getSportDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createSportAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSportAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createSportAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateSportAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSportAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateSportAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteSportAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSportAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteSportAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = sportSlice.actions;
export const SportState = (state: RootState) => state.sport;
export default sportSlice.reducer;
