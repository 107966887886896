import { ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getUserDetailAsync, UserState } from "../UserSlice"
import Config from "../../../utils/config"

const UserInfo = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(UserState)

  useEffect(() => {
    if (params.user_id) {
      dispatch(getUserDetailAsync(params.user_id))
    }
  }, [params.user_id])

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">User</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users">Users</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>User Info</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>User Info</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <span>
                    <img
                      className="rounded-circle-border"
                      height="150"
                      width="150"
                      src={`${Config.cdnUrl}${detail?.profile_image}`}
                    />{" "}
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12">
                    <label>Nick Name : </label>{" "}
                    <label>{detail?.nick_name}</label>
                  </div>
                  <div className="col-md-12">
                    <label>Email Id : </label>{" "}
                    <label>{detail?.email}</label>
                  </div>
                  <div className="col-md-12">
                    <label>Phone : </label>{" "}
                    <label>{detail?.phone}</label>
                  </div>
                  <div className="col-md-12">
                    <label>Media Credit : </label>{" "}
                    <label>{detail?.media_credit}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UserInfo
