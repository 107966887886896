import { Route } from "react-router-dom";
import UserReportCreate from "./pages/UserReportCreate";
import UserReportList from "./pages/UserReportList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "user-report"; 
const UserReportRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<UserReportList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<UserReportCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<UserReportCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default UserReportRoute;