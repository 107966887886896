const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    name: "",
    question_text: "",
    question_detail: "",

    questions: [
      {
        question_title: "",
        options: [
          { option_title: "", answer: false },
          { option_title: "", answer: false },
          { option_title: "", answer: false },
          { option_title: "", answer: false },
        ],
      },
    ]

  },

  formFields: {
    name: {
      label: "Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    dob: {
      label: "Date of Birth",
      isRequired: true,
      colWidth: "col-md-6",
      type: "date",
    },

    time: {
      label: "Time",
      isRequired: true,
      colWidth: "col-md-6",
      type: "time",
    },

    file: {
      label: "File",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
    },

    bio: {
      label: "Bio",
      isRequired: true,
      colWidth: "col-md-6",
      type: "textarea",
      inputType: "textarea",
    },

    gender: {
      label: "Gender",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Gender",
      isAdd: false,
      options: [
        {
          label: "Male",
          value: "male",
        },
        {
          label: "Female",
          value: "female",
        },
      ],
    },

    questions: {
      label: "Questions",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "array",
      type: "array",
      canAddMore: true,
      fields: [
        {
          question_title: {
            name: "question_title",
            hideLabel: true,
            label: "Question Title",
            isRequired: true,
            colWidth: "col-md-12",
            inputType: "text",
            type: "text",
          },
          options: {
            name: "options",
            label: "Options",
            isRequired: true,
            colWidth: "col-md-12",
            inputType: "array",
            type: "array",
            canAddMore: false,
            fields: [
              {
                option_title: {
                  name: "option_title",
                  hideLabel: true,
                  label: "Option Title",
                  isRequired: true,
                  colWidth: "col-md-8",
                  inputType: "text",
                  type: "text",
                },
                answer: {
                  name: "answer",
                  label: "Correct answer",
                  isRequired: true,
                  colWidth: "col-md-4",
                  inputType: "checkbox",
                  type: "checkbox",
                },
              },
              {
                option_title: {
                  name: "option_title",
                  label: "Option Title",
                  isRequired: true,
                  colWidth: "col-md-8",
                  inputType: "text",
                  type: "text",
                },
                answer: {
                  name: "answer",
                  label: "Correct answer",
                  isRequired: true,
                  colWidth: "col-md-4",
                  inputType: "checkbox",
                  type: "checkbox",
                },
              },
            ],
          },
        },
      ],
    },

    isConfirmed: {
      label: "Is Confirmed",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select",
      isAdd: false,
      options: [
        {
          label: "True",
          value: true,
        },
        {
          label: "False",
          value: false,
        },
      ],
    },
  },

  addMoreFields: {
    question_title: "",
    options: [
      { option_title: "", answer: false },
      { option_title: "", answer: false },
      { option_title: "", answer: false },
      { option_title: "", answer: false },
    ],
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
