import { Route } from "react-router-dom";
import LanguageCreate from "./pages/LanguageCreate";
import LanguageList from "./pages/LanguageList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "language"; 
const LanguageRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<LanguageList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<LanguageCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<LanguageCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default LanguageRoute;