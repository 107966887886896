import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import appReducer from "./appSlice"
import userReducer from "../modules/user/UserSlice"
import teamReducer from "../modules/team/TeamSlice"
import sportReducer from "../modules/sport/SportSlice"
import cmsReducer from "../modules/cms/CMSSlice"
import productReducer from "../modules/product/ProductSlice"
import contestReducer from "../modules/contest/ContestSlice"
import contestroundReducer from "../modules/contestround/ContestRoundSlice"
import contestroundgameReducer from "../modules/contestroundgame/ContestRoundGameSlice"
import languageReducer from "../modules/language/LanguageSlice"
import pageReducer from "../modules/page/PageSlice"
import sponsorshipReducer from "../modules/sponsorship/SponsorshipSlice"
import paymentReducer from "../modules/payment/PaymentSlice"
import reportReducer from "../modules/report/ReportSlice"
import userreportReducer from "../modules/userreport/UserReportSlice"
import webhomeReducer from "../modules/webhome/WebHomeSlice"
import prizeReducer from "../modules/prizes/PrizeSlice"
import mediaGalleryReducer from "../modules/mediaGallery/MediaGallerySlice"
import achievementReducer from "../modules/achievement/AchievementSlice"
import podcastReducer from "../modules/podcast/PodcastSlice"
import videosReducer from "../modules/videos/VideosSlice"
/* import store */
export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    team: teamReducer,
    sport: sportReducer,
    cms: cmsReducer,
    product: productReducer,
    contest: contestReducer,
    contestround: contestroundReducer,
    contestroundgame: contestroundgameReducer,
    language: languageReducer,
    page: pageReducer,
    sponsorship: sponsorshipReducer,
    payment: paymentReducer,
    report: reportReducer,
    userreport: userreportReducer,
    webhome: webhomeReducer,
    prize: prizeReducer,
    mediaGallery: mediaGalleryReducer,
    achievements: achievementReducer,
    podcast: podcastReducer,
    videos : videosReducer
    // {addStore}
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
