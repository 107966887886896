import AxiosInstance from "../../utils/AxiosInstance";
import { CMSDetailInterface } from "./models/CMSDetailInterface";

const API_ROUTE = "cmss";
export const getCMSList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/cms?${prm}`);
};

export const getCMSDetail = async (index: string) => {
  return await AxiosInstance.get(`/cms/get-By-Id/${index}`);
};

export const createCMS = async (data: CMSDetailInterface) => {
  return await AxiosInstance.post(`/cms/create`, data);
};

export const deleteCMS = async (index: string) => {
  return await AxiosInstance.delete(`/cms/delete-By-Id/${index}`);
};
export const updateCMS = async (index: string, data: CMSDetailInterface) => {
  return await AxiosInstance.put(`/cms/update`, data);
};
