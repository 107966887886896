import { Button, Container, Row, Table } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getWebHomeDetailAsync, resetDetailData, WebHomeState } from "../WebHomeSlice"
import { Formik } from "formik"
import { WebHomeDetailInterface } from "../models/WebHomeDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect } from "react"
import { createWebHome, updateWebHome } from "../WebHomeAPI"
import { propTypes } from "react-bootstrap/esm/Image"
import { useAlert } from "react-alert"
/**
 * WebHomeing Favourites list page
 */

const WebHomeCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(WebHomeState)
  const params = useParams()
  const navigator = useNavigate()
  const alert = useAlert()

  useEffect(() => {
    if (params.id) {
      dispatch(getWebHomeDetailAsync(params.id))
    }
  }, [dispatch, params.id])

  const handleSubmit = async (
    values: WebHomeDetailInterface,
    formMethod: any
  ) => {
    if (params.id) {
      let _section3Images = []
      for (let index = 0; index < values.section3_image?.length; index++) {
        if (values?.section3_image[index]?.section3_image === undefined) {
          _section3Images.push(values?.section3_image[index])
        } else {
          _section3Images.push(values?.section3_image[index]?.section3_image)
        }
      }
      values.section3_image = _section3Images
      const res: any = await updateWebHome(params.id, values)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        dispatch(resetDetailData({}))
        alert.success("Webhome Updated Successfully")
        navigator("/webhome")
      }
    } else {
      let _section3Images = []
      for (let index = 0; index < values.section3_image?.length; index++) {
        _section3Images.push(values?.section3_image[index]?.section3_image)
      }
      values.section3_image = _section3Images
      const res: any = await createWebHome(values)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        alert.success("Webhome Created Successfully")
        navigator("/webhome")
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Web Home</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/webhome">Web Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.id ? "Edit " : "Add "}Web Home</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.id ? "Edit " : "Add "}Web Home</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {params.id && params.id != detail.home_id ? (
                    <div></div>
                  ) : (
                    <Formik
                      initialValues={
                        detail[ModuleConfig.primaryKey] == params.id
                          ? detail
                          : ModuleConfig.formDefaultValue
                      }
                      validate={(values) => {
                        const errors: any = {}
                        const fields = Object.keys(ModuleConfig.formFields)
                        for (let index = 0; index < fields.length; index++) {
                          if (
                            !values[fields[index]] &&
                            ModuleConfig.formFields[fields[index]].isRequired
                          ) {
                            errors[fields[index]] = "Required"
                          }
                        }

                        return errors
                      }}
                      enableReinitialize={true}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            {Object.keys(ModuleConfig.formFields).map(
                              (field: string, index: number) => {
                                const fieldDetails =
                                  ModuleConfig.formFields[field]
                                return (fieldDetails.isAdd == false &&
                                  params.id) ||
                                  fieldDetails.isAdd == undefined ? (
                                  <div
                                    key={`field_${index}`}
                                    className={fieldDetails.colWidth}
                                  >
                                    <FormField
                                      name={field}
                                      fieldDetails={fieldDetails}
                                      accept={"image/png, image/jpeg"}
                                      values={values}
                                      errors={errors}
                                      addMoreFields={fieldDetails.addMoreFields}
                                      touched={touched}
                                      setFieldValue={setFieldValue}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                ) : null
                              }
                            )}
                          </div>
                          <hr />
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={status === "loading"}
                          >
                            Submit
                          </button>
                        </form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default WebHomeCreate
