import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { LanguageDetailInterface } from "./models/LanguageDetailInterface";
import {
  createLanguage,
  deleteLanguage,
  getLanguageDetail,
  getLanguageList,
  updateLanguage,
} from "./LanguageAPI";

export interface LanguageStateInterface {
  languages: LanguageDetailInterface[];
  favourites: LanguageDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: LanguageDetailInterface;
}

const initialState: LanguageStateInterface = {
  languages: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    language_id: "",
    language_name: "",
    language_code: "",
  },
};

export const getLanguageListAsync = createAsyncThunk(
  "language/list",
  async (data: any) => {
    const response = await getLanguageList(data);
    return response.data;
  }
);
export const getLanguageDetailAsync = createAsyncThunk(
  "language/detail",
  async (index: string) => {
    const response = await getLanguageDetail(index);
    return response.data.data;
  }
);

export const createLanguageAsync = createAsyncThunk(
  "language/create",
  async (data: LanguageDetailInterface) => {
    const response = await createLanguage(data);
    return response.data;
  }
);

export const deleteLanguageAsync = createAsyncThunk(
  "language/delete",
  async (index: string) => {
    const response = await deleteLanguage(index);
    return response.data;
  }
);

export const updateLanguageAsync = createAsyncThunk(
  "language/update",
  async (data: { index: string; data: LanguageDetailInterface }) => {
    const response = await updateLanguage(data.index, data.data);
    return response.data;
  }
);

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getLanguageListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLanguageListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.languages = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getLanguageListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getLanguageDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLanguageDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getLanguageDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createLanguageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createLanguageAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createLanguageAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateLanguageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateLanguageAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateLanguageAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteLanguageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteLanguageAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteLanguageAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = languageSlice.actions;
export const LanguageState = (state: RootState) => state.language;
export default languageSlice.reducer;
