const ModuleConfig = {
  primaryKey: "team_id",

  formDefaultValue: {
    achievement_id: "",
    catalog_url: "",
    event_title: "",
    event_description: "",
    user_ids: [],
    type: "",
    contest_id: "",
    credit: "",
    event_image: "",
    shopping_amount: "",
  },

  formFields: {
    event_title: {
      label: "Event Title",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },

    contest_id: {
      label: "Contest",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Contest",
      asynchLoad: true,
      dataUrl: "achievement/list-contest",
      filterParams: "",
      keyValue: "contest_id",
      keylabel: "title",
      options: [],
    },

    event_description: {
      label: "Event Description",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "textarea",
      type: "text",
      maxLength: "300",
    },

    event_image: {
      label: "Event Image",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
      inputType: "file",
    },

    catalog_url: {
      label: "Catalog Product",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Product",
      asynchLoad: true,
      dataUrl: "products.json",
      keyValue: "id",
      keylabel: "title",
      options: [],
    },

    credit: {
      label: "Credit",
      colWidth: "col-md-6",
      inputType: "number",
      type: "number",
    },

    shopping_amount: {
      label: "Shopping Amount",
      colWidth: "col-md-6",
      inputType: "number",
      type: "number",
    },

    type: {
      label: "Type",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Type",
      options: [
        {
          label: "Entrant",
          value: "Entrant",
        },
        {
          label: "Winner",
          value: "Winner",
        },
        {
          label: "Media credit buy",
          value: "Media credit buy",
        },
        {
          label: "Media credit used",
          value: "Media credit used",
        },
      ],
    },
  },
}

export default ModuleConfig
