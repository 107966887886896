import { Route } from "react-router-dom";
import UserCreate from "./pages/UserCreate";
import UserList from "./pages/UserList";
import UserInfo from "./pages/UserInfo";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "users"; 
const UserRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<UserList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<UserCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:user_id`} element={<UserCreate />} key={`${MODULE_ROUTE}_edit`} />,
    <Route path={`${MODULE_ROUTE}/info/:user_id`} element={<UserInfo />} key={`${MODULE_ROUTE}_info`} />,
  ];

export default UserRoute;