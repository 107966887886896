import { Button, Container, Row, Table, Col } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getPodcastDetailAsync, PodcastState } from "../PodcastSlice"
import { Formik } from "formik"
import { PodcastDetailInterface } from "../models/PodcastDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect, useState } from "react"
import { createPodcast, updatePodcast } from "../PodcastAPI"
import Config from "../../../utils/config"
import { appState } from "../../../stores/appSlice"
import { useAlert } from "react-alert"

/**
 * Podcasting Favourites list page
 */
const PodcastCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(PodcastState)
  const { languages } = useAppSelector(appState)
  const params = useParams()
  const navigator = useNavigate()
  const [en, setEn] = useState("")
  const alert = useAlert()

  useEffect(() => {
    if (params.podcast_id) {
      dispatch(getPodcastDetailAsync(params.podcast_id))
    }
  }, [params.podcast_id])

  const handleSubmit = async (
    values: PodcastDetailInterface,
    formMethod: any
  ) => {
    if (params.podcast_id) {
      let postData = { ...values }
      delete postData.Sport
      delete postData.Language
      const res: any = await updatePodcast(params.podcast_id, postData)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else if (res.statusCode >= 300) {
        alert.error(res.message)
      } else {
        dispatch(getPodcastDetailAsync(params.podcast_id))
        alert.success("Podcast Updated Successfully")
        navigator("/podcast")
      }
    } else {
      let postData = { ...values }
      delete postData.Sport
      delete postData.Language

      const res: any = await createPodcast(postData)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        if (res?.statusCode == 201 || res?.statusCode == 200) {
          alert.success("Podcast Added Successfully")
        }
        navigator("/podcast")
      }
    }
  }

  const sportDetails = () => {
    return (
      <>
        <span>
          <img
            className="rounded-circle rounded-circle-border"
            height="50"
            width="50"
            src={`${Config.cdnUrl}${detail?.Sport?.logo}`}
          />{" "}
          {detail?.Sport?.sport_name?.en}{" "}
        </span>
      </>
    )
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Podcast</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/podcast">Podcast</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.podcast_id ? "Edit " : "Add "}Podcast</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Row>
                <Col>
                  <h5 className="col-md-6">
                    {params.podcast_id ? "Edit " : "Add "}Podcast
                  </h5>
                </Col>
                <Col>
                  <div className="text-end">
                    {params.podcast_id ? sportDetails() : null}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {(params.podcast_id &&
                    params.podcast_id == detail.podcast_id) ||
                  !params.podcast_id ? (
                    <Formik
                      initialValues={
                        params.podcast_id &&
                        detail[ModuleConfig.primaryKey] == params.podcast_id
                          ? detail
                          : ModuleConfig.formDefaultValue
                      }
                      validate={(values) => {
                        const errors: any = {}
                        const fields = Object.keys(ModuleConfig.formFields)
                        for (let index = 0; index < fields.length; index++) {
                          if (
                            !values[fields[index]] &&
                            ModuleConfig.formFields[fields[index]].isRequired
                          ) {
                            errors[fields[index]] = "Required"
                          }
                        }

                        return errors
                      }}
                      enableReinitialize={true}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            {Object.keys(ModuleConfig.formFields).map(
                              (field: string, index: number) => {
                                const fieldDetails =
                                  ModuleConfig.formFields[field]
                                return (fieldDetails.isAdd == false &&
                                  params.podcast_id) ||
                                  fieldDetails.isAdd == undefined ? (
                                  <div
                                    key={`field_${index}`}
                                    className={fieldDetails.colWidth}
                                  >
                                    <FormField
                                      name={field}
                                      fieldDetails={fieldDetails}
                                      accept={fieldDetails.accept}
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={fieldDetails.maxLength}
                                      disabled={
                                        params?.podcast_id &&
                                        fieldDetails.disabled
                                      }
                                      setFieldValue={setFieldValue}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                ) : null
                              }
                            )}
                          </div>
                          <hr />
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </form>
                      )}
                    </Formik>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PodcastCreate
