import { useEffect, useState } from "react"
import { Alert, Col, Row, Spinner } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getContestsAsync, UserReportState } from "../UserReportSlice"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import Select, { components } from "react-select"
import AxiosInstance from "./../../../utils/AxiosInstance"
import { useAlert } from "react-alert"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import {
  getContestDropdown,
  getContestRoundDetail,
} from "../../contest/ContestAPI"
import { getAllUsersAsync } from "../UserReportSlice"
import {
  getAllUsers,
  getContests,
  getReportData,
  getRounds,
} from "../UserReportAPI"
const { SearchBar, ClearSearchButton } = Search

/**
 * UserReporting list page
 */

const formatGroupLabel = (data) => (
  <div>
    <span>{data.label}</span>
    <span>{data.options.length}</span>
  </div>
)

const UserReportList = () => {
  const { userreports, status, totalRecord } = useAppSelector(UserReportState)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [userData, setUserData] = useState<any>([])
  const [contestData, setContestData] = useState<any>([])
  const [optionsData, setOptionsData] = useState<any>([])
  const [selectedUserData, setSelectedUserData] = useState<any>([])
  const [selectedContestData, setSelectedContestData] = useState<any>([])
  const [selectedRoundData, setSelectedRoundData] = useState<any>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const alert = useAlert()
  const { ExportCSVButton } = CSVExport
  const [showTable, setShowTable] = useState(false)
  const [listData, setListData] = useState<any>([])
  const [roundData, setRoundData] = useState<any>([])

  const [allRounds, setAllRounds] = useState<any>([])

  useEffect(() => {
    getAllUserData()
  }, [])

  useEffect(() => {
    if (selectedUserData && selectedUserData?.value) {
      getContestDropdownData()
    }
  }, [selectedUserData])

  useEffect(() => {
    if (selectedContestData && selectedContestData[0]?.value) {
      getPageData()
    }
  }, [selectedContestData])

  const getAllUserData = async () => {
    const res: any = await getAllUsers()
    if (res?.data?.data?.length > 0) {
      const opts: any = []
      for (let index = 0; index < res?.data?.data?.length; index++) {
        opts.push({
          value: res?.data?.data[index].user_id,
          label: res?.data?.data[index].nick_name,
        })
      }
      setUserData(opts)
    }
  }

  const getContestDropdownData = async () => {
    const prms = {
      user_id: [selectedUserData?.value],
    }
    const res: any = await getContests(prms)
    if (res?.data?.data?.length > 0) {
      const opts: any = [
        {
          value: "all",
          label: "All Contests",
        },
      ]
      for (let index = 0; index < res?.data?.data?.length; index++) {
        opts.push({
          value: res?.data?.data[index].contest_id,
          label: res?.data?.data[index].title.en
            ? res?.data?.data[index].title.en
            : res?.data?.data[index].title,
        })
      }
      setContestData(opts)
    }
  }

  const getPageData = async () => {
    let ContestData = []

    for (let index = 0; index < selectedContestData.length; index++) {
      if (selectedContestData[index]?.value == "all") {
        for (let index = 1; index < contestData.length; index++) {
          ContestData.push(contestData[index]?.value)
        }
      }
    }

    if (ContestData.length == 0) {
      for (let index = 0; index < selectedContestData.length; index++) {
        ContestData.push(selectedContestData[index]?.value)
      }
    }

    const prms = {
      user_id: [selectedUserData?.value],
      contest_id: ContestData,
    }
    const res: any = await getRounds(prms)
    if (res?.data?.data.length > 0) {
      const opts: any = [
        {
          label: "All Rounds",
          value: "all",
        },
      ]
      let AllRounds = []
      for (let index = 0; index < res?.data?.data?.length; index++) {
        let rounds = []
        for (var j = 0; j < res?.data?.data[index].ContestRounds.length; j++) {
          AllRounds.push(
            res?.data?.data[index].ContestRounds[j].contest_round_id
          )
          rounds.push({
            value: res?.data?.data[index].ContestRounds[j].contest_round_id,
            label: res?.data?.data[index].ContestRounds[j].title.en,
          })
        }
        opts.push({
          value: res?.data?.data[index].contest_round_id,
          label: res?.data?.data[index].title.en
            ? res?.data?.data[index].title.en
            : res?.data?.data[index].title,
          options: rounds,
        })
      }

      setAllRounds(AllRounds)
      setRoundData(opts)
    } else {
      alert.error("Data Not Found")
    }
  }

  useEffect(() => {
    if (contestData?.length > 0) {
      groupData()
    }
  }, [])

  const handleSubmit = async () => {
    let contestRoundId = []

    for (let index = 0; index < selectedRoundData.length; index++) {
      if (selectedRoundData[index]?.value == "all") {
        contestRoundId = allRounds
      }
    }

    if (contestRoundId.length == 0) {
      for (let index = 0; index < selectedRoundData.length; index++) {
        contestRoundId.push(selectedRoundData[index]?.value)
      }
    }

    let ContestData = []

    for (let index = 0; index < selectedContestData.length; index++) {
      if (selectedContestData[index]?.value == "all") {
        for (let index = 1; index < contestData.length; index++) {
          ContestData.push(contestData[index]?.value)
        }
      }
    }

    if (ContestData.length == 0) {
      for (let index = 0; index < selectedContestData.length; index++) {
        ContestData.push(selectedContestData[index]?.value)
      }
    }
    const requestedData = {
      contest_round_id: contestRoundId,
      contest_id: ContestData,
      user_id: [selectedUserData?.value],
    }

    const res: any = await getReportData(requestedData)
    if (res?.statusCode === 404) {
      setShowTable(false)
      alert.error(res?.message)
    } else if (res.data?.statusCode === 200 || res.data?.statusCode === 201) {
      if (res.data?.data?.rows?.length == 0) {
        setListData([])
        alert.error("Data not Found")
        setShowTable(false)
      } else {
        setListData(res.data?.data.rows)
        setShowTable(true)
      }
    } else {
      setShowTable(false)
      alert.error("Something Gone Wrong")
    }
  }

  const groupData = async () => {
    let _optionsData = []
    for (var i = 0; i < contestData?.length; i++) {
      let rounds = []
      for (var j = 0; j < contestData[i].ContestRounds?.length; j++) {
        rounds.push({
          label: contestData[i].ContestRounds[j].title?.en,
          value: contestData[i].ContestRounds[j].contest_round_id,
          contest_id: contestData[i].contest_id,
        })
      }

      _optionsData.push({
        label: contestData[i].title?.en,
        options: rounds,
      })
    }
    setOptionsData(_optionsData)
  }
  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 105,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const docFormatter = (cell, row, rowIndex) => {
    if (cell == "" || cell == null || cell == "undefined") {
      return cell
    } else {
      return cell.toLocaleString()
    }
  }

  const columns = [
    {
      dataField: "contest_round_title.en",
      text: "Contest Round Title",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return cell + " / " + row?.total_round
      },
    },
    {
      dataField: "contest_title.en",
      text: "Contest Title",
      sort: true,
    },
    {
      dataField: "user_nickname",
      text: "Username",
      sort: true,
      formatter: (cell, row, rowIndex) => <strong>{cell}</strong>,
    },
    {
      dataField: "team_name.en",
      text: "Participant Team / Player",
      sort: true,
      csvFormatter: (cell, row, rowIndex) => {
        if (
          row?.team_name?.en == "undefined" ||
          row?.team_name?.en == "" ||
          row?.team_name?.en == null
        ) {
          return "Not Allocated"
        } else {
          return row?.team_name?.en
        }
      },
      formatter: (cell, row, rowIndex) => {
        if (
          row?.team_name?.en == "undefined" ||
          row?.team_name?.en == "" ||
          row?.team_name?.en == null
        ) {
          return "Not Allocated"
        } else {
          return row?.team_name?.en
        }
      },
    },
    {
      dataField: "win_loss",
      text: "Win / Lose ",
      sort: true,
    },
    {
      dataField: "game_status",
      text: "Result",
      sort: true,
    },
    {
      dataField: "entries_allotted",
      text: "Entries Alloted",
      sort: true,
      sortFunc: (a: any, b: any, order: any, dataField: any) => {
        if (order === "asc") {
          return b - a
        }
        return a - b
      },
      formatter: docFormatter,
    },
    {
      dataField: "entries_carried_forward",
      text: "Entries Carried forward",
      sort: true,
      sortFunc: (a: any, b: any, order: any, dataField: any) => {
        if (order === "asc") {
          return b - a
        }
        return a - b
      },
      formatter: docFormatter,
    },
    {
      dataField: "entries_lost",
      text: "Entries Lost",
      sort: true,
      sortFunc: (a: any, b: any, order: any, dataField: any) => {
        if (order === "asc") {
          return b - a
        }
        return a - b
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        if (row?.game_status == "Pending") {
          return ""
        }
        if (cell == "" || cell == null || cell == "undefined") {
          return cell
        } else {
          return cell.toLocaleString()
        }
      },
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const Group = (props) => (
    <div>
      <components.Group {...props} />
    </div>
  )

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={listData}
        columns={columns}
        search
        exportCSV
      >
        {(props: any) => (
          <>
            <Row>
              <div className="col-md-3">
                <div className="col-md-12">
                  <p className="white_color mt-4 mb-0">Users</p>
                </div>
                <Select
                  name="user"
                  placeholder="Select Users"
                  id="user"
                  className="custom-select-multi"
                  options={userData}
                  value={selectedUserData}
                  onChange={(e: any) => {
                    setSelectedUserData(e)
                    setSelectedContestData([])
                    setSelectedRoundData([])
                    setShowTable(false)
                  }}
                />
              </div>

              {selectedUserData && selectedUserData?.value ? (
                <Col>
                  <div
                    className={selectedContestData[0]?.value ? "" : "col-md-6"}
                  >
                    <div className="col-md-12">
                      <p className="white_color mt-4 mb-0">Contest</p>
                    </div>
                    <Select
                      name="contest"
                      placeholder="Select Contest"
                      id="contest"
                      isMulti
                      className="custom-select-multi"
                      value={selectedContestData}
                      options={contestData}
                      onChange={(e: any) => {
                        setSelectedContestData(e)
                        setSelectedRoundData([])
                        setShowTable(false)
                      }}
                    />
                  </div>
                </Col>
              ) : null}
              {selectedContestData && selectedContestData[0]?.value ? (
                <div className="col-md-3">
                  <div className="col-md-12">
                    <p className="white_color mt-4 mb-0">Round</p>
                  </div>
                  <Select
                    name="round"
                    placeholder="Select Round"
                    id="round"
                    isMulti
                    value={selectedRoundData}
                    className="custom-select-multi"
                    options={roundData}
                    onChange={(e) => {
                      setSelectedRoundData(e)
                      setShowTable(false)
                    }}
                  />
                </div>
              ) : null}

              <div className="col-md-3">
                {selectedContestData &&
                selectedContestData[0]?.value &&
                selectedRoundData &&
                selectedRoundData[0]?.value &&
                selectedUserData &&
                selectedUserData?.value ? (
                  <div className="pt-4">
                    <button
                      className="btn btn-primary mt-4"
                      onClick={() => {
                        handleSubmit()
                      }}
                    >
                      Show Data
                    </button>

                    {showTable ? (
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn-success fw-bold ms-2 mt-4"
                      >
                        Export CSV
                      </ExportCSVButton>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Row>
            <Row>
              <div className="pt-4">
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setSelectedContestData([])
                    setSelectedUserData([])
                    setRoundData([])
                    setSelectedRoundData([])
                  }}
                >
                  Clear
                </button>
              </div>
            </Row>
            <Row className="mt-5">
              {selectedContestData &&
              selectedContestData[0]?.value &&
              selectedRoundData &&
              selectedRoundData[0]?.value &&
              showTable ? (
                <Row className="mt-5">
                  <Row>
                    <div>
                      <SearchBar {...props.searchProps} className="mb-3" />
                      <ClearSearchButton
                        {...props.searchProps}
                        className="btn-primary fw-bold ms-2"
                      />
                    </div>
                    <BootstrapTable
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        ...options,
                        // totalSize: 100
                      })}
                      {...props.baseProps}
                    />
                  </Row>
                </Row>
              ) : null}
            </Row>
          </>
        )}
      </ToolkitProvider>
    </>
  )
}
export default UserReportList
