import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { ContestRoundGameDetailInterface } from "./models/ContestRoundGameDetailInterface";
import {
  createContestRoundGame,
  deleteContestRoundGame,
  getContestRoundGameDetail,
  getContestRoundGameDetailByRoundId,
  getContestRoundGameList,
  updateContestRoundGame,
} from "./ContestRoundGameAPI";

export interface ContestRoundGameStateInterface {
  contestroundgames: any[];
  favourites: ContestRoundGameDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: ContestRoundGameDetailInterface;
}

const initialState: ContestRoundGameStateInterface = {
  contestroundgames: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    contest_round_game_id: "",
    contest_round_id: "",
    teams: "",
    winner_team_id: "",
    status: "",
    progress_status: "",
    game_time: "",
    place_name: "",
    open_till: "",
  },
};

export const getContestRoundGameListAsync = createAsyncThunk(
  "contestroundgame/list",
  async (data: any) => {
    const response = await getContestRoundGameList(data);
    return response.data;
  }
);
export const getContestRoundGameDetailAsync = createAsyncThunk(
  "contestroundgame/detail",
  async (index: string) => {
    const response = await getContestRoundGameDetail(index);
    return response.data.data;
  }
);

export const getContestRoundGameDetailByRoundIdAsync = createAsyncThunk(
  "contest-round-game/list",
  async (index: string) => {
    const response = await getContestRoundGameDetailByRoundId(index)
    return response.data
  }
)

export const createContestRoundGameAsync = createAsyncThunk(
  "contestroundgame/create",
  async (data: ContestRoundGameDetailInterface) => {
    const response = await createContestRoundGame(data);
    return response.data;
  }
);

export const deleteContestRoundGameAsync = createAsyncThunk(
  "contestroundgame/delete",
  async (index: string) => {
    const response = await deleteContestRoundGame(index);
    return response.data;
  }
);

export const updateContestRoundGameAsync = createAsyncThunk(
  "contestroundgame/update",
  async (data: { index: string; data: ContestRoundGameDetailInterface }) => {
    const response = await updateContestRoundGame(data.index, data.data);
    return response.data;
  }
);

export const contestroundgameSlice = createSlice({
  name: "contestroundgame",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getContestRoundGameListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContestRoundGameListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.contestroundgames = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getContestRoundGameListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getContestRoundGameDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContestRoundGameDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getContestRoundGameDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
       // GET COntest Round DETAIL
       .addCase(getContestRoundGameDetailByRoundIdAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getContestRoundGameDetailByRoundIdAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.contestroundgames = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getContestRoundGameDetailByRoundIdAsync.rejected, (state) => {
        state.status = "failed"
      })
      // CREATE
      .addCase(createContestRoundGameAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createContestRoundGameAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createContestRoundGameAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateContestRoundGameAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateContestRoundGameAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateContestRoundGameAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteContestRoundGameAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteContestRoundGameAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteContestRoundGameAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = contestroundgameSlice.actions;
export const ContestRoundGameState = (state: RootState) => state.contestroundgame;
export default contestroundgameSlice.reducer;
