import { Button, Container, Row, Table } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getContestDetailAsync, ContestState } from "../ContestSlice"
import { Field, FieldArray, Formik } from "formik"
import { ContestDetailInterface } from "../models/ContestDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect, useState } from "react"
import { createContest, updateContest } from "../ContestAPI"
import { appState } from "../../../stores/appSlice"
import { useAlert } from "react-alert"
import * as yup from "yup"
import { toast } from "react-toastify"

/**
 * Contesting Favourites list page
 */
const ContestCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(ContestState)
  const params = useParams()
  const alert = useAlert()
  const navigator = useNavigate()
  const { languages } = useAppSelector(appState)

  let todayDate = new Date()
  todayDate.setHours(todayDate.getHours() + 1)
  let tomorrowDate = new Date()
  tomorrowDate.setDate(todayDate.getDate() + 1)
  const [defaultValus, setDefaultValue] = useState({
    mulitLang: [],
    status: "",
    number_of_round: 0,
    complexity: "",
    banner: "",
    price_pool: "",
    sport_id: "",
    reg_start_date_time: todayDate.toISOString(),
    reg_close_date_time: tomorrowDate.toISOString(),
    is_team: "",
    rules_file: "",
    sponsorship_id: "",
  })

  useEffect(() => {
    if (params.contest_id) {
      dispatch(getContestDetailAsync(params.contest_id))
    }
  }, [params, dispatch])

  useEffect(() => {
    let multiLang = []
    languages.forEach((element) => {
      multiLang.push({
        code: element.code,
        label: element.name,
        title: "",
        description: "",
        check_info: "",
      })
    })
    setDefaultValue({
      ...defaultValus,
      mulitLang: multiLang,
    })
  }, [])

  useEffect(() => {
    if (detail.contest_id == params.contest_id) {
      let multiLang = []
      languages.forEach((element) => {
        multiLang.push({
          code: element.code,
          label: element.name,
          title: detail.title[element.code],
          check_info: detail.check_info[element.code],
          description: detail.description[element.code],
        })
      })
      setDefaultValue({
        banner: detail.banner,
        status: detail.status,
        complexity: detail.complexity,
        number_of_round: detail.number_of_round,
        sport_id: detail.sport_id,
        reg_start_date_time: detail.reg_start_date_time,
        price_pool: detail.price_pool,
        reg_close_date_time: detail.reg_close_date_time,
        is_team: detail.is_team,
        rules_file: detail.rules_file,
        sponsorship_id: detail.sponsorship_id,
        mulitLang: multiLang,
      })
    }
  }, [detail])

  const handleSubmit = async (values: any, formMethod: any) => {
    values.sponsorship_id =
      values.sponsorship_id == "" ? null : values.sponsorship_id
    if (params.contest_id) {
      const data: any = {
        contest_id: params.contest_id,
        sport_id: values.sport_id,
        title: {},
        description: {},
        check_info: {},
        status: values.status,
        banner: values.banner,
        complexity: values.complexity,
        number_of_round: values.number_of_round,
        reg_start_date_time: values.reg_start_date_time,
        price_pool: values.price_pool,
        reg_close_date_time: values.reg_close_date_time,
        is_team: values.is_team,
        rules_file: values.rules_file,
        sponsorship_id:
          values.sponsorship_id == "" ? null : values.sponsorship_id,
      }
      values.mulitLang.forEach((element) => {
        data.title[element.code] = element.title
        data.description[element.code] = element.description
        data.check_info[element.code] = element.check_info
      })

      const res: any = await updateContest(params.contest_id, data)
      if (res.error && res?.statusCode === 400) {
        if (res?.error && res?.error.length > 0) {
          res?.error.forEach((element) => {
            alert.error(element.msg)
          })
        }
      } else if (res?.statusCode === 400) {
        alert.error(res.message)
      } else if (res.data?.statusCode === 201 || res.data?.statusCode === 200) {
        alert.success("Contest updated successfully.")
        navigator("/contest")
      }
    } else {
      const data: any = {
        sport_id: values.sport_id,
        title: {},
        description: {},
        check_info: {},
        status: values.status,
        banner: values.banner,
        complexity: values.complexity,
        number_of_round: values.number_of_round,
        reg_start_date_time: values.reg_start_date_time,
        price_pool: values.price_pool,
        reg_close_date_time: values.reg_close_date_time,
        is_team: values.is_team,
        rules_file: values.rules_file,
        sponsorship_id: values.sponsorship_id,
      }
      values.mulitLang.forEach((element) => {
        data.title[element.code] = element.title
        data.description[element.code] = element.description
        data.check_info[element.code] = element.check_info
      })

      const res: any = await createContest(data)
      if (res.error && res?.statusCode === 400) {
        if (res?.error && res?.error.length > 0) {
          res?.error.forEach((element) => {
            alert.error(element.msg)
          })
        }
      } else if (res?.statusCode === 400) {
        alert.error(res.message)
      } else if (res.data?.statusCode === 201 || res.data?.statusCode === 200) {
        alert.success("Contest updated successfully.")
        navigator("/contest")
      }
    }
  }

  const dateSchema = yup.object().shape({
    // reg_start_date_time: yup.date().required("required"),
    // reg_close_date_time: yup.date().min(yup.ref('reg_start_date_time'),"Close date can't be before start date").required("required")
  })

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Contest</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/contest">Contests</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.contest_id ? "Edit " : "Add "}Contest</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.contest_id ? "Edit " : "Add "}Contest</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={defaultValus}
                    validate={(values) => {
                      const errors: any = {}
                      if (!values.banner) {
                        errors.banner = "Required"
                      }
                      if (!values.sport_id) {
                        errors.sport_id = "Required"
                      }
                      if (!values.status) {
                        errors.status = "Required"
                      }
                      if (!values.complexity) {
                        errors.complexity = "Required"
                      }
                      if (!values.number_of_round) {
                        errors.number_of_round = "Required"
                      }
                      if (!values.reg_start_date_time) {
                        errors.reg_start_date_time = "Required"
                      }
                      if (!values.reg_close_date_time) {
                        errors.reg_close_date_time = "Required"
                      }
                      if (values.price_pool == "") {
                        errors.price_pool = "Required"
                      }
                      if (values.is_team != "0" && values.is_team != "1") {
                        errors.is_team = "Required"
                      }
                      if (
                        values.reg_start_date_time &&
                        values.reg_close_date_time
                      ) {
                        let startDate = new Date(
                          values.reg_start_date_time
                        ).getTime()

                        let closeDate = new Date(
                          values.reg_close_date_time
                        ).getTime()

                        if (startDate >= closeDate) {
                          errors.reg_close_date_time =
                            "Must be greater than registration start date"
                          errors.reg_start_date_time =
                            "Must be less than registration close date"
                        }
                      }
                      if (values.reg_start_date_time) {
                        let now = new Date().getTime()
                        let startDate = new Date(
                          values.reg_start_date_time
                        ).getTime()
                        if (startDate < now) {
                          errors.reg_start_date_time =
                            "Must be greater than or equals to current time"
                        }
                      }

                      for (var i = 0; i < values.mulitLang.length; i++) {
                        if (
                          !values.mulitLang[i].title &&
                          values.mulitLang[i].code == "en"
                        ) {
                          if (!errors.mulitLang) {
                            errors.mulitLang = []
                          }
                          errors[`mulitLang.${i}.title`] = "Required"
                        }
                        if (
                          !values.mulitLang[i].description &&
                          values.mulitLang[i].code == "en"
                        ) {
                          if (!errors.mulitLang) {
                            errors.mulitLang = []
                          }
                          errors[`mulitLang.${i}.description`] = "Required"
                        }
                      }
                      return errors
                    }}
                    enableReinitialize={true}
                    validationSchema={dateSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <FieldArray
                              name="mulitLang"
                              render={() => (
                                <div>
                                  {values.mulitLang &&
                                  values.mulitLang.length > 0
                                    ? values.mulitLang.map(
                                        (mulitLang, index) => (
                                          <div key={index}>
                                            <h4 className="input-label">
                                              {mulitLang.label}
                                            </h4>

                                            <div className="row">
                                              <div
                                                key={"title_" + index}
                                                className={"col-md-6"}
                                              >
                                                <div className="form-group mb-4">
                                                  <div className="input-label">
                                                    Title
                                                  </div>
                                                  <Field
                                                    className="form-control"
                                                    name={`mulitLang.${index}.title`}
                                                    maxLength="50"
                                                  />
                                                  {touched.mulitLang &&
                                                  touched.mulitLang[index] &&
                                                  touched.mulitLang[index]
                                                    .title &&
                                                  typeof errors[
                                                    `mulitLang.${index}.title`
                                                  ] === "string" ? (
                                                    <div className="error">
                                                      {
                                                        errors[
                                                          `mulitLang.${index}.title`
                                                        ]
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div
                                                key={"desc_" + index}
                                                className={"col-md-6"}
                                              >
                                                <div className="form-group mb-4">
                                                  <div className="input-label">
                                                    Description
                                                  </div>
                                                  <Field
                                                    as="textarea"
                                                    className="form-control"
                                                    maxLength="300"
                                                    name={`mulitLang.${index}.description`}
                                                  />
                                                  {touched.mulitLang &&
                                                  touched.mulitLang[index] &&
                                                  touched.mulitLang[index]
                                                    .description &&
                                                  typeof errors[
                                                    `mulitLang.${index}.description`
                                                  ] === "string" ? (
                                                    <div className="error">
                                                      {
                                                        errors[
                                                          `mulitLang.${index}.description`
                                                        ]
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div
                                                key={"check_info_" + index}
                                                className={"col-md-6"}
                                              >
                                                <div className="form-group mb-4">
                                                  <div className="input-label">
                                                    Prize Text
                                                  </div>
                                                  <Field
                                                    as="textarea"
                                                    className="form-control"
                                                    maxLength="300"
                                                    name={`mulitLang.${index}.check_info`}
                                                  />
                                                  {/* {touched.mulitLang &&
                                                touched.mulitLang[index] &&
                                                touched.mulitLang[index]
                                                  .check_info &&
                                                typeof errors[
                                                  `mulitLang.${index}.check_info`
                                                ] === "string" ? (
                                                  <div className="error">
                                                    {
                                                      errors[
                                                        `mulitLang.${index}.check_info`
                                                      ]
                                                    }
                                                  </div>
                                                ) : null} */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )
                                    : null}
                                </div>
                              )}
                            />
                            <div className={"col-md-6"}>
                              <FormField
                                name={"sport_id"}
                                fieldDetails={ModuleConfig.formFields.sport_id}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            <div className={"col-md-6"}>
                              <FormField
                                name={"banner"}
                                fieldDetails={ModuleConfig.formFields.banner}
                                accept={"image/png, image/jpeg"}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            <div className={"col-md-6"}>
                              <FormField
                                name={"reg_start_date_time"}
                                fieldDetails={
                                  ModuleConfig.formFields.reg_start_date_time
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            <div className={"col-md-6"}>
                              <FormField
                                name={"reg_close_date_time"}
                                fieldDetails={
                                  ModuleConfig.formFields.reg_close_date_time
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            <div className={"col-md-6"}>
                              <FormField
                                name={"number_of_round"}
                                fieldDetails={
                                  ModuleConfig.formFields.number_of_round
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                maxLength={"3"}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            <div className={"col-md-6"}>
                              <FormField
                                name={"price_pool"}
                                fieldDetails={
                                  ModuleConfig.formFields.price_pool
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                maxLength={"5"}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            <div className={"col-md-6"}>
                              <FormField
                                name={"complexity"}
                                fieldDetails={
                                  ModuleConfig.formFields.complexity
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            <div className={"col-md-6"}>
                              <FormField
                                name={"status"}
                                fieldDetails={
                                  ModuleConfig.formFields.status_create
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>

                            <div className={"col-md-6"}>
                              <FormField
                                name={"is_team"}
                                fieldDetails={ModuleConfig.formFields.is_team}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                            <div className={"col-md-6"}>
                              <FormField
                                name={"rules_file"}
                                fieldDetails={
                                  ModuleConfig.formFields.rules_file
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                            <div className={"col-md-6"}>
                              <FormField
                                name={"sponsorship_id"}
                                fieldDetails={
                                  ModuleConfig.formFields.sponsorship_id
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <hr />
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ContestCreate
