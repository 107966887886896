import { Route } from "react-router-dom";
import SportCreate from "./pages/SportCreate";
import SportList from "./pages/SportList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "sport"; 
const SportRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<SportList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<SportCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:sport_id`} element={<SportCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default SportRoute;