import { Route } from "react-router-dom";
import CMSCreate from "./pages/CMSCreate";
import CMSList from "./pages/CMSList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "article"; 
const CMSRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<CMSList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<CMSCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:cms_id`} element={<CMSCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default CMSRoute;