import { Route } from "react-router-dom";
import ContestRoundGameCreate from "./pages/ContestRoundGameCreate";
import ContestRoundGameList from "./pages/ContestRoundGameList";
import ContestRoundGameResult from "./pages/ContestRoundGameResult"

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "contest-round-game"; 
const ContestRoundGameRoute = [
    <Route path={`${MODULE_ROUTE}/:contest_id/:contest_round_id`} element={<ContestRoundGameList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create/:contest_id/:contest_round_id`} element={<ContestRoundGameCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:contest_id/:contest_round_id/:game_id`} element={<ContestRoundGameCreate />} key={`${MODULE_ROUTE}_edit`} />,
    <Route path={`${MODULE_ROUTE}/result/:contest_id/:contest_round_id`} element={<ContestRoundGameResult />} key={`${MODULE_ROUTE}_result`} />,
  ];

export default ContestRoundGameRoute;