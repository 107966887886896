import { BrowserRouter, useLocation } from "react-router-dom"
import AppRoute from "./routes/index"
import { Container, ThemeProvider } from "react-bootstrap"
import Header from "./modules/common/Header"
import Footer from "./modules/common/Footer"
import SideBar from "./modules/common/SideBar"
import "./assets/scss/index.scss"
import { useAppSelector } from "./stores/hooks"
// import "bootstrap/dist/css/bootstrap.min.css";
import { appState } from "./stores/appSlice"
import { useEffect, useState } from "react"
import { getProfile } from "./stores/appAPI"
import AxiosInstance from "./utils/AxiosInstance"

/**
 * Create Main App and configure
 * Theme
 * Router
 * Header
 * Main Body
 * Footer
 */
function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const loader = useAppSelector((state) => state.app.loader)

  useEffect(() => {
    checkforLogin()
  }, [])

  const checkforLogin = async () => {
    if (localStorage.getItem("token")) {
      try {
        const res = await AxiosInstance.get("users/get-profile")
        setLoading(false)
      } catch (e) {}
    } else {
      if (window.location.href.indexOf("signin") >= 10) {
        setLoading(false)
      } else {
        window.location.href = "/signin"
      }
    }
  }

  if (loading) {
    return (
      <div className="app-loader">
        <div className="loader"></div>
      </div>
    )
  }

  return (
    <div className="">
      {loader ? (
        <div className="app-loader">
          <div className="loader"></div>
        </div>
      ) : null}
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <BrowserRouter>
          <SideBar />
          <Header />
          <AppRoute />
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

export default App
