import { Link, useNavigate } from "react-router-dom"
import { Formik } from "formik"
import { useState } from "react"
import AxiosInstance from "../utils/AxiosInstance"
import { useAlert } from "react-alert"

const ChangePassword = () => {
  const [currentpasswordType, setCurrentpasswordType] = useState("password")
  const [passwordType, setPasswordType] = useState("password")
  const [confirmPasswordType, setConfirmPasswordType] = useState("password")
  const alert = useAlert()
  const navigate = useNavigate()

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }
  const toggleCurrentPassword = () => {
    if (currentpasswordType === "password") {
      setCurrentpasswordType("text")
      return
    }
    setCurrentpasswordType("password")
  }

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text")
      return
    }
    setConfirmPasswordType("password")
  }

  const handleSubmit = async (values, { setErrors }) => {
    const data = {
      old_password: values.currentPassword,
      new_password: values.newPassword,
      confirm_new_password: values.confirmPassword,
    }
    const res: any = await AxiosInstance.post("users/change-password", data)
    if (res?.data?.statusCode === 200) {
      alert.success(res?.data?.message)
      localStorage.clear();
      navigate("/signin");
      window.location.reload();
    } else if (res?.statusCode === 400) {
      if (res?.error && res?.error.length > 0) {
        res?.error.forEach((element) => {
          alert.error(element.msg)
        })
      } else if (res?.message || (res?.error && res?.error.length < 0)) {
        alert.error(res?.message)
      }
    }
  }
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Change Password</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Change Password</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <Formik
                        initialValues={{
                          currentPassword: "",
                          newPassword: "",
                          confirmPassword: "",
                        }}
                        validate={(values) => {
                          const errors: any = {}
                          if (!values["currentPassword"]) {
                            errors["currentPassword"] =
                              "Current Password Required"
                          }
                          if (!values["newPassword"]) {
                            errors["newPassword"] = "New Password Required"
                          } else if (values.newPassword.length < 8) {
                            errors["newPassword"] =
                              "Password must be 8 character long."
                          }
                          if (!values["confirmPassword"]) {
                            errors["confirmPassword"] =
                              "Confirm Password Required"
                          } else if (
                            values["newPassword"] != values["confirmPassword"]
                          ) {
                            errors["confirmPassword"] = "Password not match"
                          }

                          return errors
                        }}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group pb-1 mb-4">
                              <label
                                className="control-label col-xs-3 mb-1 pb-1"
                                htmlFor="password"
                              >
                                Current Password
                              </label>
                              <div className="col-xs-9">
                                <div className="input-group">
                                  <input
                                    type={currentpasswordType}
                                    className={
                                      errors.currentPassword &&
                                      touched.currentPassword
                                        ? "form-control cform-control error-field"
                                        : "form-control cform-control border-right-0"
                                    }
                                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                    name="currentPassword"
                                    value={values.currentPassword}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />

                                  <span className="input-group-text white-icon">
                                    <span
                                      data-toggle="#password-field"
                                      className="field-icon toggle-password"
                                    >
                                      <i
                                        className={
                                          currentpasswordType === "password"
                                            ? "pointer fas fa-eye-slash"
                                            : "pointer fas fa-eye"
                                        }
                                        onClick={toggleCurrentPassword}
                                      />
                                    </span>
                                  </span>
                                </div>

                                <div className="error-message">
                                  {errors.currentPassword &&
                                    touched.currentPassword &&
                                    errors.currentPassword}
                                </div>
                              </div>
                            </div>

                            <div className="form-group pb-1 mb-4">
                              <label
                                className="control-label col-xs-3 mb-1 pb-1"
                                htmlFor="password"
                              >
                                New Password
                              </label>
                              <div className="col-xs-9">
                                <div className="input-group">
                                  <input
                                    type={passwordType}
                                    className={
                                      errors.newPassword && touched.newPassword
                                        ? "form-control cform-control error-field"
                                        : "form-control cform-control border-right-0"
                                    }
                                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                    name="newPassword"
                                    value={values.newPassword}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />

                                  <span className="input-group-text white-icon">
                                    <span
                                      data-toggle="#password-field"
                                      className="field-icon toggle-password"
                                    >
                                      <i
                                        className={
                                          passwordType === "password"
                                            ? "pointer fas fa-eye-slash"
                                            : "pointer fas fa-eye"
                                        }
                                        onClick={togglePassword}
                                      />
                                    </span>
                                  </span>
                                </div>

                                <div className="error-message">
                                  {errors.newPassword &&
                                    touched.newPassword &&
                                    errors.newPassword}
                                </div>
                              </div>
                            </div>
                            <div className="form-group pb-1 mb-4">
                              <label
                                className="control-label col-xs-3 mb-1 pb-1"
                                htmlFor="password_new"
                              >
                                Confirm New Password
                              </label>

                              <div className="col-xs-9">
                                <div className="input-group">
                                  <input
                                    type={confirmPasswordType}
                                    className={
                                      errors.confirmPassword &&
                                      touched.confirmPassword
                                        ? "form-control cform-control error-field"
                                        : "form-control cform-control border-right-0"
                                    }
                                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />

                                  <span className="input-group-text white-icon">
                                    <span
                                      data-toggle="#password-field"
                                      className="field-icon toggle-password"
                                    >
                                      <i
                                        className={
                                          confirmPasswordType === "password"
                                            ? "pointer fas fa-eye-slash"
                                            : "pointer fas fa-eye"
                                        }
                                        onClick={toggleConfirmPassword}
                                      />
                                    </span>
                                  </span>
                                </div>
                                <div className="error-message">
                                  {errors.confirmPassword &&
                                    touched.confirmPassword &&
                                    errors.confirmPassword}
                                </div>
                              </div>
                            </div>
                            <div className="form-group checkbox_group pb-1 mb-4">
                              <div className="row">
                                <div className="col-sm-12">
                                  <input
                                    type="submit"
                                    className="btn btn-primary btn_submit"
                                    value="Change my password"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ChangePassword
