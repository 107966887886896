import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../stores"
import { UserReportDetailInterface } from "./models/UserReportDetailInterface"
import {
  createUserReport,
  deleteUserReport,
  getAllUsers,
  getContests,
  getUserReportDetail,
  getUserReportList,
  updateUserReport,
} from "./UserReportAPI"

export interface UserReportStateInterface {
  userreports: UserReportDetailInterface[]
  favourites: UserReportDetailInterface[]
  totalRecord: number
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  detail: UserReportDetailInterface
}

const initialState: UserReportStateInterface = {
  userreports: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
  },
}

export const getUserReportListAsync = createAsyncThunk(
  "userreport/list",
  async (data: any) => {
    const response = await getUserReportList(data)
    return response.data
  }
)
export const getUserReportDetailAsync = createAsyncThunk(
  "userreport/detail",
  async (index: string) => {
    const response = await getUserReportDetail(index)
    return response.data
  }
)

export const createUserReportAsync = createAsyncThunk(
  "userreport/create",
  async (data: UserReportDetailInterface) => {
    const response = await createUserReport(data)
    return response.data
  }
)

export const deleteUserReportAsync = createAsyncThunk(
  "userreport/delete",
  async (index: string) => {
    const response = await deleteUserReport(index)
    return response.data
  }
)

export const updateUserReportAsync = createAsyncThunk(
  "userreport/update",
  async (data: { index: string; data: UserReportDetailInterface }) => {
    const response = await updateUserReport(data.index, data.data)
    return response.data
  }
)

export const getAllUsersAsync = createAsyncThunk(
  "userreport/usersList",
  async () => {
    const response = await getAllUsers()
    return response.data
  }
)


export const getContestsAsync = createAsyncThunk(
  "userreport/contests",
  async (data : any) => {
    const response = await getContests(data)
    return response.data
  }
)

export const userreportSlice = createSlice({
  name: "userreport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getUserReportListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getUserReportListAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.userreports = action.payload.data
        state.totalRecord = action.payload.total
      })
      .addCase(getUserReportListAsync.rejected, (state) => {
        state.status = "failed"
      })
      // GET DETAIL
      .addCase(getUserReportDetailAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getUserReportDetailAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.detail = action.payload
      })
      .addCase(getUserReportDetailAsync.rejected, (state) => {
        state.status = "failed"
      })
      // CREATE
      .addCase(createUserReportAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(createUserReportAsync.fulfilled, (state, action) => {
        state.status = "created"
        state.detail = action.payload
      })
      .addCase(createUserReportAsync.rejected, (state) => {
        state.status = "failed"
      })
      // UPDATE
      .addCase(updateUserReportAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updateUserReportAsync.fulfilled, (state, action) => {
        state.status = "updated"
        state.detail = action.payload
      })
      .addCase(updateUserReportAsync.rejected, (state) => {
        state.status = "failed"
      })
      // DELETE
      .addCase(deleteUserReportAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteUserReportAsync.fulfilled, (state, action) => {
        state.status = "deleted"
        state.detail = action.payload
      })
      .addCase(deleteUserReportAsync.rejected, (state) => {
        state.status = "failed"
      })
      // Get Users List
      .addCase(getAllUsersAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.userreports = action.payload.data
        state.totalRecord = action.payload.total
      })
      .addCase(getAllUsersAsync.rejected, (state) => {
        state.status = "failed"
      })
      // Get Contests List
      .addCase(getContestsAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getContestsAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.userreports = action.payload.data
        state.totalRecord = action.payload.total
      })
      .addCase(getContestsAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const {} = userreportSlice.actions
export const UserReportState = (state: RootState) => state.userreport
export default userreportSlice.reducer
