import AxiosInstance from "../../utils/AxiosInstance";
import { UserReportDetailInterface } from "./models/UserReportDetailInterface";

const API_ROUTE = "userreports";
export const getUserReportList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`);
};

export const getUserReportDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/${index}`);
};

export const createUserReport = async (data: UserReportDetailInterface) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData);
};

export const deleteUserReport = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`);
};
export const updateUserReport = async (index: string, data: UserReportDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, data);
};

export const getAllUsers = async () => {
  return await AxiosInstance.get(`users/report-dropdown`)
}

export const getContests = async (data:any) => {
  return await AxiosInstance.post(`/contest/contest-with-user-id`,data)
}

export const getRounds = async (data:any) => {
  return await AxiosInstance.post(`/contest-round/report-dropdown`,data)
}

export const getReportData = async (data:any) => {
  return await AxiosInstance.post(`/reports/all-contest-result-report-with-user`,data)
}