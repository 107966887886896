import { useState } from "react"
import { useAppDispatch } from "../../../stores/hooks"
import { getContestRoundGameDetailByRoundIdAsync } from "../../contestroundgame/ContestRoundGameSlice"
import Config from "../../../utils/config"
import moment from "moment"
import { useAlert } from "react-alert"
import { winnerDeclare } from "../ContestAPI"
import {
  getContestRoundDetailAsync,
  getContestRoundDetailByContestIdAsync,
} from "../../contestround/ContestRoundSlice"
import { useParams } from "react-router-dom"
import { Button, Modal } from "react-bootstrap"
// import { getContestRoundDetailByContestIdAsync } from "../../contestround/ContestRoundSlice"

const ContestRoundGameIsTeam = (props: any) => {
  const params = useParams()
  const dispatch = useAppDispatch()

  const [result, setResult] = useState<any>({})
  const [resultStatus, setResultStatus] = useState<any>({})
  const alert = useAlert()
  const [showScorrerModal, setShowScorrerModal] = useState(false)
  const [winnerDetails, setWinnerDetails] = useState<any>({})

  const getGameList = () => {
    dispatch(getContestRoundGameDetailByRoundIdAsync(props.contest_round_id))
    // dispatch(getContestRoundDetailByContestIdAsync(props?.contest_id))
  }

  const callAPI = async (winner : any) => {
    const res: any = await winnerDeclare(winner)
    if (res.error && res.statusCode === 400) {
      if (res?.error && res?.error.length > 0) {
        res?.error.forEach((element : any) => {
          alert.error(element.msg)
        })
      }
    } else if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
      alert.success("Success.")
      getPageData()
      getGameList()
    }
  }

  const handleSubmit = async (roundId: any) => {
    let isConfirm = window.confirm(
      "Are you sure you wish to update this round?"
    )
    if (isConfirm) {
      let winner = {
        contest_round_game_id: roundId,
        winner_team_ids: result[roundId] ? [result[roundId]] : [],
        status: resultStatus[roundId] ? resultStatus[roundId] : "Tie",
        progress_status: "Close",
      }
      setWinnerDetails(winner)
      if (winner.status != "Result") {
        setShowScorrerModal(true)
      } else {
        callAPI(winner)
      }
    }
  }

  const getPageData = () => {
    if (params.contest_id) {
      dispatch(getContestRoundDetailByContestIdAsync(params.contest_id))
      dispatch(getContestRoundDetailAsync(params.contest_id))
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {props?.contestroundgames && props?.contestroundgames?.length > 0
              ? props?.contestroundgames.map((game, index) => {
                  return (
                    <>
                      <div className="match-rounde-list" key={index}>
                        <div className="row align-items-center">
                          <div className="col-lg-5">
                            <div className="row align-items-center mobilebot2">
                              <div className="col-md-5 team-inf">
                                <img
                                  src={`${Config.cdnUrl}${game?.ContestRoundGameTeams[0]?.Team?.logo}`}
                                  alt=""
                                  className="img-fluid m-auto max-125"
                                />
                                <div className="text-center text-black font-w600 mt-2">
                                  {
                                    game?.ContestRoundGameTeams[0]?.Team?.name
                                      .en
                                  }
                                </div>

                                {game?.progress_status === "Close" ? (
                                  <>
                                    {game?.ContestRoundGameTeams[0]
                                      ?.is_winner || game.credit_forward ? (
                                      <div className="result-won">Win</div>
                                    ) : (
                                      <div className="result-lose">Lose</div>
                                    )}
                                  </>
                                ) : null}

                                {props?.status === "In progress" ? (
                                  <div className="text-center text-black font-w600 mt-2">
                                    <input
                                      type={"radio"}
                                      onClick={() => {
                                        let _result = { ...result }
                                        _result[game?.contest_round_game_id] =
                                          game?.ContestRoundGameTeams[0]?.team_id
                                        setResult(_result)
                                        const _resultStatus = {
                                          ...resultStatus,
                                        }
                                        _resultStatus[
                                          game?.contest_round_game_id
                                        ] = "Result"
                                        setResultStatus(_resultStatus)
                                      }}
                                      name={game?.contest_round_game_id}
                                      checked={
                                        game?.ContestRoundGameTeams[0]
                                          ?.is_winner === true
                                          ? true
                                          : result[
                                              game?.contest_round_game_id
                                            ] ===
                                            game?.ContestRoundGameTeams[0]
                                              ?.team_id
                                      }
                                      value={
                                        game?.ContestRoundGameTeams[0]?.team_id
                                      }
                                    />
                                    {" Win"}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-md-2 p-0">
                                <div className="text-center minheight97 mt-3">
                                  <span className="blue-vs-text">Vs</span>
                                </div>
                              </div>
                              <div className="col-md-5 team-inf">
                                <img
                                  src={`${Config.cdnUrl}${game?.ContestRoundGameTeams[1]?.Team?.logo}`}
                                  alt=""
                                  className="img-fluid m-auto max-125"
                                />
                                <div className="text-center text-black font-w600 mt-2">
                                  {
                                    game?.ContestRoundGameTeams[1]?.Team?.name
                                      .en
                                  }
                                </div>

                                {game?.progress_status === "Close" ? (
                                  <>
                                    {game?.ContestRoundGameTeams[1]
                                      ?.is_winner || game.credit_forward ? (
                                      <div className="result-won">Win</div>
                                    ) : (
                                      <div className="result-lose">Lose</div>
                                    )}
                                  </>
                                ) : null}
                                {props?.status === "In progress" ? (
                                  <div className="text-center text-black font-w600 mt-2">
                                    <input
                                      type={"radio"}
                                      name={game?.contest_round_game_id}
                                      onClick={() => {
                                        let _result = { ...result }
                                        _result[game?.contest_round_game_id] =
                                          game?.ContestRoundGameTeams[1]?.team_id

                                        const _resultStatus = {
                                          ...resultStatus,
                                        }
                                        _resultStatus[
                                          game?.contest_round_game_id
                                        ] = "Result"
                                        setResultStatus(_resultStatus)
                                        setResult(_result)
                                      }}
                                      checked={
                                        game?.ContestRoundGameTeams[1]
                                          ?.is_winner === true
                                          ? true
                                          : result[
                                              game?.contest_round_game_id
                                            ] ===
                                            game?.ContestRoundGameTeams[1]
                                              ?.team_id
                                      }
                                      value={
                                        game?.ContestRoundGameTeams[1]?.team_id
                                      }
                                    />
                                    {" Win"}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="row align-items-center">
                              <div className="col-md-5">
                                <div className="font15 text-black font-w400">
                                  <div>Open For Allocation Till.</div>
                                  <div className="font-w600">
                                    {moment(game?.open_till).format(
                                      "hh:mm A, MM/DD/YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="pipline font15 text-black font-w400">
                                  <div>Stadium name</div>
                                  <div className="font-w600">
                                    {game?.place_name}
                                  </div>
                                  <div className="mt-2">
                                    <img
                                      src="/assets/images/calander-icon.svg"
                                      alt=""
                                      className="img-fluid me-2"
                                    />{" "}
                                    {moment(game?.game_time).format(
                                      "hh:mm A, MM/DD/YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                              {props?.status !== "In progress" &&
                              game?.progress_status !== "Close" ? (
                                <div className="col-md-2">
                                  <div className="text-end">
                                    <span
                                      className="blueiconbtn"
                                      onClick={() => {
                                        let _game = { ...game }
                                        _game.teams = []
                                        for (
                                          var i = 0;
                                          i <
                                          game.ContestRoundGameTeams?.length;
                                          i++
                                        ) {
                                          _game.teams.push({
                                            logo: game.ContestRoundGameTeams[i]
                                              .Team?.logo,
                                            label:
                                              game.ContestRoundGameTeams[i].Team
                                                ?.name.en,
                                            value:
                                              game.ContestRoundGameTeams[i].Team
                                                ?.team_id,
                                          })
                                        }

                                        props.setGameItem(_game)
                                        props.setShowAddGameModel(true)
                                        window.scrollTo(0, 300)
                                      }}
                                    >
                                      <img
                                        src="/assets/images/edit.svg"
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </span>
                                  </div>
                                </div>
                              ) : props?.status === "In progress" &&
                                game?.progress_status !== "Close" ? (
                                <>
                                  <div className="col-md-2">
                                    <select
                                      onChange={(e) => {
                                        const _resultStatus = {
                                          ...resultStatus,
                                        }
                                        _resultStatus[
                                          game?.contest_round_game_id
                                        ] = e.target.value

                                        if (
                                          e.target.value == "Tie" ||
                                          e.target.value == "No result"
                                        ) {
                                          let _result = { ...result }
                                          _result[game?.contest_round_game_id] =
                                            ""
                                          setResult(_result)
                                        }
                                        setResultStatus(_resultStatus)
                                      }}
                                      className="form-control result_style"
                                    >
                                      <option
                                        selected={
                                          resultStatus[
                                            game?.contest_round_game_id
                                          ] == "Tie"
                                        }
                                        value={"Tie"}
                                      >
                                        Tie
                                      </option>
                                      <option
                                        selected={
                                          resultStatus[
                                            game?.contest_round_game_id
                                          ] == "Result"
                                        }
                                        value={"Result"}
                                      >
                                        Result
                                      </option>
                                      <option
                                        selected={
                                          resultStatus[
                                            game?.contest_round_game_id
                                          ] == "No result"
                                        }
                                        value={"No result"}
                                      >
                                        No result
                                      </option>
                                    </select>
                                    <button
                                      className="btn btn-primary mt-2"
                                      onClick={() => {
                                        handleSubmit(
                                          game?.contest_round_game_id
                                        )
                                      }}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </>
                              ) : props?.status === "In progress" &&
                                game?.progress_status === "Close" ? (
                                <div className="col-md-2">
                                  <div className="text-center text-black font-w600 mt-2">
                                    <b>{game?.status}</b>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })
              : null}
          </div>
        </div>
      </div>

      {showScorrerModal ? (
        <Modal show={true} onHide={() => setShowScorrerModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props?.RoundDetail?.round_sequence == props.number_of_round
              ? "This is the last round, so credits cannot be carried forward. Do you wish to declare this as a win or lose credits?"
              : "Do you want to carry forward credits  to the next round?"}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-primary"
              variant="primary"
              onClick={() => {
                let _winnerDetails = winnerDetails
                _winnerDetails.creditForward = true
                setWinnerDetails(_winnerDetails)
                callAPI(_winnerDetails)
                setShowScorrerModal(false)
              }}
            >
              {props?.RoundDetail?.round_sequence == props.number_of_round ? "Win" : "Yes" }
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={() => {
                let _winnerDetails = winnerDetails
                _winnerDetails.creditForward = false
                setWinnerDetails(_winnerDetails)
                callAPI(_winnerDetails)
                setShowScorrerModal(false)
              }}
            >
              {props?.RoundDetail?.round_sequence == props.number_of_round ? "Lose" : "No" }
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}

export default ContestRoundGameIsTeam
