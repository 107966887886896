import { Button, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { getContestRoundDetailAsync, ContestRoundState } from "../ContestRoundSlice";
import { Field, FieldArray, Formik } from "formik";
import { ContestRoundDetailInterface } from "../models/ContestRoundDetailInterface";
import ModuleConfig from "../config/ModuleConfig";
import FormField from "../../common/FormField";
import { useEffect, useState } from "react";
import { createContestRound, updateContestRound } from "../ContestRoundAPI";
import { appState } from "../../../stores/appSlice"

/**
 * ContestRounding Favourites list page
 */
const ContestRoundCreate = () => {
  const dispatch = useAppDispatch();
  const { status, detail } = useAppSelector(ContestRoundState);
  const params = useParams();
  const navigator = useNavigate();
  const { languages } = useAppSelector(appState)
  const [defaultValus, setDefaultValue] = useState({
    mulitLang: [],
    status: "",
    reg_start_date_time: "",
    reg_close_date_time: "",
  })

  useEffect(() => {
    let multiLang = []
    languages.forEach((element) => {
      multiLang.push({
        code: element.code,
        label: element.name,
        title: "",
      })
    })
    setDefaultValue({
      ...defaultValus,
      mulitLang: multiLang,
    })
  }, [])

  useEffect(() => {
    if (params.contest_round_id) {
      dispatch(getContestRoundDetailAsync(params.contest_round_id)); 
    }
  }, [params.contest_round_id]);

  useEffect(() => {
    if (detail.contest_round_id == params.contest_round_id) {
      let multiLang = []
      languages.forEach((element) => {
        multiLang.push({
          code: element.code,
          label: element.name,
          title: detail.title[element.code],
        })
      })
      setDefaultValue({
        reg_start_date_time: detail.reg_start_date_time,
        reg_close_date_time: detail.reg_close_date_time,
        status: detail.status,
        mulitLang: multiLang,
      })
    }
  }, [detail])



  const handleSubmit = async (values: any, formMethod: any) => {
    if (params.contest_round_id) {
      const data: any = {
        contest_id: params.contest_id,
        contest_round_id: params.contest_round_id,
        title: {},
        status: values.status,
        reg_start_date_time: values.reg_start_date_time,
        reg_close_date_time: values.reg_close_date_time,

      }
      values.mulitLang.forEach((element) => {
        data.title[element.code] = element.title
      })

      const res: any = await updateContestRound(params.contest_round_id, data);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0];
        }
        formMethod.setErrors(errors);
      } else {
        navigator(`/contest-round/${params.contest_id}`);
      }
    } else {
      const data: any = {
        title: {},
        status: values.status,
        reg_start_date_time: values.reg_start_date_time,
        reg_close_date_time: values.reg_close_date_time,
        contest_id: params.contest_id,
      }
      values.mulitLang.forEach((element) => {
        data.title[element.code] = element.title
      })

      const res: any = await createContestRound(data);
      if (res.errors && res.message) {
        const errors: any = {};
        const errorKeys = Object.keys(res.errors);
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0];
        }
        formMethod.setErrors(errors);
      } else {
        navigator(`/contest-round/${params.contest_id}`);
      }
    }
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Contest Round</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/contest-round/${params.contest_id}`}>Contest Round</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.contest_round_id ? "Edit " : "Add "}Contest Round</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.contest_round_id ? "Edit " : "Add "}Contest Round</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={defaultValus}
                    validate={(values) => {
                      const errors: any = {}
                     
                      if (!values.reg_start_date_time) {
                        errors.reg_start_date_time = "Required"
                      }
                      if (!values.reg_close_date_time) {
                        errors.reg_close_date_time = "Required"
                      }
                      if (!values.status) {
                        errors.status = "Required"
                      }
                      for (var i = 0; i < values.mulitLang.length; i++) {
                        if (!values.mulitLang[i].title) {
                          if(!errors.mulitLang){
                            errors.mulitLang = []
                          }
                          errors[`mulitLang.${i}.title`] = "Required"
                        }
                      }
                      return errors
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                        <FieldArray
                              name="mulitLang"
                              render={() => (
                                <div>
                                  {values.mulitLang &&
                                  values.mulitLang.length > 0
                                    ? values.mulitLang.map(
                                        (mulitLang, index) => (
                                          <div key={index}>
                                            <h4 className="input-label">
                                              {mulitLang.label}
                                            </h4>

                                            <div className="row">
                                              <div
                                                key={index}
                                                className={"col-md-6"}
                                              >
                                                <div className="form-group mb-4">
                                                  <div className="input-label">
                                                    Title
                                                  </div>
                                                  <Field
                                                    className="form-control"
                                                    maxLength="50"
                                                    name={`mulitLang.${index}.title`}
                                                  />
                                                  {touched.mulitLang &&
                                                  touched.mulitLang[index] &&
                                                  touched.mulitLang[index]
                                                    .title &&
                                                  typeof errors[
                                                    `mulitLang.${index}.title`
                                                  ] === "string" ? (
                                                    <div className="error">
                                                      {
                                                        errors[
                                                          `mulitLang.${index}.title`
                                                        ]
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )
                                    : null}
                                </div>
                              )}
                            />

                            <div className={"col-md-6"}>
                              <FormField
                                name={"reg_start_date_time"}
                                fieldDetails={ModuleConfig.formFields.reg_start_date_time}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                            <div className={"col-md-6"}>
                              <FormField
                                name={"reg_close_date_time"}
                                fieldDetails={ModuleConfig.formFields.reg_close_date_time}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                            <div className={"col-md-6"}>
                              <FormField
                                name={"status"}
                                fieldDetails={ModuleConfig.formFields.status}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                        </div>
                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContestRoundCreate;
