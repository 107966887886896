import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getPrizeDetailAsync, PrizeState } from "../PrizeSlice"
import { Formik } from "formik"
import { PrizeDetailInterface } from "../models/PrizeDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect } from "react"
import { updatePrize } from "../PrizeAPI"
import { useAlert } from "react-alert"
/**
 * WebHomeing Favourites list page
 */

const PrizeStatusUpdate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(PrizeState)
  const params = useParams()
  const navigator = useNavigate()
  const alert = useAlert()

  useEffect(() => {
    if (params.id) {
      dispatch(getPrizeDetailAsync(params.id))
    }
  }, [params.id])

  const handleSubmit = async (
    values: PrizeDetailInterface,
    formMethod: any
  ) => {
    if (params.id) {
      let postData = {
        contest_winner_id : values.contest_winner_id,
        status : values.status
      }
      const res: any = await updatePrize(params.id, postData)
      if(res?.data?.statusCode === 200 || res?.data?.statusCode === 201){
        alert.success(res?.data?.message)
        navigator("/prizes")
      } else {
        alert.error(res?.message)
      }
      // if (res.errors && res.message) {
      //   const errors: any = {}
      //   const errorKeys = Object.keys(res.errors)
      //   for (var i = 0; i < errorKeys.length; i++) {
      //     errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
      //   }
      //   formMethod.setErrors(errors)
      // } else {
      //   alert.success("Prize Status Updated Successfully")
      //   navigator("/prizes")
      // }
    }
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Prizes</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/prizes">Prizes</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Edit Prize Status</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Edit Prize Status</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={
                      detail[ModuleConfig.primaryKey] == params.id
                        ? detail
                        : ModuleConfig.formDefaultValue
                    }
                    validate={(values) => {
                      const errors: any = {}
                      const fields = Object.keys(ModuleConfig.formFields)
                      for (let index = 0; index < fields.length; index++) {
                        if (
                          !values[fields[index]] &&
                          ModuleConfig.formFields[fields[index]].isRequired
                        ) {
                          errors[fields[index]] = "Required"
                        }
                      }

                      return errors
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {Object.keys(ModuleConfig.formFields).map(
                            (field: string, index: number) => {
                              const fieldDetails =
                                ModuleConfig.formFields[field]
                              return (fieldDetails.isAdd == false &&
                                params.id) ||
                                fieldDetails.isAdd == undefined ? (
                                <div
                                  key={`field_${index}`}
                                  className={fieldDetails.colWidth}
                                >
                                  <FormField
                                    name={field}
                                    fieldDetails={fieldDetails}
                                    values={values}
                                    errors={errors}
                                    disabled={fieldDetails.disabled}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              ) : null
                            }
                          )}
                        </div>
                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PrizeStatusUpdate
