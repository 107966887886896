import axios from "axios";
import config from "./config";

const baseURL = config.STORE_ENDPOINT;
const AuthToken = config.STORE_TOKEN;

const ShopAxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 35000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

ShopAxiosInstance.interceptors.request.use(
  async (config: any) => {
    axios.defaults.timeout = 35000;
    config.baseURL = baseURL;

    config.headers.Authorization = `Bearer ${AuthToken}`;

    return config;
  },
  (error) => Promise.reject(error.response.data)
);

export default ShopAxiosInstance;
