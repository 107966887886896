const ModuleConfig = {
  primaryKey: "contest_winner_id",

  formDefaultValue: {
    contest_winner_id: "",
    contest_id: "",
    user_id: "",
    credit: "",
    amount: "",
    status: "",
  },

  formFields: {

    "Contest.title.en": {
      label: "Contest",
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      disabled:true,
    },

    "User.nick_name" : {
      label: "User Name",
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      disabled:true,
    },

    "User.email" : {
      label: "Email ID",
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      disabled:true,
    },

    "User.address" : {
      label: "Address",
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      disabled:true,
    },

    credit: {
      label: "Credit",
      colWidth: "col-md-6",
      type: "text",
      inputType: "number",
      disabled:true,
    },

    amount: {
      label: "Amount",
      colWidth: "col-md-6",
      inputType: "number",
      type: "text",
      disabled:true,
    },

    status: {
      label: "Status",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "Complete",
          value: "Complete",
        },
        {
          label: "Cheque in Transit",
          value: "Cheque in Transit",
        }
      ],
    },
  },

  listColumnOrder: [
    "contest_id",
    "user_id",
    "credit",
    "amount",
    "status"
  ],
}

export default ModuleConfig
