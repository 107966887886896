const ModuleConfig = {
  primaryKey: "language_id",

  formDefaultValue: {
    language_name: "",
    language_code: "",
  },

  formFields: {
    language_name: {
      label: "Language Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength:"50"
    },
    language_code: {
      label: "Language Code",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength:"3"
    },
  },

  listColumnOrder: ["language_id", "language_name", "language_code"],
};

export default ModuleConfig;
