import { useLocation, useNavigate } from "react-router-dom";
import {
  appState,
} from "../../stores/appSlice";
import { useAppDispatch, useAppSelector } from "../../stores/hooks";

const Header = () => {
  const dispatchApp = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {profile } = useAppSelector(appState);
  const token = localStorage.getItem("token");


  const signout = async () => {
    
      localStorage.clear();
      navigate("/signin");
      window.location.reload();

  };

  const NO_HEADER_MENU = ["/signin", "/signin/", "/signup", "/signup/"];

  return NO_HEADER_MENU.indexOf(location.pathname) === -1 ? (
    <header className="navbar pcoded-header navbar-expand-lg navbar-light ">
      <div className="m-header">
        <a className="mobile-menu" id="mobile-collapse1" href="#!">
          <span></span>
        </a>
        <a href="/" className="b-brand">
          <div className="b-bg">
            <i className="feather icon-trending-up"></i>
          </div>
          <span className="b-title">Safety Survivor</span>
        </a>
      </div>
      <a className="mobile-menu" id="mobile-header" href="#!">
        <i className="feather icon-more-horizontal"></i>
      </a>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ms-auto">
          <li>
            <div className="dropdown drp-user">
              <a
                href="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="icon feather icon-settings"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-end profile-notification">
                <ul className="pro-body">
                  <li>
                    <a href="/profile" className="dropdown-item">
                      <i className="feather icon-user"></i> Profile
                    </a>
                  </li>
                  <li>
                    <a href="/change-password" className="dropdown-item">
                      <i className="feather icon-settings"></i> Change Password
                    </a>
                  </li>
                  <li>
                    <a onClick={()=>{signout()}} className="dropdown-item">
                      <i className="feather icon-lock"></i> Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  ) : null;
  
};

export default Header;
