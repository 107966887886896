import AxiosInstance from "../../utils/AxiosInstance";
import { PageDetailInterface } from "./models/PageDetailInterface";

const API_ROUTE = "pages";
export const getPageList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/page?${prm}`);
};

export const getPageDetail = async (index: string) => {
  return await AxiosInstance.get(`/page/get-by-id/${index}`);
};

export const createPage = async (data: PageDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/page/create`, data);
};

export const deletePage = async (index: string) => {
  return await AxiosInstance.delete(`/page/delete-by-id/${index}`);
};
export const updatePage = async (index: string, data: PageDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.put(`/page/update`, data);
};
