import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../stores"
import { AchievementDetailInterface } from "./models/AchievementDetailInterface"
import {
  createAchievement,
  deleteAchievement,
  getAchievementDetail,
  getAchievementList,
  getProductsListing,
  updateAchievement,
} from "./AchievementAPI"

export interface AchievementStateInterface {
  achievements: AchievementDetailInterface[]
  favourites: AchievementDetailInterface[]
  totalRecord: number
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  detail: AchievementDetailInterface
  products: any
}

const initialState: AchievementStateInterface = {
  achievements: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    achievement_id: "",
    catalog_url: "",
    event_title: "",
    event_description: "",
    user_ids: [],
    type: "",
    contest_id: "",
    credit: "",
    event_image: "",
    users : [],
    achievement : {}
  },
  products: [],
}

export const getAchievementListAsync = createAsyncThunk(
  "achievement/list",
  async (data: any) => {
    const response = await getAchievementList(data)
    return response.data
  }
)

export const getAchievementDetailAsync = createAsyncThunk(
  "achievement/detail",
  async (index: string) => {
    const response = await getAchievementDetail(index)
    return response.data.data
  }
)

export const createAchievementAsync = createAsyncThunk(
  "achievement/create",
  async (data: AchievementDetailInterface) => {
    const response = await createAchievement(data)
    return response.data
  }
)

export const deleteAchievementAsync = createAsyncThunk(
  "achievement/delete",
  async (index: string) => {
    const response = await deleteAchievement(index)
    return response.data
  }
)

export const updateAchievementAsync = createAsyncThunk(
  "achievement/update",
  async (data: { index: string; data: AchievementDetailInterface }) => {
    const response = await updateAchievement(data.index, data.data)
    return response.data
  }
)

export const getProductsListingAsync = createAsyncThunk(
  "product/get",
  async () => {
    const response = await getProductsListing()
    return response.data
  }
)

export const AchievementSlice = createSlice({
  name: "achievement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getAchievementListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getAchievementListAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.achievements = action.payload.data
        state.totalRecord = action.payload.total
      })
      .addCase(getAchievementListAsync.rejected, (state) => {
        state.status = "failed"
      })
      // GET DETAIL
      .addCase(getAchievementDetailAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getAchievementDetailAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.detail = action.payload
      })
      .addCase(getAchievementDetailAsync.rejected, (state) => {
        state.status = "failed"
      })
      // CREATE
      .addCase(createAchievementAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(createAchievementAsync.fulfilled, (state, action) => {
        state.status = "created"
        state.detail = action.payload
      })
      .addCase(createAchievementAsync.rejected, (state) => {
        state.status = "failed"
      })
      // UPDATE
      .addCase(updateAchievementAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updateAchievementAsync.fulfilled, (state, action) => {
        state.status = "updated"
        state.detail = action.payload
      })
      .addCase(updateAchievementAsync.rejected, (state) => {
        state.status = "failed"
      })
      // DELETE
      .addCase(deleteAchievementAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteAchievementAsync.fulfilled, (state, action) => {
        state.status = "deleted"
        state.detail = action.payload
      })
      .addCase(deleteAchievementAsync.rejected, (state) => {
        state.status = "failed"
      })

      // GET PRODUCT LIST
      .addCase(getProductsListingAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getProductsListingAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.products = action.payload.data
      })
      .addCase(getProductsListingAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const {} = AchievementSlice.actions
export const AchievementState = (state: RootState) => state.achievements
export default AchievementSlice.reducer
