import AxiosInstance from "../../utils/AxiosInstance"
import { VideosDetailInterface } from "./models/VideosDetailInterface"

const API_ROUTE = "video"
export const getVideosList = async (params: any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}?${prm}`)
}

export const getVideosDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/get-by-id/${index}`)
}

export const createVideos = async (data: VideosDetailInterface) => {
  return await AxiosInstance.post(`/${API_ROUTE}/create`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const deleteVideos = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/delete-By-Id/${index}`)
}
export const updateVideos = async (
  index: string,
  data: VideosDetailInterface
) => {
  return await AxiosInstance.put(`/${API_ROUTE}/update`, data)
}
