import { Route } from "react-router-dom";
import VideosCreate from "./pages/VideosCreate";
import VideosList from "./pages/VideosList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "videos"; 
const VideosRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<VideosList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<VideosCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:video_id`} element={<VideosCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default VideosRoute;