import { useEffect, useState } from "react"
import AxiosInstance from "../../../utils/AxiosInstance"
import ShopAxiosInstance from "../../../utils/ShopAxiosInstance"
import Config from "../../../utils/config"
import Select from "react-select"

export interface OptionInterface {
  value: string
  label: string
}
const InputSelect = (props: any) => {
  const [options, setOptions] = useState<OptionInterface[]>([])

  useEffect(() => {
    if (!props.fieldDetails.asynchLoad) {
      setOptions(props.fieldDetails.options)
    } else {
      promiseOptions()
    }
  }, [])

  const promiseOptions = () => {
    if (props?.fieldDetails?.dataUrl == "products.json") {
      return ShopAxiosInstance.get(
        `printify/shops/${Config.DEFAULT_STORE}/${props?.fieldDetails?.dataUrl}`
      ).then((res) => {
        const opts: OptionInterface[] = []
        for (let index = 0; index < res?.data?.data?.length; index++) {
          opts.push({
            value: res?.data?.data[index][props?.fieldDetails?.keyValue],
            label: res?.data?.data[index][props?.fieldDetails?.keylabel],
          })
        }
        return setOptions(opts)
      })
    }
    return AxiosInstance.get(
      `${props?.fieldDetails?.dataUrl}?${props?.fieldDetails?.filterParams}`
    ).then((res) => {
      const opts: OptionInterface[] = []
      for (let index = 0; index < res?.data?.data?.length; index++) {
        opts.push({
          value: res?.data?.data[index][props?.fieldDetails?.keyValue],
          label: res?.data?.data[index][props?.fieldDetails?.keylabel].en
            ? res?.data?.data[index][props?.fieldDetails?.keylabel].en
            : res?.data?.data[index][props?.fieldDetails?.keylabel],
        })
      }
      return setOptions(opts)
    })
  }

  const value = options.filter(
    (option) => option.value == props?.values[props?.name]
  )
  return (
    <Select
      name={props.name}
      classNamePrefix="select"
      defaultValue={props?.values[props?.name]}
      value={value}
      isDisabled={props?.disabled}
      options={options}
      onChange={(op) => {
        props.setFieldValue(props.name, op.value)
      }}
    />
  )
}

export default InputSelect
