import AxiosInstance from "../../utils/AxiosInstance"
import ShopAxiosInstance from "../../utils/ShopAxiosInstance"
import Config from "../../utils/config"
import { AchievementDetailInterface } from "./models/AchievementDetailInterface"

const API_ROUTE = "achievement"
export const getAchievementList = async (params: any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/${API_ROUTE}/get-all?${prm}`)
}

export const getAchievementDetail = async (index: string) => {
  return await AxiosInstance.get(`/${API_ROUTE}/get-by-id/${index}`)
}

export const createAchievement = async (data: AchievementDetailInterface) => {
  const formData = new FormData()
  const keys = Object.keys(data)
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]])
  }

  return await AxiosInstance.post(`/${API_ROUTE}/create`, data)
}

export const deleteAchievement = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/delete-by-id/${index}`)
}
export const updateAchievement = async (
  index: string,
  data: AchievementDetailInterface
) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.put(`/${API_ROUTE}/update`, data)
}

export const getProductsListing = async () => {
  return ShopAxiosInstance.get(`printify/shops/${Config.DEFAULT_STORE}/products.json`)
}