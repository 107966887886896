import { Route } from "react-router-dom";
import ReportCreate from "./pages/ReportCreate";
import Report from "./pages/Report";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "report"; 
const ReportRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<Report />} key={`${MODULE_ROUTE}_index`} />,
  ];

export default ReportRoute;