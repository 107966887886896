import { useEffect, useState } from "react"
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getVideosListAsync, VideosState } from "../VideosSlice"
import { deleteVideos } from "../VideosAPI"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"

import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import { getSportListAsync, SportState } from "../../sport/SportSlice"
import { useAlert } from "react-alert"
/**
 * Videosing list page
 */
const VideosList = () => {
  const { videoss, status, totalRecord } = useAppSelector(VideosState)
  const { sports } = useAppSelector(SportState)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("created_at")
  const [order, setOrder] = useState("DESC")
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const alert = useAlert()
  const { SearchBar, ClearSearchButton } = Search

  const [deleteItem, setDeleteItem] = useState("")

  const [searchText, setSearchText] = useState("")
  const [searchSport, setSearchSport] = useState("")

  useEffect(() => {
    getPageData()
    getSportData()
  }, [dispatch, page, sort, order])

  const getPageData = () => {
    const filter = {
      page: page,
      sortField: sort,
      order: order,
    }
    dispatch(getVideosListAsync(filter))
  }

  const getSportData = () => {
    const filter = {
      page: page,
    }
    dispatch(getSportListAsync(filter))
  }

  const removeVideos = async () => {
    const res: any = await deleteVideos(deleteItem)
    if (
      res?.statusCode != 200 &&
      res?.statusCode != 201 &&
      res.data == undefined
    ) {
      alert.error(res?.message)
    } else {
      alert.success("data deleted Successfully")
    }
    setDeleteItem("")
    getPageData()
  }

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const docFormatter = (cell, row, rowIndex) => {
    return cell.toLocaleString()
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "author",
      text: "Author",
      sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: true,
      formatter: docFormatter,
    },
    {
      dataField: "Sport.sport_name.en",
      text: "Sport",
      sort: true,
    },
    {
      dataField: "Language.language_name",
      text: "Language",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          {row.total_purchased == 0 && (
            <i
              className="feather icon-trash-2 text-danger fw-bold"
              onClick={() => {
                setDeleteItem(row.video_id)
              }}
            ></i>
          )}
          <i
            className="feather icon-edit text-success fw-bold"
            onClick={() => {
              navigate(`/videos/update/${row.video_id}`)
            }}
          ></i>
        </div>
      ),
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const listData =
    videoss.length == 0
      ? []
      : videoss.filter((e: any) => {
          return (
            (e.title?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
              e.description?.toUpperCase().indexOf(searchText.toUpperCase()) >=
                0 ||
              e.author?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
              e.credit?.toString().indexOf(searchText.toString()) >= 0 ||
              e.short_description
                ?.toUpperCase()
                .indexOf(searchText.toUpperCase()) >= 0 ||
              e.Sport?.sport_name?.en
                ?.toUpperCase()
                .indexOf(searchText.toUpperCase()) >= 0 ||
              e.Language?.language_name
                ?.toUpperCase()
                .indexOf(searchText.toUpperCase()) >= 0) &&
            (searchSport == "" || e.Sport?.sport_name?.en == searchSport)
          )
        })

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Video</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Video</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Video</h5>
                    </Col>
                    <Col md={2}>
                      <Link className="btn btn-primary" to="/videos/create">
                        Add Video
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Something went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="video_id"
                        data={listData}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <Col>
                              <input
                                placeholder="Search"
                                type={"text"}
                                value={searchText}
                                className="form-control"
                                onChange={(e) => {
                                  setSearchText(e.target.value)
                                }}
                              />
                            </Col>
                            <Col>
                              {" "}
                              <select
                                className="form-control "
                                onChange={(e) => {
                                  setSearchSport(e.target.value)
                                }}
                              >
                                <option selected={searchSport == ""} value="">
                                  Select Sport
                                </option>

                                {sports.map((sport: any, index: number) => {
                                  return (
                                    <option value={sport?.sport_name?.en}>
                                      {sport?.sport_name?.en}
                                    </option>
                                  )
                                })}
                              </select>
                            </Col>
                            <Col>
                              <button
                                onClick={() => {
                                  setSearchSport("")
                                  setSearchText("")
                                }}
                                className="btn btn-primary"
                              >
                                Clear
                              </button>
                            </Col>
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Video?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeVideos}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}
export default VideosList
