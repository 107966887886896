const ModuleConfig = {
  primaryKey: "contest_round_id",

  formDefaultValue: {
    contest_id: "",
    title: "",
    reg_start_date_time: "",
    reg_close_date_time: "",
    status: "",
    sponsorship_id: "",

  },

  formFields: {
    title: {
      label: "Title",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength:"50"
    },

    reg_start_date_time: {
      label: "Registration Start DateTime",
      isRequired: true,
      colWidth: "col-md-6",
      type: "date",
      inputType: "datepicker"
    },
    reg_close_date_time: {
      label: "Registration Close DateTime",
      isRequired: true,
      colWidth: "col-md-6",
      type: "date",
      inputType: "datepicker"
    },

    status: {
      label: "Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Open",
          value: "Open",
        },
        {
          label: "In Progress",
          value: "In progress",
        },
        {
          label: "Close",
          value: "Close",
        },
      ],
    },

    sponsorship_id: {
      label: "Sponsor",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Sponsor",
      asynchLoad: true,
      dataUrl: "sponsorship",
      filterParams:"filterField=status&filterValue=Active",
      keyValue: "sponsorship_id",
      keylabel: "company_name",
      options: [],
    },


  },
  listColumnOrder: ["title", "contest_id", "status"],
};

export default ModuleConfig;
