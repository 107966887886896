import { Route } from "react-router-dom";
import ContestRoundCreate from "./pages/ContestRoundCreate";
import ContestRoundList from "./pages/ContestRoundList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "contest-round"; 
const ContestRoundRoute = [
    <Route path={`${MODULE_ROUTE}/:contest_id`} element={<ContestRoundList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create/:contest_id`} element={<ContestRoundCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:contest_id/:contest_round_id`} element={<ContestRoundCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default ContestRoundRoute;