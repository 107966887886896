import { Button, Container, Row, Table } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getSportDetailAsync, SportState } from "../SportSlice"
import { Field, FieldArray, Formik } from "formik"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect, useState } from "react"
import { createSport, updateSport } from "../SportAPI"
import { appState } from "../../../stores/appSlice"
import FileInput from "../../common/FormField/FileInput"

/**
 * Sporting Favourites list page
 */

const options = [
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 0,
  },
]
const SportCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(SportState)
  const { languages } = useAppSelector(appState)
  const params = useParams()
  const [currentLanguage, setCurrentLanguage] = useState("en")
  const navigator = useNavigate()
  const [defaultValus, setDefaultValue] = useState({
    mulitLang: [],
    status: "",
    logo: "",
  })

  useEffect(() => {
    let multiLang = []
    languages.forEach((element) => {
      multiLang.push({
        code: element.code,
        label: element.name,
        sport_name: "",
      })
    })
    setDefaultValue({
      ...defaultValus,
      mulitLang: multiLang,
    })
  }, [])

  useEffect(() => {
    if (params.sport_id) {
      dispatch(getSportDetailAsync(params.sport_id))
    }
  }, [params.sport_id])

  useEffect(() => {
    if (detail.sport_id == params.sport_id) {
      let multiLang = []
      languages.forEach((element) => {
        multiLang.push({
          code: element.code,
          label: element.name,
          sport_name: detail.sport_name[element.code],
        })
      })
      setDefaultValue({
        logo: detail.logo,
        status: detail.status,
        mulitLang: multiLang,
      })
    }
  }, [detail])

  const handleSubmit = async (values: any, formMethod: any) => {
    if (params.sport_id) {
      const data: any = {
        sport_id: params.sport_id,
        sport_name: {},
        status: values.status,
        logo: values.logo,
      }
      values.mulitLang.forEach((element) => {
        data.sport_name[element.code] = element.sport_name
      })

      const res: any = await updateSport(params.sport_id, data)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        navigator("/sport")
      }
    } else {
      const data: any = {
        sport_name: {},
        status: values.status,
        logo: values.logo,
      }
      values.mulitLang.forEach((element) => {
        data.sport_name[element.code] = element.sport_name
      })

      const res: any = await createSport(data)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        navigator("/sport")
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Sport</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/sport">Sports</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.sport_id ? "Edit " : "Add "}Sport</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col">
                  <h5>{params.sport_id ? "Edit " : "Add "}Sport</h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={defaultValus}
                    validate={(values) => {
                      const errors: any = {}

                      if (!values.logo) {
                        errors.logo = "Required"
                      }
                      if (!values.status) {
                        errors.status = "Required"
                      }
                      for (var i = 0; i < values.mulitLang.length; i++) {
                        if (!values.mulitLang[i].sport_name) {
                          if (!errors.mulitLang) {
                            errors.mulitLang = []
                          }
                          errors[`mulitLang.${i}.sport_name`] = "Required"
                        }
                      }
                      return errors
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <FieldArray
                              name="mulitLang"
                              render={() => (
                                <>
                                  {values.mulitLang &&
                                  values.mulitLang.length > 0
                                    ? values.mulitLang.map(
                                        (mulitLang, index) => (
                                          <>
                                            <div
                                              key={index}
                                              className={"col-md-6"}
                                            >
                                              <h4 className="input-label">
                                                {mulitLang.label}
                                              </h4>
                                              <div className="form-group mb-4">
                                                <div className="input-label">
                                                  Sport Name
                                                </div>
                                                <Field
                                                  className="form-control"
                                                  name={`mulitLang.${index}.sport_name`}
                                                  maxLength="50"
                                                />
                                                {touched.mulitLang &&
                                                touched.mulitLang[index] &&
                                                touched.mulitLang[index]
                                                  .sport_name &&
                                                typeof errors[
                                                  `mulitLang.${index}.sport_name`
                                                ] === "string" ? (
                                                  <div className="error">
                                                    {
                                                      errors[
                                                        `mulitLang.${index}.sport_name`
                                                      ]
                                                    }
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </>
                                        )
                                      )
                                    : null}
                                </>
                              )}
                            />

                            <div className={"col-md-6"}>
                              <FormField
                                name={"logo"}
                                fieldDetails={ModuleConfig.formFields.logo}
                                accept="image/png, image/jpeg"
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                            <div className={"col-md-6"}>
                              <FormField
                                name={"status"}
                                fieldDetails={ModuleConfig.formFields.status}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <hr />
                          <button
                            type="submit"
                            className="btn btn-primary"
                            // disabled={status === "loading"}
                          >
                            Submit
                          </button>
                        </form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SportCreate
