import { getPropByString } from "../../../utils/CommonFunction";
import config from "../../../utils/config";
import S3 from "react-aws-s3";
import uploadFileToBlob from "../../../utils/AzureStorageBlob"
import Config from '../../../utils/config';
import { useDropzone } from "react-dropzone"
import { useState } from "react"
window.Buffer = window.Buffer || require("buffer").Buffer

const MultiDropzone = (props: any) => {
  const [files, setFiles] = useState([])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 20,
    multiple: true,
    onDrop: (acceptedFiles) => {
      var acceptedFilesWithPreview = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
      setFiles([...files, ...acceptedFilesWithPreview])
    },
  })

  
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
  }

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  }

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
  }
  const thumbs = files.map((file, i) => {
    const filePreview =
      file.type == 'application/pdf' ? '/images/pdf.png' : file.preview
    return (
      <div className="img-block">
        {/* <div  style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img src={filePreview} style={img} />
          </div>
        </div> */}
        {/* <div>
          <a className="link" onClick={removeFile(i)}>
            Remove
          </a>
        </div> */}
      </div>
    )
  })



  var values = getPropByString(props?.values, props?.name);
  return (
    <div className="input-group">  
      <input
        type={"file"}
        id={props.name}
        multiple={props.multiple}
        className="form-control"
        onBlur={props.handleBlur}
        onChange={ async(event  : any) => {
          const res = await uploadFileToBlob(event.target.files[0], 'images');
          if (event.target.files) {
            props.setFieldValue(props.name, res);
          } else {
            props.setFieldValue(props.name, null);
          }
        }}
        aria-describedby="basic-addon2"
      />

      {values ? (
        <span className="input-group-text" id="basic-addon2">
          <a href={`${Config.cdnUrl}${values}`} target="_blank" rel="noopener noreferrer">
            <i className="feather icon-eye"></i>
          </a>
        </span>
      ) : null}
    </div>
  );
};

export default MultiDropzone;
