import { Route } from "react-router-dom";
import SponsorshipCreate from "./pages/SponsorshipCreate";
import SponsorshipList from "./pages/SponsorshipList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "sponsorship"; 
const SponsorshipRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<SponsorshipList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<SponsorshipCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<SponsorshipCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default SponsorshipRoute;