import { useEffect, useState } from "react"
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getPrizeListAsync, PrizeState } from "../PrizeSlice"
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa"
import { PrizeDetailInterface } from "../models/PrizeDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import CustomPagination from "../../../utils/CustomPagination"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

/**
 * Prizes list page
 */
const PrizeList = () => {
  const { prizes, status, totalRecord } = useAppSelector(PrizeState)
  const [page, setPage] = useState(1)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { SearchBar, ClearSearchButton } = Search
  const { ExportCSVButton } = CSVExport

  const [deleteItem, setDeleteItem] = useState("")

  const [searchText, setSearchText] = useState("")
  const [searchStatus, setSearchStatus] = useState("")

  useEffect(() => {
    getPageData()
  }, [dispatch, page])

  const getPageData = () => {
    const filter = {
      page: page,
    }
    dispatch(getPrizeListAsync(filter))
  }

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const columns = [
    {
      dataField: "Contest.title.en",
      text: "Contest",
      sort: true,
    },
    {
      dataField: "User.nick_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "User.email",
      text: "Email ID",
      sort: true,
    },
    {
      dataField: "User.address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: true,
      formatter: (cell, row, rowData) => {
        return cell.toLocaleString()
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell, row, rowData) => {
        return cell.toLocaleString()
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      align: "center",
      classes: "col-2",
      headerStyle: (colum, colIndex) => {
        return { "width" : "200px !important" };
    },
      formatter: (cellContent: any, row: any) =>
        row.status == "Pending" ? (
          <span className="label label-info rounded-pill"> Pending </span>
        ) : row.status == "Complete" ? (
          <span className="label label-success rounded-pill"> Complete </span>
        ) : row.status == "Cheque in Transit" ? (
          <span className="label label-success rounded-pill"> Cheque in Transit </span>
        ) : (
          ""
        ),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          <i
            className="feather icon-edit text-success fw-bold"
            onClick={() => {
              navigate(`/prizes/update/${row.contest_winner_id}`)
            }}
          ></i>
        </div>
      ),
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const listData = prizes.filter((e: any) => {
    return (
      (e.Contest?.title?.en?.toUpperCase().indexOf(searchText.toUpperCase()) >=
        0 ||
        e.User?.nick_name.toUpperCase().indexOf(searchText.toUpperCase()) >=
          0 ||
        e.User?.email.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 || 
        e.amount.toString().indexOf(searchText.toString()) >= 0 ||
        e.credit.toString().indexOf(searchText.toString()) >= 0) &&
      (searchStatus == "" || e.status == searchStatus)
    )
  })

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">All Prizes</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Prizes</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Prizes</h5>
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Somthing went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={listData}
                        columns={columns}
                        selectRow={selectRow}
                        search
                        exportCSV
                      >
                        {(props: any) => (
                          <Row>
                            <Row className="mb-3">
                              <Col>
                                <input
                                  placeholder="Search"
                                  type={"text"}
                                  value={searchText}
                                  className="form-control"
                                  onChange={(e) => {
                                    setSearchText(e.target.value)
                                  }}
                                />
                              </Col>
                              <Col>
                                {" "}
                                <select
                                  className="form-control "
                                  onChange={(e) => {
                                    setSearchStatus(e.target.value)
                                  }}
                                >
                                  <option
                                    selected={searchStatus == ""}
                                    value=""
                                  >
                                    Select Status
                                  </option>
                                  <option selected={searchStatus == "Pending"}>
                                    Pending
                                  </option>
                                  <option selected={searchStatus == "Complete"}>
                                    Complete
                                  </option>
                                  <option
                                    selected={
                                      searchStatus == "Cheque in Transit"
                                    }
                                  >
                                    Cheque in Transit
                                  </option>
                                </select>
                              </Col>
                              <Col>
                                <button
                                  onClick={() => {
                                    setSearchStatus("")
                                    setSearchText("")
                                  }}
                                  className="btn btn-primary"
                                >
                                  Clear
                                </button>
                              </Col>
                            </Row>
                            {/* <SearchBar {...props.searchProps} />
                              <ClearSearchButton
                                {...props.searchProps}
                                className="btn-primary fw-bold ms-2"
                              /> */}
                            {/* <ExportCSVButton
                                {...props.csvProps}
                                className="btn-success fw-bold ms-2"
                              >
                                Export CSV
                              </ExportCSVButton> */}
                            <BootstrapTable
                              filter={filterFactory()}
                              pagination={paginationFactory(options)}
                              {...props.baseProps}
                            />
                          </Row>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PrizeList
