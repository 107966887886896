import { Field, Formik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import AxiosInstance from "../utils/AxiosInstance"
import FileInput from "./common/FormField/FileInput"

const options = [
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 0,
  },
]

const Profile = () => {
  const [profileData, setProfileData] = useState<any>({})
  const navigate = useNavigate()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const res = await AxiosInstance.get("users/get-profile")
    if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
      setProfileData(res?.data?.data)
    }
  }
  const handleSubmit = async(values) => {
    // const data = {
    //   ...values,
    //   user_id: profileData?.user_id,
    //   _2fa : profileData?._2fa
    // }
    // const res = await AxiosInstance.post("/users/update",data)
    
    // if(res?.data?.statusCode === 200){
    //   navigate("/")
    // }else{
    //   alert("Something went wrong.")
    // }
  }
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Profile</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Profile</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <Formik
                        initialValues={{
                          // nick_name: "",
                          // email: "",
                          // profile_image: "",
                          // status: "",
                          // phone: "",
                          // media_credit: "",
                          // role_name: "",

                          nick_name: profileData.nick_name
                            ? profileData.nick_name
                            : "",
                          email: profileData.email,
                          profile_image: profileData.profile_image ? profileData.profile_image : "",
                          status: profileData.status ? profileData.status : "",
                          phone: profileData.phone,
                          media_credit: profileData.media_credit,
                        }}
                        validate={(values) => {
                          const errors: any = {}
                        

                          return errors
                        }}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="row">

                              <div className="col-md-6">
                                <label
                                  className="control-label col-xs-3 mb-1 pb-1"
                                  htmlFor="nick_name"
                                >
                                  User Name
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control cform-control border-right-0"
                                    placeholder="User Name"
                                    name="nick_name"
                                    value={values.nick_name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label
                                  className="control-label col-xs-3 mb-1 pb-1"
                                  htmlFor="email"
                                >
                                  Email
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control cform-control border-right-0"
                                    placeholder="Email"
                                    name="email"
                                    value={values.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label
                                  className="control-label col-xs-3 mb-1 pb-1"
                                  htmlFor="phone"
                                >
                                  Phone
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    maxLength={11}
                                    className="form-control cform-control border-right-0"
                                    placeholder="Phone"
                                    name="phone"
                                    value={values.phone}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label
                                  className="control-label col-xs-3 mb-1 pb-1"
                                  htmlFor="status"
                                >
                                  Status
                                </label>
                                <div className="input-group">
                                  <Field
                                    as="select"
                                    className="form-control"
                                    name="status"
                                    disabled
                                  >
                                    {options.map((option: any, index: any) => {
                                      return (
                                        <option
                                          key={index}
                                          value={option?.value}
                                        >
                                          {option?.label}
                                        </option>
                                      )
                                    })}
                                  </Field>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label
                                  className="control-label col-xs-3 mb-1 pb-1"
                                  htmlFor="media_credit"
                                >
                                  Media Credit
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    maxLength={11}
                                    className="form-control cform-control border-right-0"
                                    placeholder="Media Credit"
                                    name="media_credit"
                                    value={values.media_credit}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="form-group checkbox_group pb-1 mb-4">
                              <div className="row">
                                <div className="col-sm-12">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn_submit"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div> */}
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
