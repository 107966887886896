import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { PageDetailInterface } from "./models/PageDetailInterface";
import {
  createPage,
  deletePage,
  getPageDetail,
  getPageList,
  updatePage,
} from "./PageAPI";

export interface PageStateInterface {
  pages: PageDetailInterface[];
  favourites: PageDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: PageDetailInterface;
}

const initialState: PageStateInterface = {
  pages: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    page_id: "",
    title: "",
    slug: "",
    content: "",
    language_id:""
  },
};

export const getPageListAsync = createAsyncThunk(
  "page/list",
  async (data: any) => {
    const response = await getPageList(data);
    return response.data;
  }
);
export const getPageDetailAsync = createAsyncThunk(
  "page/detail",
  async (index: string) => {
    const response = await getPageDetail(index);
    return response.data.data;
  }
);

export const createPageAsync = createAsyncThunk(
  "page/create",
  async (data: PageDetailInterface) => {
    const response = await createPage(data);
    return response.data;
  }
);

export const deletePageAsync = createAsyncThunk(
  "page/delete",
  async (index: string) => {
    const response = await deletePage(index);
    return response.data;
  }
);

export const updatePageAsync = createAsyncThunk(
  "page/update",
  async (data: { index: string; data: PageDetailInterface }) => {
    const response = await updatePage(data.index, data.data);
    return response.data;
  }
);

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getPageListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPageListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.pages = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getPageListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getPageDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPageDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getPageDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createPageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPageAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createPageAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updatePageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePageAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updatePageAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deletePageAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePageAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deletePageAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = pageSlice.actions;
export const PageState = (state: RootState) => state.page;
export default pageSlice.reducer;
