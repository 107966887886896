import AxiosInstance from "../../utils/AxiosInstance";
import { ContestRoundGameDetailInterface } from "./models/ContestRoundGameDetailInterface";

const API_ROUTE = "contestroundgames";
export const getContestRoundGameList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/contest-round-game?${prm}`);
};

export const getContestRoundGameDetail = async (index: string) => {
  return await AxiosInstance.get(`/contest-round-game/get-by-id/${index}`);
};

export const getContestRoundGameDetailByRoundId = async (index: string) => {
  return await AxiosInstance.get(`/contest-round-game/get-by-contest-round-id-with-child-data/${index}`);
};

export const createContestRoundGame = async (data: ContestRoundGameDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/contest-round-game/create`, data);
};

export const deleteContestRoundGame = async (index: string) => {
  return await AxiosInstance.delete(`/contest-round-game/delete-by-id/${index}`);
};
export const updateContestRoundGame = async (index: string, data: ContestRoundGameDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.put(`/contest-round-game/update`, data);
};
