import { Route } from "react-router-dom";
import ContestCreate from "./pages/ContestCreate";
import ContestList from "./pages/ContestList";
import ContestInfo from './pages/ContestInfo';
import ContestEdit from './pages/ContestEdit';

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "contest"; 
const ContestRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<ContestList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<ContestCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:contest_id`} element={<ContestEdit />} key={`${MODULE_ROUTE}_edit`} />,
    // <Route path={`${MODULE_ROUTE}/contest-round/:contest_id`} element={<ContestInfo />} key={`${MODULE_ROUTE}_info`} />,
  ];

export default ContestRoute;