import { Tab, Tabs } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import {
  getAchievementDetailAsync,
  AchievementState,
  getProductsListingAsync,
} from "../AchievementSlice"
import { Field, FieldArray, Formik } from "formik"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect, useState } from "react"
import { createAchievement, updateAchievement } from "../AchievementAPI"
import { appState } from "../../../stores/appSlice"
import { useAlert } from "react-alert"
import AchievementUserList from "./AchievementUserList"
import TextInput from "../../common/FormField/TextInput"
import Config from "../../../utils/config"

const AchievementDetails = () => {
  const dispatch = useAppDispatch()
  const { status, detail, products } = useAppSelector(AchievementState)
  const params = useParams()
  const alert = useAlert()
  const navigator = useNavigate()
  const { languages } = useAppSelector(appState)
  const [currentTab, setCurrentTab] = useState("achievement_edit")
  const [catalogName, setCatalogName] = useState("")

  const [defaultValus, setDefaultValue] = useState<any>({})

  const getProductName = () => {
    for (let i = 0; i < products.length; i++) {
      if (products[i]?.id == detail.achievement.catalog_url) {
        setCatalogName(products[i]?.title)
      }
    }
  }

  useEffect(() => {
    if (params.achievement_id) {
      dispatch(getAchievementDetailAsync(params.achievement_id))
      dispatch(getProductsListingAsync())
    }
  }, [params.achievement_id])

  useEffect(() => {
    getProductName()
  }, [products, detail])

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Achievement</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/achievement">Achievements</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>
                    Acheivement Details
                    {params.achievement_id
                      ? "/ " + detail.achievement.event_title
                      : "Add "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Tabs
        defaultActiveKey="achievement_edit"
        id="uncontrolled-tab-example"
        activeKey={currentTab}
        onSelect={(k) => {
          setCurrentTab(k)
          if (params.achievement_id) {
            dispatch(getAchievementDetailAsync(params.achievement_id))
          }
        }}
      >
        <Tab eventKey="achievement_edit" title="Achievement Details">
          {currentTab === "achievement_edit" ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      className="w-100 h-100"
                      src={`${Config.cdnUrl}${detail.achievement.event_image}`}
                      alt="achievementImage"
                    />
                  </div>
                  <div className="col-md-9">
                    <p>Event Title : {detail?.achievement.event_title}</p>
                    <p>
                      Event Description :{" "}
                      {detail?.achievement?.event_description}
                    </p>
                    <p>Catalog Product : {catalogName} </p>
                    <p>Type : {detail?.achievement?.type} </p>
                    {detail?.achievement?.contest_id != null && (
                      <p>
                        contest : {detail?.achievement?.Contest?.title?.en}{" "}
                      </p>
                    )}
                    {detail?.achievement?.credit != null && (
                      <p>Credit : {detail?.achievement?.credit} </p>
                    )}
                    {detail?.achievement?.shopping_amount != null && (
                      <p>
                        Shopping Amount : {detail?.achievement?.shopping_amount}{" "}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Tab>
        <Tab eventKey="user_list" title="User List">
          {currentTab === "user_list" ? (
            <AchievementUserList
              constestStatus={detail?.achievement?.event_description}
            />
          ) : null}
        </Tab>
      </Tabs>
    </>
  )
}
export default AchievementDetails
