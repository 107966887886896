import { useEffect, useState } from "react"
import { Alert, Col, Row, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { ReportState } from "../ReportSlice"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import Select from "react-select"
import { getContestDropdown } from "../../contest/ContestAPI"
import AxiosInstance from "../../../utils/AxiosInstance"
import { useAlert } from "react-alert"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
import { getContestRoundDetails } from "../ReportAPI"
import { number } from "yup"

/**
 * Reporting list page
 */
const formatGroupLabel = (data) => (
  <div>
    <span>{data.label}</span>
    <span>{data.options.length}</span>
  </div>
)

const ReportList = () => {
  const { reports, status, totalRecord } = useAppSelector(ReportState)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [contestData, setContestData] = useState<any>([])
  const [selectedContestData, setSelectedContestData] = useState<any>("")
  const [selectedRoundData, setSelectedRoundData] = useState<any>({})
  const [reportData, setReportData] = useState<any>([])
  const [roundData, setRoundData] = useState<any>([])
  const dispatch = useAppDispatch()
  const alert = useAlert()
  const { ExportCSVButton } = CSVExport
  const { SearchBar, ClearSearchButton } = Search
  const [searchText, setSearchText] = useState("")
  const [selectedReportType, setSelectedReportType] = useState<any>({
    value: "",
    label: "Select Report Type",
  })
  const [showTable, setShowTable] = useState(false)

  const [allContests, setAllContests] = useState([])
  const [allRounds, setAllRounds] = useState<any>([])

  useEffect(() => {
    if (
      selectedReportType?.value == "Detailed" ||
      selectedReportType?.value == "Consolidated"
    ) {
      getContestDropdownData()
    }
  }, [selectedReportType])

  useEffect(() => {
    if (selectedReportType?.value == "Detailed") {
      if (selectedContestData && selectedContestData[0]?.value) {
        getRoundDropdownData()
      }
    } else {
      if (selectedContestData && selectedContestData?.value) {
        getRoundDropdownData()
      }
    }
  }, [selectedContestData])

  const getContestDropdownData = async () => {
    const res: any = await getContestDropdown()
    if (res?.data?.data.length > 0) {
      const opts: any = []
      if (selectedReportType?.value == "Detailed") {
        opts.push({
          value: "all",
          label: "All Contest",
        })
      }
      let AllContests = []
      for (let index = 0; index < res?.data?.data?.length; index++) {
        AllContests.push(res?.data?.data[index].contest_id)
        opts.push({
          value: res?.data?.data[index].contest_id,
          label: res?.data?.data[index].title.en
            ? res?.data?.data[index].title.en
            : res?.data?.data[index].title,
        })
      }
      setAllContests(AllContests)
      setContestData(opts)
    }
  }
  const getRoundDropdownData = async () => {
    let ContestData = []
    if (selectedReportType?.value == "Detailed") {
      for (let index = 0; index < selectedContestData.length; index++) {
        if (selectedContestData[index]?.value == "all") {
          ContestData = allContests
        }
      }
      if (ContestData.length == 0) {
        for (let index = 0; index < selectedContestData.length; index++) {
          ContestData.push(selectedContestData[index]?.value)
        }
      }
    } else {
      ContestData.push(selectedContestData?.value)
    }
    const prms = {
      contest_id: ContestData,
    }
    const res: any = await getContestRoundDetails(prms)
    if (res?.data?.data.length > 0) {
      const opts: any = []
      if (selectedReportType?.value == "Detailed") {
        opts.push({
          value: "all",
          label: "All Rounds",
        })
      }
      let AllRounds = []
      for (let index = 0; index < res?.data?.data?.length; index++) {
        let rounds = []
        for (var j = 0; j < res?.data?.data[index].ContestRounds.length; j++) {
          AllRounds.push(
            res?.data?.data[index].ContestRounds[j].contest_round_id
          )
          rounds.push({
            value: res?.data?.data[index].ContestRounds[j].contest_round_id,
            label: res?.data?.data[index].ContestRounds[j].title.en,
          })
        }

        opts.push({
          value: res?.data?.data[index].contest_round_id,
          label: res?.data?.data[index].title.en
            ? res?.data?.data[index].title.en
            : res?.data?.data[index].title,
          options: rounds,
        })
      }
      setAllRounds(AllRounds)
      setRoundData(opts)
    } else {
      alert.error("Data Not Found")
    }
  }
  const handleSubmit = async () => {
    let contest_id = []
    let round_id = []
    if (selectedReportType?.value == "Detailed") {
      for (let index = 0; index < selectedContestData.length; index++) {
        if (selectedContestData[index]?.value == "all") {
          contest_id = allContests
        }
      }
      if (contest_id.length == 0) {
        for (let index = 0; index < selectedContestData.length; index++) {
          contest_id.push(selectedContestData[index]?.value)
        }
      }
      for (let index = 0; index < selectedRoundData.length; index++) {
        if (selectedRoundData[index]?.value == "all") {
          round_id = allRounds
        }
      }
      if (round_id.length == 0) {
        for (let index = 0; index < selectedRoundData.length; index++) {
          round_id.push(selectedRoundData[index]?.value)
        }
      }
    } else {
      contest_id.push(selectedContestData?.value)
      round_id.push(selectedRoundData?.value)
    }

    let data = {
      contest_id: contest_id,
      contest_round_id: round_id,
      report_type: selectedReportType?.value,
    }

    const res: any = await AxiosInstance.post(
      `/reports/all-contest-result-report`,
      data
    )
    if (res?.data?.statusCode === 200 || res?.data?.statusCode === 201) {
      if (res.data?.data?.rows?.length == 0) {
        setReportData(res?.data?.data?.rows)
        alert.error("Data not Found")
        setShowTable(false)
      } else {
        setReportData(res?.data?.data?.rows)
        setShowTable(true)
      }
    } else {
      alert.error(res?.data?.data?.message)
    }
  }

  const docFormatter = (cell, row, rowIndex) => {
    if (cell == "" || cell == null || cell == "undefined") {
      return cell
    } else {
      return cell.toLocaleString()
    }
  }

  const reportType = [
    {
      label: "Detailed",
      value: "Detailed",
    },
    {
      label: "Consolidated",
      value: "Consolidated",
    },
  ]

  const columns = [
    {
      dataField: "game_sequence",
      text: "Game",
      sort: true,
    },
    {
      dataField: "contest_round_title.en",
      text: "Round",
      sort: true,
      formatter: (cell: any, row: any, rowIndex: any) => {
        return cell + " / " + row?.total_round
      },
    },
    {
      dataField: "user_nickname",
      text: "Username",
      sort: true,
      csvFormatter: (cell: any, row: any, rowIndex: any) => {
        if (
          row?.user_nickname == "undefined" ||
          row?.user_nickname == "" ||
          row?.user_nickname == null
        ) {
          return ""
        } else {
          return row?.user_nickname
        }
      },
    },
    {
      dataField: "team_name.en",
      text: "Participant Team / Player",
      sort: true,
      csvFormatter: (col, row, rowIndex) => {
        if (
          row?.team_name?.en == "undefined" ||
          row?.team_name?.en == "" ||
          row?.team_name?.en == null
        ) {
          return "Not Allocated"
        } else {
          return row?.team_name?.en
        }
      },
      formatter: (col, row, rowIndex) => {
        if (
          row?.team_name?.en == "undefined" ||
          row?.team_name?.en == "" ||
          row?.team_name?.en == null
        ) {
          return "Not Allocated"
        } else {
          return row?.team_name?.en
        }
      },
    },
    {
      dataField: "entries_allotted",
      text: "Entries Alloted",
      sort: true,
      sortFunc: (a: any, b: any, order: any, dataField: any) => {
        if (order === "asc") {
          return b - a
        }
        return a - b
      },
      formatter: docFormatter,
    },
    {
      dataField: "game_status",
      text: "Result",
      sort: true,
    },
    {
      dataField: "win_loss",
      text: "Win / Lose ",
      sort: true,
    },
    {
      dataField: "entries_carried_forward",
      text: "Entries Advancing",
      sort: true,
      sortFunc: (a: any, b: any, order: any, dataField: any) => {
        if (order === "asc") {
          return b - a
        }
        return a - b
      },
      formatter: docFormatter,
    },
    {
      dataField: "entries_lost",
      text: "Entries Lost",
      sort: true,
      sortFunc: (a: any, b: any, order: any, dataField: any) => {
        if (order === "asc") {
          return b - a
        }
        return a - b
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        if (row?.game_status == "Pending") {
          return ""
        }
        if (cell == "" || cell == null || cell == "undefined") {
          return cell
        } else {
          return cell.toLocaleString()
        }
      },
    },
  ]

  // const listData = reportData.filter((e: any) => {
  //   return (
  //     e.entries_allotted.toString().indexOf(searchText.toString()) >= 0 ||
  //     e.entries_carried_forward.toString().indexOf(searchText.toString()) >=
  //       0 ||
  //     e?.contest_title?.en?.toUpperCase().indexOf(searchText.toUpperCase()) >=
  //       0 ||
  //     e?.user_nickname?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
  //     e?.contest_round_title?.en
  //       ?.toUpperCase()
  //       .indexOf(searchText.toUpperCase()) >= 0
  //   )
  // })
  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={reportData}
        columns={columns}
        search
        exportCSV
      >
        {(props: any) => (
          <>
            <Row>
              <div className="col-md-3">
                <div className="col-md-12">
                  <p className="white_color mt-4 mb-0">Report Type</p>
                </div>
                <Select
                  name="Report Type"
                  placeholder="Select Report Type"
                  id="reportType"
                  value={selectedReportType}
                  className="custom-select-multi"
                  options={reportType}
                  onChange={(e) => {
                    setSelectedReportType(e)
                    setSelectedRoundData([])
                    setSelectedContestData([])
                    setShowTable(false)
                  }}
                />
              </div>
              {selectedReportType && selectedReportType?.value == "Detailed" ? (
                <div className="col-md-3">
                  <div className="col-md-12">
                    <p className="white_color mt-4 mb-0">Contest</p>
                  </div>
                  <Select
                    name="contest"
                    placeholder="Select Contest"
                    id="contest"
                    isMulti
                    className="custom-select-multi"
                    options={contestData}
                    onChange={(e) => {
                      setSelectedContestData(e)
                      setRoundData({})
                      setSelectedRoundData([])
                      setShowTable(false)
                      // setSearchText("")
                    }}
                  />
                </div>
              ) : null}
              {selectedReportType &&
              selectedReportType?.value == "Consolidated" ? (
                <div className="col-md-3">
                  <div className="col-md-12">
                    <p className="white_color mt-4 mb-0">Contest</p>
                  </div>
                  <Select
                    name="contest"
                    placeholder="Select Contest"
                    value={selectedContestData}
                    id="contest"
                    className="custom-select-multi"
                    options={contestData}
                    onChange={(e) => {
                      setSelectedContestData(e)
                      setRoundData([])
                      setSelectedRoundData([])
                      setShowTable(false)
                      // setSearchText("")
                    }}
                  />
                </div>
              ) : null}
              {selectedContestData &&
              selectedContestData[0]?.value &&
              selectedReportType?.value == "Detailed" ? (
                <div className="col-md-3">
                  <div className="col-md-12">
                    <p className="white_color mt-4 mb-0">Round</p>
                  </div>
                  <Select
                    name="round"
                    placeholder="Select Round"
                    id="round"
                    value={selectedRoundData}
                    isMulti
                    className="custom-select-multi"
                    options={roundData}
                    onChange={(e) => {
                      setSelectedRoundData(e)
                      setShowTable(false)
                    }}
                  />
                </div>
              ) : null}

              {selectedReportType &&
              selectedReportType?.value == "Consolidated" &&
              selectedContestData &&
              selectedContestData?.value ? (
                <div className="col-md-3">
                  <div className="col-md-12">
                    <p className="white_color mt-4 mb-0">Round</p>
                  </div>
                  <Select
                    name="round"
                    placeholder="Select Round"
                    value={selectedRoundData}
                    id="round"
                    className="custom-select-multi"
                    options={roundData}
                    onChange={(e) => {
                      setSelectedRoundData(e)
                    }}
                  />
                </div>
              ) : null}

              <div className="col-md-3">
                {selectedContestData &&
                (selectedContestData[0]?.value || selectedContestData?.value) &&
                selectedRoundData &&
                (selectedRoundData[0]?.value || selectedRoundData?.value) ? (
                  <div className="pt-4">
                    <button
                      className="btn btn-primary mt-4"
                      onClick={() => {
                        handleSubmit()
                      }}
                    >
                      Show Data
                    </button>
                    {showTable ? (
                      <ExportCSVButton
                        {...props.csvProps}
                        className="btn-success fw-bold ms-2 mt-4"
                      >
                        Export CSV
                      </ExportCSVButton>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Row>
            <Row>
              <div className="pt-4">
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setSelectedContestData({})
                    // setSearchText("")
                    setRoundData({})
                    setSelectedRoundData([])
                    setSelectedContestData([])
                    setSelectedReportType({
                      label: "Select Round Data",
                      value: "",
                    })
                  }}
                >
                  Clear
                </button>
              </div>
            </Row>
            <Row className="mt-5">
              {selectedContestData &&
              (selectedContestData[0]?.value || selectedContestData?.value) &&
              selectedRoundData &&
              (selectedRoundData[0]?.value || selectedRoundData?.value) &&
              showTable ? (
                <Row>
                  <div>
                    <SearchBar {...props.searchProps} className="mb-3" />
                    <ClearSearchButton
                      {...props.searchProps}
                      className="btn-primary fw-bold ms-2"
                    />
                  </div>
                  <BootstrapTable
                    filter={filterFactory()}
                    pagination={paginationFactory(options)}
                    {...props.baseProps}
                  />
                </Row>
              ) : null}
            </Row>
          </>
        )}
      </ToolkitProvider>
    </>
  )
}
export default ReportList
