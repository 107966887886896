import AxiosInstance from "../../utils/AxiosInstance";
import { WebHomeDetailInterface } from "./models/WebHomeDetailInterface";

export const getWebHomeList = async (params : any) => {
  // const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/home`);
};

export const getWebHomeDetail = async (index: string) => {
  return await AxiosInstance.get(`/home/get-by-home-id/${index}`);
};

export const createWebHome = async (data: WebHomeDetailInterface) => {
  return await AxiosInstance.post(`/home/create`, data);
};

export const deleteWebHome = async (index: string) => {
  return await AxiosInstance.delete(`/home/delete-by-id/${index}`);
};
export const updateWebHome = async (index: string, data: WebHomeDetailInterface) => {
  return await AxiosInstance.put(`/home/update`, data);
};
