const ModuleConfig = {
  primaryKey: "id",

  formDefaultValue: {
    title: "",
    description: "",
    image: ""
  },

  formFields: {
    title: {
      label: "Title",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength:"50"
    },

    description: {
      label: "Description",
      isRequired: true,
      colWidth: "col-md-6",
      type: "textarea",
      inputType: "textarea",
      maxLength:"300"
    },

    image: {
      label: "Image",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
      inputType: "file",
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
};

export default ModuleConfig;
