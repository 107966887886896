import AxiosInstance from "../../utils/AxiosInstance"
import { ContestDetailInterface } from "./models/ContestDetailInterface"

const API_ROUTE = "contests"
export const getContestList = async (params: any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/contest?${prm}`)
}
export const getContestDropdown = async () => {
  return await AxiosInstance.get(`/contest/report-dropdown`)
}
export const getContestDetail = async (index: string) => {
  return await AxiosInstance.get(`/contest/get-by-id/${index}`)
}
export const getContestRoundDetail = async (index: string) => {
  return await AxiosInstance.get(`/contest-round/get-by-contest-id/${index}`)
}

export const createContest = async (data: ContestDetailInterface) => {
  return await AxiosInstance.post(`/contest/create`, data)
}

export const winnerDeclare = async (data: any) => {
  return await AxiosInstance.put(`/contest-round-game/status-declare`, data)
}

export const deleteContest = async (index: string) => {
  return await AxiosInstance.delete(`/contest/delete-by-id/${index}`)
}

export const updateContest = async (
  index: string,
  data: ContestDetailInterface
) => {
  return await AxiosInstance.put(`/contest/update`, data)
}
