import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../stores"
import { PodcastDetailInterface } from "./models/PodcastDetailInterface"
import {
  createPodcast,
  deletePodcast,
  getPodcastDetail,
  getPodcastList,
  updatePodcast,
} from "./PodcastAPI"

export interface PodcastStateInterface {
  podcasts: PodcastDetailInterface[]
  favourites: PodcastDetailInterface[]
  totalRecord: number
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  detail: PodcastDetailInterface
}

const initialState: PodcastStateInterface = {
  podcasts: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    podcast_id: "",
    title: "",
    description: "",
    credit: null,
    language_id: "",
    author: "",
    audio_link: "",
    sport_id: "",
    Sport: {},
    Language: {},
  },
}

export const getPodcastListAsync = createAsyncThunk(
  "podcast/list",
  async (data: any) => {
    const response = await getPodcastList(data)
    return response.data
  }
)
export const getPodcastDetailAsync = createAsyncThunk(
  "podcast/detail",
  async (index: string) => {
    const response = await getPodcastDetail(index)
    return response?.data
  }
)

export const createPodcastAsync = createAsyncThunk(
  "podcast/create",
  async (data: PodcastDetailInterface) => {
    const response = await createPodcast(data)
    return response.data
  }
)

export const deletePodcastAsync = createAsyncThunk(
  "podcast/delete",
  async (index: string) => {
    const response = await deletePodcast(index)
    return response.data
  }
)

export const updatePodcastAsync = createAsyncThunk(
  "podcast/update",
  async (data: { index: string; data: PodcastDetailInterface }) => {
    const response = await updatePodcast(data.index, data.data)
    return response.data
  }
)

export const podcastSlice = createSlice({
  name: "podcast",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getPodcastListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getPodcastListAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.podcasts = action.payload.data
        state.totalRecord = action.payload.total
        state.detail = {}
      })
      .addCase(getPodcastListAsync.rejected, (state) => {
        state.status = "failed"
      })
      // GET DETAIL
      .addCase(getPodcastDetailAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getPodcastDetailAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.detail = action.payload.data
      })
      .addCase(getPodcastDetailAsync.rejected, (state) => {
        state.status = "failed"
      })
      // CREATE
      .addCase(createPodcastAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(createPodcastAsync.fulfilled, (state, action) => {
        state.status = "created"
        state.detail = action.payload
      })
      .addCase(createPodcastAsync.rejected, (state) => {
        state.status = "failed"
      })
      // UPDATE
      .addCase(updatePodcastAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updatePodcastAsync.fulfilled, (state, action) => {
        state.status = "updated"
        state.detail = action.payload
      })
      .addCase(updatePodcastAsync.rejected, (state) => {
        state.status = "failed"
      })
      // DELETE
      .addCase(deletePodcastAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deletePodcastAsync.fulfilled, (state, action) => {
        state.status = "deleted"
        state.detail = action.payload
      })
      .addCase(deletePodcastAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const {} = podcastSlice.actions
export const PodcastState = (state: RootState) => state.podcast
export default podcastSlice.reducer
