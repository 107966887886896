import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getContestListAsync, ContestState } from "../ContestSlice"
import { deleteContest } from "../ContestAPI"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import { DropDownButton } from "@progress/kendo-react-buttons"
import { Field } from "formik"

/**
 * Contesting list page
 */
const ContestList = () => {
  const { contests, status, totalRecord } = useAppSelector(ContestState)
  const [page, setPage] = useState(1)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [searchText, setSearchText] = useState("")
  const [searchStatus, setSearchStatus] = useState("")

  const [deleteItem, setDeleteItem] = useState("")

  useEffect(() => {
    getPageData()
  }, [dispatch, page])

  const getPageData = () => {
    const filter = {
      page: page,
    }
    dispatch(getContestListAsync(filter))
  }

  const removeContest = async () => {
    const res = await deleteContest(deleteItem)
    setDeleteItem("")
    getPageData()
  }

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  )

  const options = {
    sizePerPage: 10,
    paginationSize: 5,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: totalRecord,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const columns = [
    {
      dataField: "Sport.sport_name.en",
      text: "Sport",
      sort: true,
    },
    {
      dataField: "title.en",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description.en",
      text: "Description",
      sort: true,
    },
    {
      dataField: "number_of_round",
      text: "Number of Round",
      sort: true,
      classes: "col-1",
    },
    {
      dataField: "ContestCredits.credit_sum",
      text: "Total Allocated Credits",
      classes: "col-2",
      formatter: (cell, row, rowdata) => {
        if (cell == "" || cell == "undefined" || cell == null) {
          return 0
        } else {
          return parseInt(cell).toLocaleString()
        }
      },
      sort: true,
      sortValue: (cell, row) => {
        if (cell == "" || cell == "undefined" || cell == null) {
          return 0
        } else {
          return parseInt(cell)
        }
      },
    },
    {
      dataField: "ContestCredits.total_credit",
      text: "Total Contest Credits",
      sort: true,
      classes: "col-2",
      formatter: (cell, row, rowdata) => {
        if (cell == "" || cell == "undefined" || cell == null) {
          return 0
        } else {
          return parseInt(cell).toLocaleString()
        }
      },
      sortValue: (cell, row) => {
        if (cell == "" || cell == "undefined" || cell == null) {
          return 0
        } else {
          return parseInt(cell)
        }
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      align: "center",
      classes: "col-2",
      formatter: (cellContent: any, row: any) =>
        row.status == "Draft" ? (
          <span className="label label-info rounded-pill"> Draft </span>
        ) : row.status == "Open" ? (
          <span className="label label-success rounded-pill"> Open </span>
        ) : row.status == "In progress" ? (
          <span className="label label-primary rounded-pill">
            {" "}
            In Progress{" "}
          </span>
        ) : row.status == "Close" ? (
          <span className="label label-danger rounded-pill"> Close </span>
        ) : (
          ""
        ),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      classes: "col-1",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex justify-content-around">
          {row?.status === "Draft" ? (
            <i
              className="feather icon-trash-2 text-danger fw-bold"
              onClick={() => {
                setDeleteItem(row.contest_id)
              }}
            ></i>
          ) : null}
          <i
            className="feather icon-edit text-success fw-bold"
            onClick={() => {
              navigate(`/contest/update/${row.contest_id}`)
            }}
          ></i>
        </div>
      ),
    },
  ]

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const listData = contests.filter((e: any) => {
    return (
      (e.title?.en?.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
        e.Sport.sport_name.en
          ?.toUpperCase()
          .indexOf(searchText.toUpperCase()) >= 0 ||
        e.description.en?.toUpperCase().indexOf(searchText.toUpperCase()) >=
          0 ||
        e.ContestCredits?.credit_sum
          ?.toString()
          .indexOf(searchText.toString()) >= 0 ||
        e.ContestCredits?.total_credit
          ?.toString()
          .indexOf(searchText.toString()) >= 0) &&
      (searchStatus == "" || e.status == searchStatus)
    )
  })
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Contest</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Contests</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Contests</h5>
                    </Col>
                    <Col md={2}>
                      <Link className="btn btn-primary" to="/contest/create">
                        Add Contest
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  {status === "loading" ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : status === "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      Something went wrong please try again
                    </Alert>
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="id"
                        data={listData}
                        columns={columns}
                        selectRow={selectRow}
                        // search
                        exportCSV
                      >
                        {(props: any) => {
                          return (
                            <>
                              <Row className="mb-3">
                                <Col>
                                  <input
                                    placeholder="Search"
                                    type={"text"}
                                    value={searchText}
                                    className="form-control"
                                    onChange={(e) => {
                                      setSearchText(e.target.value)
                                    }}
                                  />
                                </Col>
                                <Col>
                                  {" "}
                                  <select
                                    className="form-control "
                                    onChange={(e) => {
                                      setSearchStatus(e.target.value)
                                    }}
                                  >
                                    <option
                                      selected={searchStatus == ""}
                                      value=""
                                    >
                                      Select Status
                                    </option>
                                    <option
                                      selected={searchStatus == "In progress"}
                                    >
                                      In progress
                                    </option>
                                    <option selected={searchStatus == "Open"}>
                                      Open
                                    </option>
                                    <option selected={searchStatus == "Close"}>
                                      Close
                                    </option>
                                    <option selected={searchStatus == "Draft"}>
                                      Draft
                                    </option>
                                  </select>
                                </Col>
                                <Col>
                                  <button
                                    onClick={() => {
                                      setSearchStatus("")
                                      setSearchText("")
                                    }}
                                    className="btn btn-primary"
                                  >
                                    Clear
                                  </button>
                                </Col>
                              </Row>
                              <Row>
                                <BootstrapTable
                                  filter={filterFactory()}
                                  pagination={paginationFactory(options)}
                                  {...props.baseProps}
                                />
                              </Row>
                            </>
                          )
                        }}
                      </ToolkitProvider>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteItem ? (
        <Modal show={true} onHide={() => setDeleteItem("")}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete Contest?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setDeleteItem("")}>
              Close
            </Button>
            <Button
              className="btn-danger"
              variant="primary"
              onClick={removeContest}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}
export default ContestList
