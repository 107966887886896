const ModuleConfig = {
  primaryKey: "contest_round_game_id",

  formDefaultValue: {
    contest_round_id: "",
    teams: "",
    winner_team_id: null,
    status: "",
    progress_status: "",
    place_name: "",
    game_time: "",
    open_till: "",
  },

  formFields: {
    teams: {
      label: "Teams",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "multiselect",
      type: "multiselect",
      placeholder: "Select Teams",
      asynchLoad: true,
      dataUrl: "teams/drop-down",
      keyValue: "team_id",
      keylabel: "name",
      options: [],
    },

    status: {
      label: "Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "Result",
          value: "Result",
        },
        {
          label: "No Result",
          value: "No result",
        },
      ],
    },

    progress_status: {
      label: "Progress Status",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Progress Status",
      options: [
        {
          label: "Draft",
          value: "Draft",
        },
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "Close",
          value: "Close",
        },
      ],
    },

    game_time: {
      label: "Date/Time",
      isRequired: true,
      colWidth: "col-md-6",
      type: "date",
      inputType: "datepicker",
    },
    open_till: {
      label: "Open For Allocation Till.",
      isRequired: true,
      colWidth: "col-md-6",
      type: "date",
      inputType: "datepicker",
    },
    place_name: {
      label: "Place Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength:"50"
    },
  },

  listColumnOrder: ["team1_id", "team2_id", "status", "progress_status","place_name","game_time"],
}

export default ModuleConfig
