import { Route } from "react-router-dom";
import TeamCreate from "./pages/TeamCreate";
import TeamList from "./pages/TeamList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "team"; 
const TeamRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<TeamList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<TeamCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:team_id`} element={<TeamCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default TeamRoute;