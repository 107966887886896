import { Button, Container, Row, Table } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getUserDetailAsync, UserState } from "../UserSlice"
import { Formik } from "formik"
import { UserDetailInterface } from "../models/UserDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect, useState } from "react"
import { createUser, updateUser } from "../UserAPI"

import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"
import { useAlert } from "react-alert"

/**
 * Usering Favourites list page
 */
const UserCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(UserState)
  const params = useParams()
  const navigator = useNavigate()
  const alert = useAlert()
  const [formData, setFormData] = useState({
    user_id: "",
    nick_name: "",
    email: "",
    password: "",
    status: "",
    media_credit: "",
    profile_image: "",
    phone: "",
    _2fa: true,
    created_at: null,
    birth_year: null,
  })

  useEffect(() => {
    setFormData({
      user_id: detail.user_id,
      nick_name: detail.nick_name,
      email: detail.email,
      password: detail.password,
      status: detail.status,
      media_credit: detail.media_credit,
      profile_image: detail.profile_image,
      phone: detail.phone == null ? "" : detail.phone,
      _2fa: detail?._2fa,
      created_at: new Date(detail?.created_at).toDateString(),
      birth_year: detail.birth_year == null ? "" : detail.birth_year,
    })
  }, [detail])

  useEffect(() => {
    if (params.user_id) {
      dispatch(getUserDetailAsync(params.user_id))
    }
  }, [params.user_id])

  const handleSubmit = async (values: UserDetailInterface, formMethod: any) => {
    if (params.user_id) {
      const res: any = await updateUser(params.user_id, values)
      if (res.error && res.message) {
        alert.error(res.message)
        const errors: any = {}
        const errorKeys = Object.keys(res.error)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.error[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        navigator("/users")
      }
    } else {
      const res: any = await createUser(values)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        navigator("/users")
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <ToastContainer />
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">User</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users">Users</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.user_id ? "Edit " : "Add "}User</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.user_id ? "Edit " : "Add "}User</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={
                      detail[ModuleConfig.primaryKey] == params.user_id
                        ? formData
                        : ModuleConfig.formDefaultValue
                    }
                    validate={(values) => {
                      const errors: any = {}
                      const fields = Object.keys(ModuleConfig.formFields)
                      for (let index = 0; index < fields.length; index++) {
                        if (
                          !values[fields[index]] &&
                          !(
                            values[fields[index]] == "0" ||
                            values[fields[index]] == "1"
                          ) &&
                          ModuleConfig.formFields[fields[index]].isRequired
                        ) {
                          errors[fields[index]] = "Required"
                        }
                      }

                      return errors
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {Object.keys(ModuleConfig.formFields).map(
                            (field: string, index: number) => {
                              const fieldDetails =
                                ModuleConfig.formFields[field]
                              return (fieldDetails.isAdd == false &&
                                params.user_id) ||
                                fieldDetails.isAdd == undefined ? (
                                <div
                                  key={`field_${index}`}
                                  className={fieldDetails.colWidth}
                                >
                                  <FormField
                                    name={field}
                                    accept="image/jpeg , image/png"
                                    fieldDetails={fieldDetails}
                                    disabled={fieldDetails?.disabled}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                  />
                                </div>
                              ) : null
                            }
                          )}
                        </div>
                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UserCreate
