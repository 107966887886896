import { useEffect } from "react"
import { Spinner, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"
import { appState, getDashboardAsync } from "../stores/appSlice"
import { useAppDispatch, useAppSelector } from "../stores/hooks"

const Dashboard = () => {
  const { status, dashboard } = useAppSelector(appState)
  const dispatchApp = useAppDispatch()
  // const navigate = useNavigate()

  useEffect(() => {
    // if(!localStorage.token){
    //   navigate("/signin")
    // }
    dispatchApp(getDashboardAsync())
  }, [])

  useEffect(() => {}, [dashboard])

  const thisWeekNewUsers = dashboard?.current_week?.new_user
    ? dashboard?.current_week?.new_user
    : 0
  const thisWeekContestCreditEliminated = dashboard?.current_week
    ?.contest_credit_eliminated
    ? dashboard?.current_week?.contest_credit_eliminated
    : 0
  const thisWeekContestCreditOutstanding = dashboard?.current_week
    ?.contest_credit_outstanding
    ? dashboard?.current_week?.contest_credit_outstanding
    : 0
  // const thisWeekTotalMediaCreditPurchased = dashboard?.current_week
  //   ?.media_credit_purchased
  //   ? dashboard?.current_week?.media_credit_purchased
  //   : 0
  // const thisWeekTotalMediaCreditUsed = dashboard?.current_week
  //   ?.media_credit_used
  //   ? dashboard?.current_week?.media_credit_used
  //   : 0
  const thisWeekActiveSponsers = dashboard?.current_week?.active_sponsorship
    ? dashboard?.current_week?.active_sponsorship
    : 0
  const thisWeekPendingSponsers = dashboard?.current_week?.pending_sponsorship
    ? dashboard?.current_week?.pending_sponsorship
    : 0
  const lastWeekNewUsers = dashboard?.last_week?.new_user
    ? dashboard?.last_week?.new_user
    : 0
  const lastWeekContestCreditEliminated = dashboard?.last_week
    ?.contest_credit_eliminated
    ? dashboard?.last_week?.contest_credit_eliminated
    : 0
  const lastWeekContestCreditOutstanding = dashboard?.last_week
    ?.contest_credit_outstanding
    ? dashboard?.last_week?.contest_credit_outstanding
    : 0
  // const lastWeekTotalMediaCreditPurchased = dashboard?.last_week
  //   ?.media_credit_purchased
  //   ? dashboard?.last_week?.media_credit_purchased
  //   : 0
  // const lastWeekTotalMediaCreditUsed = dashboard?.last_week?.media_credit_used
  //   ? dashboard?.last_week?.media_credit_used
  //   : 0
  const lastWeekActiveSponsers = dashboard?.last_week?.active_sponsorship
    ? dashboard?.last_week?.active_sponsorship
    : 0
  const lastWeekPendingSponsers = dashboard?.last_week?.pending_sponsorship
    ? dashboard?.last_week?.pending_sponsorship
    : 0

  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  })

  return (
    <div className="pcoded-wrapper">
      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              {status === "loading" ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : status === "failed" ? (
                <Alert key={"danger"} variant={"danger"}>
                  Something went wrong please try again
                </Alert>
              ) : (
                <>
                  {/* [ Main Content ] start  */}
                  <div className="row">
                    {/*[ daily sales section ] start */}
                    <div className="col-md-6 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card daily-sales">
                          <div className="card-block">
                            <h6 className="mb-4">Total Active Users</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  {dashboard?.current_week?.active_user?.toLocaleString()}{" "}
                                </h3>
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/*[ daily sales section ] end */}

                    <div className="col-md-6 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">
                              Total Media Credits Purchased
                            </h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  <span>
                                    {dashboard?.total?.media_credit_purchased?.toLocaleString()}{" "}
                                  </span>
                                </h3>
                              </div>
                              <div className="col-3 text-right">
                                {/* <p className="m-b-0 text-muted">80%</p> */}
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-6 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">Total Media Credits Used</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  <span>
                                    {dashboard?.total?.media_credit_used?.toLocaleString()}{" "}
                                  </span>
                                </h3>
                              </div>
                              <div className="col-3 text-right"></div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-6 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card daily-sales">
                          <div className="card-block">
                            <h6 className="mb-4">
                              Amounts Won In Contests All-Time
                            </h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center m-b-0">
                                  <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                  {dashboard?.total?.winners_amount?.toLocaleString()}{" "}
                                </h3>
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-6 col-xl-4">
                      <Link to="/users" className="text-decoration-none">
                        <div className="card daily-sales">
                          <div className="card-block">
                            <h6 className="mb-4">New Users</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center m-b-0">
                                  <i
                                    className={
                                      thisWeekNewUsers < lastWeekNewUsers
                                        ? "feather icon-arrow-down text-c-green f-30 m-r-10"
                                        : "feather icon-arrow-up text-c-green f-30 m-r-10"
                                    }
                                  ></i>
                                  <span>
                                    {thisWeekNewUsers?.toLocaleString()}
                                  </span>
                                  {` / `}{" "}
                                  <span className="lastWeekData">
                                    {lastWeekNewUsers?.toLocaleString()}
                                  </span>
                                </h3>
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-6 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">
                              Outstanding Contest Credits
                            </h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i
                                    className={
                                      thisWeekContestCreditOutstanding <
                                      lastWeekContestCreditOutstanding
                                        ? "feather icon-arrow-down text-c-green f-30 m-r-10"
                                        : "feather icon-arrow-up text-c-green f-30 m-r-10"
                                    }
                                  ></i>
                                  <span>
                                    {thisWeekContestCreditOutstanding?.toLocaleString()}
                                  </span>
                                  {" / "}{" "}
                                  <span className="lastWeekData">
                                    {lastWeekContestCreditOutstanding?.toLocaleString()}
                                  </span>
                                </h3>
                              </div>
                              <div className="col-3 text-right">
                                {/* <p className="m-b-0">80%</p> */}
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-6 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">
                              Eliminated Contest Credits{" "}
                            </h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i
                                    className={
                                      thisWeekContestCreditEliminated <
                                      lastWeekContestCreditEliminated
                                        ? "feather icon-arrow-down text-c-green f-30 m-r-10"
                                        : "feather icon-arrow-up text-c-green f-30 m-r-10"
                                    }
                                  ></i>
                                  <span>
                                    {thisWeekContestCreditEliminated?.toLocaleString()}
                                  </span>
                                  {" / "}{" "}
                                  <span className="lastWeekData">
                                    {lastWeekContestCreditEliminated?.toLocaleString()}
                                  </span>
                                </h3>
                              </div>
                              <div className="col-3 text-right">
                                {/* <p className="m-b-0">80%</p> */}
                              </div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "1000%" }}
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-12 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">Active Sponsorships</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i
                                    className={
                                      thisWeekActiveSponsers <
                                      lastWeekActiveSponsers
                                        ? "feather icon-arrow-down text-c-green f-30 m-r-10"
                                        : "feather icon-arrow-up text-c-green f-30 m-r-10"
                                    }
                                  ></i>
                                  <span>
                                    {thisWeekActiveSponsers?.toLocaleString()}
                                  </span>
                                  {" / "}{" "}
                                  <span className="lastWeekData">
                                    {lastWeekActiveSponsers?.toLocaleString()}
                                  </span>
                                </h3>
                              </div>
                              <div className="col-3 text-right"></div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-6 col-xl-4">
                      <Link to="/" className="text-decoration-none">
                        <div className="card yearly-sales">
                          <div className="card-block">
                            <h6 className="mb-4">Pending Sponsorships</h6>
                            <div className="row d-flex align-items-center">
                              <div className="col-9">
                                <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                                  <i
                                    className={
                                      thisWeekPendingSponsers <
                                      lastWeekPendingSponsers
                                        ? "feather icon-arrow-down text-c-green f-30 m-r-10"
                                        : "feather icon-arrow-up text-c-green f-30 m-r-10"
                                    }
                                  ></i>
                                  <span>
                                    {thisWeekPendingSponsers?.toLocaleString()}
                                  </span>
                                  {" / "}{" "}
                                  <span className="lastWeekData">
                                    {lastWeekPendingSponsers?.toLocaleString()}
                                  </span>
                                </h3>
                              </div>
                              <div className="col-3 text-right"></div>
                            </div>
                            <div
                              className="progress m-t-30"
                              style={{ height: "7px" }}
                            >
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
