import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../stores"
import { WebHomeDetailInterface } from "./models/WebHomeDetailInterface"
import {
  createWebHome,
  deleteWebHome,
  getWebHomeDetail,
  getWebHomeList,
  updateWebHome,
} from "./WebHomeAPI"

export interface WebHomeStateInterface {
  webhomes: WebHomeDetailInterface[]
  favourites: WebHomeDetailInterface[]
  totalRecord: number
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  detail: WebHomeDetailInterface
}

const initialState: WebHomeStateInterface = {
  webhomes: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    home_id: "",
    cms_id: "",
    contest_id: "",
    media_credit_title: "",
    media_credit_description: "",
    contest_title: "",
    contest_description: "",
    welcome_title: "",
    section3_title: "",
    section3_description: "",
    section3_image: [],
    contact_us: "",
    contact_us_image: "",
    language_id: "",
    section3_bottom_text: "",
    section2_title: "",
  },
}

export const getWebHomeListAsync = createAsyncThunk(
  "home",
  async (data: any) => {
    const response = await getWebHomeList(data)
    return response.data
  }
)
export const getWebHomeDetailAsync = createAsyncThunk(
  "/home/get-by-id/:id",
  async (index: string) => {
    const response = await getWebHomeDetail(index)
    return response.data.data
  }
)

export const createWebHomeAsync = createAsyncThunk(
  "home/create",
  async (data: WebHomeDetailInterface) => {
    const response = await createWebHome(data)
    return response.data
  }
)

export const deleteWebHomeAsync = createAsyncThunk(
  "home/delete",
  async (index: string) => {
    const response = await deleteWebHome(index)
    return response.data
  }
)

export const updateWebHomeAsync = createAsyncThunk(
  "home/update",
  async (data: { index: string; data: WebHomeDetailInterface }) => {
    const response = await updateWebHome(data.index, data.data)
    return response.data
  }
)

export const webhomeSlice = createSlice({
  name: "webhome",
  initialState,
  reducers: {

    resetDetailData:(state, action) =>{
      state.detail = {}
    }
  },
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getWebHomeListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getWebHomeListAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.webhomes = action.payload.data
        state.totalRecord = action.payload.total
      })
      .addCase(getWebHomeListAsync.rejected, (state) => {
        state.status = "failed"
      })
      // GET DETAIL
      .addCase(getWebHomeDetailAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getWebHomeDetailAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.detail = action.payload
      })
      .addCase(getWebHomeDetailAsync.rejected, (state) => {
        state.status = "failed"
      })
      // CREATE
      .addCase(createWebHomeAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(createWebHomeAsync.fulfilled, (state, action) => {
        state.status = "created"
        state.detail = action.payload
      })
      .addCase(createWebHomeAsync.rejected, (state) => {
        state.status = "failed"
      })
      // UPDATE
      .addCase(updateWebHomeAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updateWebHomeAsync.fulfilled, (state, action) => {
        state.status = "updated"
        state.detail = action.payload
      })
      .addCase(updateWebHomeAsync.rejected, (state) => {
        state.status = "failed"
      })
      // DELETE
      .addCase(deleteWebHomeAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(deleteWebHomeAsync.fulfilled, (state, action) => {
        state.status = "deleted"
        state.detail = action.payload
      })
      .addCase(deleteWebHomeAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const {resetDetailData} = webhomeSlice.actions
export const WebHomeState = (state: RootState) => state.webhome
export default webhomeSlice.reducer
