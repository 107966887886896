const ModuleConfig = {
  primaryKey: "sponsorship_id",

  formDefaultValue: {
    company_name: "",
    email: "",
    contact_name: "",
    website: "",
    phone_number: "",
    type_of_sponsor: "",
    company_offering: "",
    budget: "",
    logo: "",
  },

  formFields: {
    company_name: {
      label: "Company Name",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },

    email: {
      label: "Contact Email Address",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "email",
      maxLength: "50",
    },

    contact_name: {
      label: "Company Contact(Name)",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },

    website: {
      label: "Website Link",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      // pattern: "https?://.+",
      maxLength: "50",
    },

    phone_number: {
      label: "Contact Phone Number",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "number",
      min: 1,
      max: 99999999999,
    },

    type_of_sponsor: {
      label: "Type Of Contest You'd Like to Sponsor",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Type Of Contest",
      options: [
        {
          label: "Media Content",
          value: "Media content",
        },
        {
          label: "Contests",
          value: "Contests",
        },
        {
          label: "Both",
          value: "Both",
        },
      ],
    },

    budget: {
      label: "Budget for Sponsorship(CAD)",
      isRequired: false,
      colWidth: "col-md-6",
      inputType: "text",
      type: "number",
      min: 1,
      max: 999999,
    },

    logo: {
      label: "Logo",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
      inputType: "file",
    },

    status: {
      label: "Status",
      isRequired: false,
      disabled: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Status",
      options: [
        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "Active",
          value: "Active",
        },
        {
          label: "In active",
          value: "In active",
        },
      ],
    },

    company_offering: {
      label: "Company Offering",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "textarea",
      type: "textarea",
      maxLength: "300",
    },
  },

  listColumnOrder: ["firstName", "lastName", "email"],
}

export default ModuleConfig
