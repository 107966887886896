import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { SponsorshipDetailInterface } from "./models/SponsorshipDetailInterface";
import {
  createSponsorship,
  deleteSponsorship,
  getSponsorshipDetail,
  getSponsorshipList,
  updateSponsorship,
} from "./SponsorshipAPI";

export interface SponsorshipStateInterface {
  sponsorships: SponsorshipDetailInterface[];
  favourites: SponsorshipDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: SponsorshipDetailInterface;
}

const initialState: SponsorshipStateInterface = {
  sponsorships: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    sponsorship_id: "",
    company_name :  "",
    email :  "",
    contact_name :  "",
    website :  "",
    phone_number : "",
    type_of_sponsor :  "",
    company_offering :  "",
    logo :  "",
    budget : "",
  },
};

export const getSponsorshipListAsync = createAsyncThunk(
  "sponsorship/list",
  async (data: any) => {
    const response = await getSponsorshipList(data);
    return response?.data;
  }
);
export const getSponsorshipDetailAsync = createAsyncThunk(
  "sponsorship/detail",
  async (index: string) => {
    const response = await getSponsorshipDetail(index);
    return response.data.data;
  }
);

export const createSponsorshipAsync = createAsyncThunk(
  "sponsorship/create",
  async (data: SponsorshipDetailInterface) => {
    const response = await createSponsorship(data);
    return response.data;
  }
);

export const deleteSponsorshipAsync = createAsyncThunk(
  "sponsorship/delete",
  async (index: string) => {
    const response = await deleteSponsorship(index);
    return response.data;
  }
);

export const updateSponsorshipAsync = createAsyncThunk(
  "sponsorship/update",
  async (data: { index: string; data: SponsorshipDetailInterface }) => {
    const response = await updateSponsorship(data.index, data.data);
    return response.data;
  }
);

export const sponsorshipSlice = createSlice({
  name: "sponsorship",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getSponsorshipListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSponsorshipListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.sponsorships = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getSponsorshipListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getSponsorshipDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSponsorshipDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getSponsorshipDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createSponsorshipAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSponsorshipAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createSponsorshipAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateSponsorshipAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSponsorshipAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateSponsorshipAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteSponsorshipAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSponsorshipAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteSponsorshipAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = sponsorshipSlice.actions;
export const SponsorshipState = (state: RootState) => state.sponsorship;
export default sponsorshipSlice.reducer;
