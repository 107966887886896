import { Route } from "react-router-dom";
import MediaGalleryCreate from "./pages/MediaGalleryCreate";
import MediaGalleryList from "./pages/MediaGalleryList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "mediagallery"; 
const MediaGalleryRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<MediaGalleryList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<MediaGalleryCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<MediaGalleryCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default MediaGalleryRoute;