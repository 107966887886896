import { Button, Container, Row, Table } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getTeamDetailAsync, TeamState } from "../TeamSlice"
import { Field, FieldArray, Formik } from "formik"
import { TeamDetailInterface } from "../models/TeamDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect, useState } from "react"
import { createTeam, updateTeam } from "../TeamAPI"
import FileInput from "./../../common/FormField/FileInput"
import { appState } from "../../../stores/appSlice"
import { useAlert } from "react-alert"

/**
 * Teaming Favourites list page
 */
const TeamCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(TeamState)
  const { languages } = useAppSelector(appState)

  const params = useParams()
  const navigator = useNavigate()
  const alert = useAlert()

  const [defaultValus, setDefaultValue] = useState({
    mulitLang: [],
    status: "",
    logo: "",
    sport_id: "",
    is_individual: "",
  })

  useEffect(() => {
    let multiLang = []
    languages.forEach((element) => {
      multiLang.push({
        code: element.code,
        label: element.name,
        name: "",
        description: "",
      })
    })
    setDefaultValue({
      ...defaultValus,
      mulitLang: multiLang,
    })
  }, [])

  useEffect(() => {
    if (params.team_id) {
      dispatch(getTeamDetailAsync(params.team_id))
    }
  }, [params.team_id])

  useEffect(() => {
    if (detail.team_id == params.team_id) {
      let multiLang = []
      languages.forEach((element) => {
        multiLang.push({
          code: element.code,
          label: element.name,
          name: detail.name[element.code],
          description: detail.description[element.code],
        })
      })
      setDefaultValue({
        logo: detail.logo,
        status: detail.status,
        sport_id: detail.sport_id,
        is_individual: detail.is_individual,
        mulitLang: multiLang,
      })
    }
  }, [detail])

  const handleSubmit = async (values: any, formMethod: any) => {
    if (params.team_id) {
      const data: any = {
        team_id: params.team_id,
        sport_id: values.sport_id,
        name: {},
        description: {},
        status: values.status,
        logo: values.logo,
        is_individual: values.is_individual,
      }
      values.mulitLang.forEach((element) => {
        data.name[element.code] = element.name
        data.description[element.code] = element.description
      })

      const res: any = await updateTeam(params.team_id, data)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        if (res.data.statusCode === 200 || res.data.statusCode === 201)
          alert.success("Team Updated Successfully")
        else alert.error("Something Gone Wrong")
        navigator("/team")
      }
    } else {
      const data: any = {
        sport_id: values.sport_id,
        name: {},
        description: {},
        status: values.status,
        logo: values.logo,
        is_individual: values.is_individual,
      }
      values.mulitLang.forEach((element) => {
        data.name[element.code] = element.name
        data.description[element.code] = element.description
      })
      const res: any = await createTeam(data)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        alert.success("Team Created Successfully")
        navigator("/team")
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Team</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/team">Teams</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.team_id ? "Edit " : "Add "}Team</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.team_id ? "Edit " : "Add "}Team</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={defaultValus}
                    validate={(values) => {
                      const errors: any = {}
                      if (!values.logo) {
                        errors.logo = "Required"
                      }
                      if (!values.sport_id) {
                        errors.sport_id = "Required"
                      }
                      if (
                        values.is_individual != "0" &&
                        values.is_individual != "1"
                      ) {
                        errors.is_individual = "Required"
                      }
                      if (!values.status) {
                        errors.status = "Required"
                      }
                      for (var i = 0; i < values.mulitLang.length; i++) {
                        if (!values.mulitLang[i].name && values.mulitLang[i].code == "en") {
                          if (!errors.mulitLang) {
                            errors.mulitLang = []
                          }
                          errors[`mulitLang.${i}.name`] = "Required"
                        }
                        // if (!values.mulitLang[i].description) {
                        //   if(!errors.mulitLang){
                        //     errors.mulitLang = []
                        //   }
                        //   errors[`mulitLang.${i}.description`] = "Required"
                        // }
                      }
                      return errors
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <FieldArray
                            name="mulitLang"
                            render={() => (
                              <div>
                                {values.mulitLang && values.mulitLang.length > 0
                                  ? values.mulitLang.map((mulitLang, index) => (
                                      <div key={index}>
                                        <h4 className="input-label">
                                          {mulitLang.label}
                                        </h4>

                                        <div className="row">
                                          <div
                                            key={index}
                                            className={"col-md-6"}
                                          >
                                            <div className="form-group mb-4">
                                              <div className="input-label">
                                                Name
                                              </div>
                                              <Field
                                                className="form-control"
                                                name={`mulitLang.${index}.name`}
                                                maxLength="50"
                                              />
                                              {touched.mulitLang &&
                                              touched.mulitLang[index] &&
                                              touched.mulitLang[index].name &&
                                              typeof errors[
                                                `mulitLang.${index}.name`
                                              ] === "string" ? (
                                                <div className="error">
                                                  {
                                                    errors[
                                                      `mulitLang.${index}.name`
                                                    ]
                                                  }
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div
                                            key={index}
                                            className={"col-md-6"}
                                          >
                                            <div className="form-group mb-4">
                                              <div className="input-label">
                                                Description
                                              </div>
                                              <Field
                                                as="textarea"
                                                maxLength="300"
                                                className="form-control"
                                                name={`mulitLang.${index}.description`}
                                              />
                                              {/* {touched.mulitLang &&
                                              touched.mulitLang[index] &&
                                              touched.mulitLang[index]
                                                .description &&
                                              typeof errors[
                                                `mulitLang.${index}.description`
                                              ] === "string" ? (
                                                <div className="error">
                                                  {
                                                    errors[
                                                      `mulitLang.${index}.description`
                                                    ]
                                                  }
                                                </div>
                                              ) : null} */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  : null}
                              </div>
                            )}
                          />

                          <div className={"col-md-6"}>
                            <FormField
                              name={"sport_id"}
                              fieldDetails={ModuleConfig.formFields.sport_id}
                              values={values}
                              errors={errors}
                              touched={touched}
                              disabled={params.team_id ? true : false}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>

                          <div className={"col-md-6"}>
                            <FormField
                              name={"logo"}
                              accept="image/png, image/jpeg"
                              fieldDetails={ModuleConfig.formFields.logo}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                          <div className={"col-md-6"}>
                            <FormField
                              name={"status"}
                              fieldDetails={ModuleConfig.formFields.status}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                          <div className={"col-md-6"}>
                            <FormField
                              name={"is_individual"}
                              fieldDetails={
                                ModuleConfig.formFields.is_individual
                              }
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TeamCreate
