import { Button, Container, Row, Table, Col } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import { getCMSDetailAsync, CMSState } from "../CMSSlice"
import { Formik } from "formik"
import { CMSDetailInterface } from "../models/CMSDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect, useState } from "react"
import { createCMS, updateCMS } from "../CMSAPI"
import Config from "../../../utils/config"
import { appState } from "../../../stores/appSlice"
import { useAlert } from "react-alert"

/**
 * CMSing Favourites list page
 */
const CMSCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(CMSState)
  const { languages } = useAppSelector(appState)
  const params = useParams()
  const navigator = useNavigate()
  const [en, setEn] = useState("")
  const alert = useAlert()

  useEffect(() => {
    if (params.cms_id) {
      dispatch(getCMSDetailAsync(params.cms_id))
    }
  }, [params.cms_id])

  const handleSubmit = async (values: CMSDetailInterface, formMethod: any) => {
    if (params.cms_id) {
      let postData = { ...values }
      delete postData.Sport

      postData.reference_id =
        postData.reference_id == "" ? null : postData.reference_id

      const res: any = await updateCMS(params.cms_id, postData)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else if (res.statusCode >= 300) {
        alert.error(res.message)
      } else {
        dispatch(getCMSDetailAsync(params.cms_id))
        navigator("/article")
      }
    } else {
      let postData = { ...values }
      delete postData.Sport

      postData.reference_id =
        postData.reference_id == "" ? null : postData.reference_id

      const res: any = await createCMS(postData)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        if (res?.statusCode == 201 || res?.statusCode == 200) {
          alert.success("Article Added Successfully")
        }
        navigator("/article")
      }
    }
  }

  const sportDetails = () => {
    return (
      <>
        <span>
          <img
            className="rounded-circle rounded-circle-border"
            height="50"
            width="50"
            src={`${Config.cdnUrl}${detail?.Sport?.logo}`}
          />{" "}
          {detail?.Sport?.sport_name?.en}{" "}
        </span>
      </>
    )
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Article</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/article">Article</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.cms_id ? "Edit " : "Add "}Article</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Row>
                <Col>
                  <h5 className="col-md-6">
                    {params.cms_id ? "Edit " : "Add "}Article
                  </h5>
                </Col>
                <Col>
                  <div className="text-end">
                    {params.cms_id ? sportDetails() : null}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {(params.cms_id && params.cms_id == detail.cms_id) ||
                  !params.cms_id ? (
                    <Formik
                      initialValues={
                        params.cms_id &&
                        detail[ModuleConfig.primaryKey] == params.cms_id
                          ? detail
                          : ModuleConfig.formDefaultValue
                      }
                      validate={(values) => {
                        const errors: any = {}
                        const fields = Object.keys(ModuleConfig.formFields)
                        for (let index = 0; index < fields.length; index++) {
                          if (
                            !values[fields[index]] &&
                            ModuleConfig.formFields[fields[index]].isRequired
                          ) {
                            errors[fields[index]] = "Required"
                          }
                        }
                        if (
                          !values["reference_id"] &&
                          values.language_id != Config?.enLanguageID
                        ) {
                          errors["reference_id"] = "Required"
                        }

                        return errors
                      }}
                      enableReinitialize={true}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            {Object.keys(ModuleConfig.formFields).map(
                              (field: string, index: number) => {
                                const fieldDetails =
                                  ModuleConfig.formFields[field]
                                if (
                                  values.language_id ==
                                    "aa618680-bd61-4692-8f1a-a75e5bbf3afb" &&
                                  values.reference_id != ""
                                ) {
                                  setFieldValue("reference_id", "")
                                }
                                return (fieldDetails.isAdd == false &&
                                  params.cms_id) ||
                                  fieldDetails.isAdd == undefined ? (
                                  <div
                                    key={`field_${index}`}
                                    className={fieldDetails.colWidth}
                                  >
                                    <FormField
                                      name={field}
                                      fieldDetails={fieldDetails}
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={fieldDetails.maxLength}
                                      disabled={
                                        (Config?.enLanguageID ==
                                          values?.language_id ||
                                          "" == values?.language_id) &&
                                        fieldDetails.disabled
                                      }
                                      setFieldValue={setFieldValue}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </div>
                                ) : null
                              }
                            )}
                          </div>
                          <hr />
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </form>
                      )}
                    </Formik>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CMSCreate
