import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { ReportDetailInterface } from "./models/ReportDetailInterface";
import {
  createReport,
  deleteReport,
  getReportDetail,
  getReportList,
  updateReport,
} from "./ReportAPI";

export interface ReportStateInterface {
  reports: ReportDetailInterface[];
  favourites: ReportDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: ReportDetailInterface;
}

const initialState: ReportStateInterface = {
  reports: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    name: "",
    image: "",
    status: "",
  },
};

export const getReportListAsync = createAsyncThunk(
  "report/list",
  async (data: any) => {
    const response = await getReportList(data);
    return response.data;
  }
);
export const getReportDetailAsync = createAsyncThunk(
  "report/detail",
  async (index: string) => {
    const response = await getReportDetail(index);
    return response.data;
  }
);

export const createReportAsync = createAsyncThunk(
  "report/create",
  async (data: ReportDetailInterface) => {
    const response = await createReport(data);
    return response.data;
  }
);

export const deleteReportAsync = createAsyncThunk(
  "report/delete",
  async (index: string) => {
    const response = await deleteReport(index);
    return response.data;
  }
);

export const updateReportAsync = createAsyncThunk(
  "report/update",
  async (data: { index: string; data: ReportDetailInterface }) => {
    const response = await updateReport(data.index, data.data);
    return response.data;
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getReportListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReportListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.reports = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getReportListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getReportDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReportDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getReportDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createReportAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createReportAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createReportAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateReportAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateReportAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateReportAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteReportAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteReportAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteReportAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = reportSlice.actions;
export const ReportState = (state: RootState) => state.report;
export default reportSlice.reducer;
