import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../stores"
import { PrizeDetailInterface } from "./models/PrizeDetailInterface"
import {
  getPrizeDetail,
  getPrizeList,
  updatePrize,
} from "./PrizeAPI"

export interface PrizeStateInterface {
  prizes: PrizeDetailInterface[]
  favourites: PrizeDetailInterface[]
  totalRecord: number
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  detail: PrizeDetailInterface
}

const initialState: PrizeStateInterface = {
  prizes: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    contest_winner_id: "",
    contest_id: "",
    user_id: "",
    credit: "",
    amount: "",
    status: "",
  },
}

export const getPrizeListAsync = createAsyncThunk(
  "prize",
  async (data: any) => {
    const response = await getPrizeList(data)
    return response.data
  }
)
export const getPrizeDetailAsync = createAsyncThunk(
  "/prize/get-by-id/:id",
  async (index: string) => {
    const response = await getPrizeDetail(index)
    return response.data.data
  }
)

export const updatePrizeAsync = createAsyncThunk(
  "prize/update",
  async (data: { index: string; data: PrizeDetailInterface }) => {
    const response = await updatePrize(data.index, data.data)
    return response.data
  }
)

export const prizeSlice = createSlice({
  name: "prize",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getPrizeListAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getPrizeListAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.prizes = action.payload.data
        state.totalRecord = action.payload.total
      })
      .addCase(getPrizeListAsync.rejected, (state) => {
        state.status = "failed"
      })
      // GET DETAIL
      .addCase(getPrizeDetailAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getPrizeDetailAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.detail = action.payload
      })
      .addCase(getPrizeDetailAsync.rejected, (state) => {
        state.status = "failed"
      })
      // UPDATE
      .addCase(updatePrizeAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(updatePrizeAsync.fulfilled, (state, action) => {
        state.status = "updated"
        state.detail = action.payload
      })
      .addCase(updatePrizeAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const {} = prizeSlice.actions
export const PrizeState = (state: RootState) => state.prize
export default prizeSlice.reducer
