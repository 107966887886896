import AxiosInstance from "../../utils/AxiosInstance";
import { LanguageDetailInterface } from "./models/LanguageDetailInterface";

const API_ROUTE = "languages";
export const getLanguageList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/language?${prm}`);
};

export const getLanguageDetail = async (index: string) => {
  return await AxiosInstance.get(`/language/get-by-id/${index}`);
};

export const createLanguage = async (data: LanguageDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/language/create`, data);
};

export const deleteLanguage = async (index: string) => {
  return await AxiosInstance.delete(`/language/delete-by-id/${index}`);
};
export const updateLanguage = async (index: string, data: LanguageDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.put(`/language/update`, data);
};
