const ModuleConfig = {
  primaryKey: "cms_id",

  formDefaultValue: {
    title: "",
    content: "",
    credit: 1,
    language_id: "",
    author_name: "",
    short_description: "",
    reference_id: null,
  },

  formFields: {
    title: {
      label: "Title",
      isRequired: true,
      colWidth: "col-md-8",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },
    credit: {
      label: "Credit",
      isRequired: true,
      colWidth: "col-md-2",
      inputType: "text",
      type: "number",
      maxLength: "20",
      min: 1,
      max: 999999,
    },
    language_id: {
      label: "Language",
      isRequired: false,
      colWidth: "col-md-2",
      inputType: "select",
      type: "select",
      placeholder: "Select Language",
      asynchLoad: true,
      dataUrl: "language",
      keyValue: "language_id",
      keylabel: "language_name",
      options: [],
    },
    reference_id: {
      label: "Parent Article",
      isRequired: false,
      colWidth: "col-md-2",
      inputType: "select",
      type: "select",
      placeholder: "Select Article",
      asynchLoad: true,
      disabled: true,
      dataUrl: "cms/cms-by-language",
      keyValue: "cms_id",
      keylabel: "title",
      options: [],
    },
    author_name: {
      label: "Author Name",
      isRequired: true,
      colWidth: "col-md-4",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },

    short_description: {
      label: "Short Description (max 300 characters)",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "textarea",
      type: "text",
      maxLength: "300",
    },

    sport_id: {
      label: "Sport",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Sport",
      asynchLoad: true,
      dataUrl: "sport/dropdown",
      filterParams:"filterField=status&filterValue=1",
      keyValue: "sport_id",
      keylabel: "sport_name",
      options: [],
    },

    content: {
      label: "Content",
      isRequired: true,
      colWidth: "col-md-12",
      inputType: "editor",
      type: "editor",
    },
  },

  listColumnOrder: [
    "title",
    "content",
    "credit",
    "language_id",
    "short_description",
    "author_name",
  ],
}

export default ModuleConfig
