const ModuleConfig = {
  primaryKey: "home_id",

  formDefaultValue: {
    cms_id: "",
    contest_id: "",
    media_credit_title: "",
    media_credit_description: "",
    contest_title: "",
    contest_description: "",
    welcome_title: "",
    section3_title: "",
    section3_description: "",
    section3_image: [],
    contact_us: "",
    contact_us_image: "",
    language_id: "",
    section3_bottom_text: "",
    section2_title: "",
  },

  formFields: {
    cms_id: {
      label: "CMS",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select CMS",
      asynchLoad: true,
      dataUrl: "cms",
      keyValue: "cms_id",
      keylabel: "title",
      options: [],
    },

    contest_id: {
      label: "Contest",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Contest",
      asynchLoad: true,
      dataUrl: "contest",
      filterParams: "",
      keyValue: "contest_id",
      keylabel: "title",
      options: [],
    },

    media_credit_title: {
      label: "Media Credit Title",
      isRequired: true,
      colWidth: "col-md-6",
      type: "text",
    },

    contest_title: {
      label: "Contest Title",
      isRequired: true,
      colWidth: "col-md-6",
      type: "text",
    },

    media_credit_description: {
      label: "Media Credit Description",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "editor",
      type: "editor",
    },

    contest_description: {
      label: "Contest Description",
      isRequired: true,
      colWidth: "col-md-6",
      type: "editor",
      inputType: "editor",
    },

    welcome_title: {
      label: "Welcome Title",
      isRequired: true,
      colWidth: "col-md-6",
      type: "text",
    },

    section2_title: {
      label: "Section 2 Title",
      isRequired: true,
      colWidth: "col-md-6",
      type: "text",
    },

    section3_title: {
      label: "Section 3 Title",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
    },

    section3_description: {
      label: "Section 3 Description",
      isRequired: true,
      colWidth: "col-md-6",
      type: "textarea",
      inputType: "textarea",
    },

    section3_image: {
      label: "Section 3 Image",
      isRequired: true,
      colWidth: "col-md-6",
      type: "array",
      inputType: "array",
      canAddMore: true,
      addMoreFields: {
        section3_image: "",
      },
      fields: [
        {
          section3_image: {
            label: "Section 3 Image",
            isRequired: true,
            multiple: true,
            colWidth: "col-md-6",
            type: "file",
            inputType: "file",
          },
        },
      ],
    },

    contact_us: {
      label: "Contact Us",
      isRequired: true,
      colWidth: "col-md-6",
      type: "text",
    },

    contact_us_image: {
      label: "Contact Us Image",
      isRequired: true,
      colWidth: "col-md-6",
      type: "file",
      inputType: "file",
    },

    language_id: {
      label: "Language",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Language",
      asynchLoad: true,
      dataUrl: "language",
      keyValue: "language_id",
      keylabel: "language_name",
      options: [],
    },

    section3_bottom_text: {
      label: "Section 3 Button Text",
      isRequired: true,
      colWidth: "col-md-6",
      type: "text",
    },
  },

  listColumnOrder: [
    "cms_id",
    "contest_id",
    "media_credit_title",
    "media_credit_description",
    "contest_title",
    "contest_description",
    "welcome_title",
    "section3_title",
    "section3_description",
    "section3_image",
    "contact_us",
    "contact_us_image",
    "language_id",
    "section3_bottom_text",
    "section2_title",
  ],
}

export default ModuleConfig
