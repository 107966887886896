const ModuleConfig = {
  primaryKey: "page_id",

  formDefaultValue: {
    title: "",
    slug: "",
    content: "",
    language_id: "",
  },

  formFields: {
    title: {
      label: "Title",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength:"50"
    },

    slug: {
      label: "Slug",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      disabled : true,
      maxLength:"50"
    },

    language_id: {
      label: "Language",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Language",
      asynchLoad: true,
      dataUrl: "language",
      keyValue:"language_id",
      keylabel:"language_name",
      options: [],
    },

    content: {
      label: "Content",
      isRequired: true,
      colWidth: "col-md-12",
      type: "editor",
      inputType: "editor",
    },
  },

  listColumnOrder: ["slug", "content","title"],
};

export default ModuleConfig;
