import AxiosInstance from "../../utils/AxiosInstance";
import { SponsorshipDetailInterface } from "./models/SponsorshipDetailInterface";

const API_ROUTE = "sponsorships";
export const getSponsorshipList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/sponsorship?${prm}`);
};

export const getSponsorshipDetail = async (index: string) => {
  return await AxiosInstance.get(`/sponsorship/get-by-id/${index}`);
};

export const createSponsorship = async (data: SponsorshipDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/sponsorship/create`, data);
};

export const deleteSponsorship = async (index: string) => {
  return await AxiosInstance.delete(`/sponsorship/delete-by-id/${index}`);
};
export const updateSponsorship = async (index: string, data: SponsorshipDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.put(`/sponsorship/update`, data);
};
