import { Button, Container, Row, Table } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"
import {
  getAchievementDetailAsync,
  AchievementState,
} from "../AchievementSlice"
import { Field, FieldArray, Formik } from "formik"
import { AchievementDetailInterface } from "../models/AchievementDetailInterface"
import ModuleConfig from "../config/ModuleConfig"
import FormField from "../../common/FormField"
import { useEffect, useState } from "react"
import { createAchievement, updateAchievement } from "../AchievementAPI"
import FileInput from "../../common/FormField/FileInput"
import { appState } from "../../../stores/appSlice"
import { useAlert } from "react-alert"

/**
 * Achievementing Favourites list page
 */
const AchievementCreate = () => {
  const dispatch = useAppDispatch()
  const { status, detail } = useAppSelector(AchievementState)
  const { languages } = useAppSelector(appState)

  const params = useParams()
  const navigator = useNavigate()
  const alert = useAlert()

  const [defaultValus, setDefaultValue] = useState({
    catalog_url: "",
    event_title: "",
    event_description: "",
    type: "",
    contest_id: null,
    credit: null,
    event_image: "",
    shopping_amount: null,
  })

  useEffect(() => {
    setDefaultValue({
      ...defaultValus,
    })
  }, [])

  useEffect(() => {
    if (params.achievement_id) {
      dispatch(getAchievementDetailAsync(params.achievement_id))
    }
  }, [params.achievement_id])

  useEffect(() => {
    if (detail?.achievement.achievement_id == params.achievement_id) {
      setDefaultValue({
        event_image: detail?.achievement.event_image,
        contest_id: detail?.achievement.contest_id,
        catalog_url: detail?.achievement.catalog_url,
        event_title: detail?.achievement.event_title,
        event_description: detail?.achievement.event_description,
        type: detail?.achievement.type,
        credit: detail?.achievement.credit,
        shopping_amount: detail?.achievement.shopping_amount,
      })
    }
  }, [detail])

  const handleSubmit = async (values: any, formMethod: any) => {
    if (params.achievement_id) {
      const data: any = {
        achievement_id: params.achievement_id,
        contest_id: values.contest_id,
        event_title: values.event_title,
        event_description: values.event_description,
        type: values.type,
        event_image: values.event_image,
        credit: values.credit,
        catalog_url: values.catalog_url,
        shopping_amount: values.shopping_amount,
      }

      const res: any = await updateAchievement(params.achievement_id, data)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else {
        if (res.data.statusCode === 200 || res.data.statusCode === 201)
          alert.success("Achievement Updated Successfully")
        else alert.error("Something Gone Wrong")
      }
      navigator("/achievement")
    } else {
      const data: any = {
        contest_id: values.contest_id,
        event_title: values.event_title,
        event_description: values.event_description,
        type: values.type,
        event_image: values.event_image,
        credit: values.credit || 0,
        catalog_url: values.catalog_url,
        shopping_amount: values.shopping_amount || 0,
      }
      const res: any = await createAchievement(data)
      if (res.errors && res.message) {
        const errors: any = {}
        const errorKeys = Object.keys(res.errors)
        for (var i = 0; i < errorKeys.length; i++) {
          errors[errorKeys[i]] = res.errors[errorKeys[i]][0]
        }
        formMethod.setErrors(errors)
      } else if (res.status == 200 || res.status == 201) {
        alert.success("Achievement Created Successfully")
        navigator("/achievement")
      } else {
        alert.error(res?.message)
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Achievement</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/achievement">Achievements</Link>
                </li>
                <li className="breadcrumb-item">
                  <a>{params.achievement_id ? "Edit " : "Add "}Achievement</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>{params.achievement_id ? "Edit " : "Add "}Achievement</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    initialValues={defaultValus}
                    validate={(values) => {
                      const errors: any = {}
                      if (!values.event_title) {
                        errors.event_title = "Required"
                      }
                      if (!values.event_image) {
                        errors.event_image = "Required"
                      }
                      if (!values.type) {
                        errors.type = "Required"
                      }
                      if (!values.event_description) {
                        errors.event_description = "Required"
                      }
                      if (values.type == "Entrant" || values.type == "Winner") {
                        if (!values.contest_id) {
                          errors.contest_id = "Required"
                        }
                        values.credit = null
                        values.shopping_amount = null
                      }
                      if (values.type == "Media credit buy") {
                        if (!values.credit) {
                          errors.credit = "Required"
                        }
                        values.contest_id = null
                      }
                      if (!values.catalog_url) {
                        errors.catalog_url = "Required"
                      }
                      return errors
                    }}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className={"col-md-6"}>
                            <FormField
                              name={"event_title"}
                              fieldDetails={ModuleConfig.formFields.event_title}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                          <div className={"col-md-6"}>
                            <FormField
                              name={"event_description"}
                              fieldDetails={
                                ModuleConfig.formFields.event_description
                              }
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>

                          <div className={"col-md-6"}>
                            <FormField
                              name={"catalog_url"}
                              fieldDetails={ModuleConfig.formFields.catalog_url}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                          <div className={"col-md-6"}>
                            <FormField
                              name={"type"}
                              fieldDetails={ModuleConfig.formFields.type}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              disabled={params.achievement_id}
                            />
                          </div>
                          {(values.type == "Entrant" ||
                            values.type == "Winner") && (
                            <div className={"col-md-6"}>
                              <FormField
                                name={"contest_id"}
                                fieldDetails={
                                  ModuleConfig.formFields.contest_id
                                }
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disabled={params.achievement_id}
                              />
                            </div>
                          )}
                          {values.type == "Media credit buy" && (
                            <div className={"col-md-6"}>
                              <FormField
                                name={"credit"}
                                fieldDetails={ModuleConfig.formFields.credit}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disabled={params.achievement_id}
                              />
                            </div>
                          )}

                          {values.type == "Media credit used" && (
                            <>
                              <div className={"col-md-6"}>
                                <FormField
                                  name={"credit"}
                                  fieldDetails={ModuleConfig.formFields.credit}
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  disabled={params.achievement_id}
                                />
                              </div>
                              <div className={"col-md-6"}>
                                <FormField
                                  name={"shopping_amount"}
                                  fieldDetails={
                                    ModuleConfig.formFields.shopping_amount
                                  }
                                  values={values}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  disabled={params.achievement_id}
                                />
                              </div>
                            </>
                          )}
                          <div className={"col-md-6"}>
                            <FormField
                              name={"event_image"}
                              accept="image/png, image/jpeg"
                              fieldDetails={ModuleConfig.formFields.event_image}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <hr />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={status === "loading"}
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AchievementCreate
