import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Form from "react-bootstrap/Form"
import ReportList from "./ReportList"
import UserReportList from "../../userreport/pages/UserReportList"
/**
 * Reporting list page
 */
const Report = () => {
  const [reportType, setReportType] = useState("userReport")

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10"> </h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Reports</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col auto>
                      <h5>Reports</h5>
                    </Col>
                  </Row>
                </div>
                <div className="card-block table-border-style">
                  <Row>
                    <div className="col-12">
                      <div className="col-md-12">
                        <p className="white_color mt-4 mb-0">Report Type</p>
                      </div>
                      <Form>
                        <div key={`inline-radio`} className="mb-3">
                          <Form.Check
                            defaultChecked
                            inline
                            label="User Report"
                            name="group1"
                            type="radio"
                            id={`inline-radio-1`}
                            onChange={() => {
                              setReportType("userReport")
                            }}
                          />
                          <Form.Check
                            inline
                            label="Contest Report"
                            name="group1"
                            type="radio"
                            id={`inline-radio-2`}
                            onChange={() => {
                              setReportType("roundReport")
                            }}
                          />
                        </div>
                      </Form>
                    </div>
                  </Row>

                  {reportType === "roundReport" ? (
                    <ReportList />
                  ) : (
                    <UserReportList />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Report
