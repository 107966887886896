import AxiosInstance from "../../utils/AxiosInstance";
import { ContestRoundDetailInterface } from "./models/ContestRoundDetailInterface";

const API_ROUTE = "contestrounds";
export const getContestRoundList = async (params : any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/contest-round?${prm}`);
};

export const getContestRoundDetail = async (index: string) => {
  return await AxiosInstance.get(`/contest-round/get-by-id/${index}`);
};

export const getContestRoundDetailByContestId = async (index: string) => {
  return await AxiosInstance.get(`/contest-round/get-by-contest-id/${index}`);
};

export const createContestRound = async (data: ContestRoundDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/contest-round/create`, data);
};

export const deleteContestRound = async (index: string) => {
  return await AxiosInstance.delete(`/contest-round/delete-by-id/${index}`);
};
export const updateContestRound = async (index: string, data: ContestRoundDetailInterface) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.put(`/contest-round/update`, data);
};
