import { Route } from "react-router-dom";
import PageCreate from "./pages/PageCreate";
import PageList from "./pages/PageList";

/**
 * This will return all routes for the specific module
 */
const MODULE_ROUTE = "page"; 
const PageRoute = [
    <Route path={`${MODULE_ROUTE}`} element={<PageList />} key={`${MODULE_ROUTE}_index`} />,
    <Route path={`${MODULE_ROUTE}/create`} element={<PageCreate />}  key={`${MODULE_ROUTE}_create`}  />,
    <Route path={`${MODULE_ROUTE}/update/:id`} element={<PageCreate />} key={`${MODULE_ROUTE}_edit`} />,
  ];

export default PageRoute;