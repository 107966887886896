import AxiosInstance from "../../utils/AxiosInstance"
import { PaymentDetailInterface } from "./models/PaymentDetailInterface"

const API_ROUTE = "payments"
export const getPaymentList = async (params: any) => {
  const prm = new URLSearchParams(params).toString()
  return await AxiosInstance.get(`/user-payment?${prm}`)
}

export const getPaymentDetail = async (index: string) => {
  return await AxiosInstance.get(`/user-payment/get-by-id//${index}`)
}

export const createPayment = async (data: PaymentDetailInterface) => {
  const formData = new FormData()
  const keys = Object.keys(data)
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]])
  }

  return await AxiosInstance.post(`/${API_ROUTE}`, formData)
}

export const deletePayment = async (index: string) => {
  return await AxiosInstance.delete(`/${API_ROUTE}/${index}`)
}
export const updatePayment = async (
  index: string,
  data: PaymentDetailInterface
) => {
  // const formData = new FormData();
  // const keys = Object.keys(data);
  // for (var i = 0; i < keys.length; i++) {
  //   formData.append(keys[i], data[keys[i]]);
  // }

  return await AxiosInstance.post(`/${API_ROUTE}/${index}`, data)
}

export const getApplyCreditsList = async (index: string) => {
  return await AxiosInstance.get(`/contest-credit/get-by-user-payment-id/${index}`)
}

export const updateApplyCreditsList = async (data: any) => {
  return await AxiosInstance.post(`/contest-credit/re-allocate`, data)
}
