import { getPropByString } from "../../../utils/CommonFunction"
import config from "../../../utils/config"
import S3 from "react-aws-s3"
import uploadFileToBlob from "../../../utils/AzureStorageBlob"
import Config from "./../../../utils/config"
import { useState } from "react"
window.Buffer = window.Buffer || require("buffer").Buffer

const FileInput = (props: any) => {
  var values = getPropByString(props?.values, props?.name)

  const [loading , setLoading] = useState(false)

  if (loading) {
    return (
      <div className="app-loader">
        <div className="loader"></div>
      </div>
    )
  }
  return (
    <div className="input-group">
      <input
        type={"file"}
        id={props.name}
        multiple={props.multiple}
        className="form-control"
        onBlur={props.handleBlur}
        accept={props?.accept}
        disabled={props?.disabled}
        onChange={async (event: any) => {
          setLoading(true)
          const res = await uploadFileToBlob(event.target.files[0], "images")
          if (event.target.files) {
            props.setFieldValue(props.name, res)
            setLoading(false)
          } else {
            props.setFieldValue(props.name, null)
            setLoading(false)
          }
        }}
        aria-describedby="basic-addon2"
      />

      {values ? (
        <span className="input-group-text" id="basic-addon2">
          <a
            href={`${Config.cdnUrl}${values}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="feather icon-eye"></i>
          </a>
        </span>
      ) : null}
    </div>
  )
}

export default FileInput
