import react from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';

const   CDatePicker = (props: any) => {

  const selected = props.values[props.name]
    ? new Date(props.values[props.name])
   : new Date()
  return (
    <DatePicker
      className="form-control"
      selected={selected}
      onChange={(date) => {
        props.setFieldValue(props.name, date);
      }}
      name={props.name}
      onFocus={props.handleBlur}
      disabled={props.disabled}
      minDate={moment().toDate()}
      showTimeSelect={true}
      dateFormat="MM-d-yyyy h:mm aa"
    />
  )
  //     <input
  //         type={"date"}
  //         name={props.name}
  //         id={props.name}
  //         value={props.values[props.name]}
  //         className="form-control"
  //         onBlur={props.handleBlur}
  //         onChange={props.handleChange}
  //         placeholder={props.fieldDetails.label} />
  // )
}

export default CDatePicker
