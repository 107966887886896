import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../stores";
import { ProductDetailInterface } from "./models/ProductDetailInterface";
import {
  createProduct,
  deleteProduct,
  getProductDetail,
  getProductList,
  updateProduct,
} from "./ProductAPI";

export interface ProductStateInterface {
  products: ProductDetailInterface[];
  favourites: ProductDetailInterface[];
  totalRecord: number;
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  detail: ProductDetailInterface;
}

const initialState: ProductStateInterface = {
  products: [],
  favourites: [],
  totalRecord: 0,
  status: "idle",
  detail: {
    id: "",
    description: "",
    title: "",
    image: ""
  },
};

export const getProductListAsync = createAsyncThunk(
  "product/list",
  async (data: any) => {
    const response = await getProductList(data);
    return response.data;
  }
);
export const getProductDetailAsync = createAsyncThunk(
  "product/detail",
  async (index: string) => {
    const response = await getProductDetail(index);
    return response.data;
  }
);

export const createProductAsync = createAsyncThunk(
  "product/create",
  async (data: ProductDetailInterface) => {
    const response = await createProduct(data);
    return response.data;
  }
);

export const deleteProductAsync = createAsyncThunk(
  "product/delete",
  async (index: string) => {
    const response = await deleteProduct(index);
    return response.data;
  }
);

export const updateProductAsync = createAsyncThunk(
  "product/update",
  async (data: { index: string; data: ProductDetailInterface }) => {
    const response = await updateProduct(data.index, data.data);
    return response.data;
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // GET LIST
      .addCase(getProductListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.products = action.payload.data;
        state.totalRecord = action.payload.total;
      })
      .addCase(getProductListAsync.rejected, (state) => {
        state.status = "failed";
      })
      // GET DETAIL
      .addCase(getProductDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
      })
      .addCase(getProductDetailAsync.rejected, (state) => {
        state.status = "failed";
      })
      // CREATE
      .addCase(createProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createProductAsync.fulfilled, (state, action) => {
        state.status = "created";
        state.detail = action.payload;
      })
      .addCase(createProductAsync.rejected, (state) => {
        state.status = "failed";
      })
      // UPDATE
      .addCase(updateProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProductAsync.fulfilled, (state, action) => {
        state.status = "updated";
        state.detail = action.payload;
      })
      .addCase(updateProductAsync.rejected, (state) => {
        state.status = "failed";
      })
      // DELETE
      .addCase(deleteProductAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProductAsync.fulfilled, (state, action) => {
        state.status = "deleted";
        state.detail = action.payload;
      })
      .addCase(deleteProductAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {} = productSlice.actions;
export const ProductState = (state: RootState) => state.product;
export default productSlice.reducer;
