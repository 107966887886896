import { Link, useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import {
  ContestRoundGameState,
  getContestRoundGameDetailByRoundIdAsync,
} from "../ContestRoundGameSlice"
import { useAppDispatch, useAppSelector } from "../../../stores/hooks"

const ContestRoundGameResult = () => {
  const { contestroundgames, status, totalRecord } = useAppSelector(
    ContestRoundGameState
  )

  const params = useParams()
  const navigator = useNavigate()
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [selectTeam, setSelectTeam] = useState({})
  const [selectResult, setSelectResult] = useState({})

  useEffect(() => {
    getPageData()
  }, [dispatch, page])

  const getPageData = () => {
    if (params.contest_round_id) {
      dispatch(getContestRoundGameDetailByRoundIdAsync(params.contest_round_id))
    }
  }

  const handleChange = (id, value) => {
    setSelectTeam((selectTeam) => ({...selectTeam,  [id] : value }))
  }

  const handleSubmit = () => {
    var data = {
      status: selectResult,
      selectTeam: selectTeam,
    }
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Contest Round Game</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="feather icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/contest">Contests</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/contest-round/${params.contest_id}`}>
                    Contest Round
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a>Contest Round Games</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="main-body">
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-block table-border-style">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <th>Team 1</th>
                        <th>VS</th>
                        <th>Team 2</th>
                        <th>Result</th>
                      </thead>
                      <tbody>
                        {contestroundgames && contestroundgames?.length > 0
                          ? contestroundgames.map((item: any, index) => {
                              return (
                                <tr
                                  key={
                                    `result_` +
                                    index +
                                    item?.contest_round_game_id
                                  }
                                  className="unread"
                                >
                                  <td>
                                    <input
                                      type="radio"
                                      name={"winner_team_id_" + index}
                                      id={"winner_team_id_" + index}
                                      // checked={selectTeam == item?.team1?.team_id}
                                      onChange={(e) => {
                                        handleChange(
                                          index,
                                          e.target.value
                                        )
                                      }}
                                      value={item?.team1?.team_id}
                                    />
                                    <label className="m-l-10">
                                      {item?.team1?.name}
                                    </label>
                                  </td>
                                  <td>VS</td>
                                  <td>
                                    <input
                                      type="radio"
                                      name={"winner_team_id_" + index}
                                      // checked={selectTeam == item?.team1?.team_id}
                                      onChange={(e) => {
                                        handleChange(
                                          index,
                                          e.target.value
                                        )
                                      }}
                                      value={item?.team2?.team_id}
                                      id={"winner_team_id_" + index}
                                    />
                                    <label className="m-l-10">
                                      {item?.team2?.name}
                                    </label>
                                  </td>
                                  <td>
                                    <select
                                      className="form-control"
                                      placeholder="Set Result"
                                      name={"status_" + index}
                                      id={"status_" + index}
                                      // value={selectResult}
                                      onChange={(e) => {
                                        setSelectResult(e.target.value)
                                      }}
                                    >
                                      <option value="">Select Result</option>
                                      <option value="Result">Result</option>
                                      <option value="No result">
                                        No Result
                                      </option>
                                    </select>
                                  </td>
                                </tr>
                              )
                            })
                          : ""}
                      </tbody>
                    </table>
                    <button
                      className="text-center btn btn-primary shadow-2 mb-4 mt-4"
                      type="submit"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ContestRoundGameResult
