const ModuleConfig = {
  primaryKey: "video_id",

  formDefaultValue: {
    title: "",
    description: "",
    credit: null,
    language_id: "",
    author: "",
    video_blob: null,
    sport_id: "",
    Sport: {},
    Language: {},
  },

  formFields: {
    title: {
      label: "Title",
      isRequired: true,
      colWidth: "col-md-8",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },
    credit: {
      label: "Credit",
      isRequired: true,
      colWidth: "col-md-2",
      inputType: "text",
      type: "number",
      maxLength: "20",
      min: 1,
      max: 999999,
    },
    language_id: {
      label: "Language",
      isRequired: true,
      colWidth: "col-md-2",
      inputType: "select",
      type: "select",
      placeholder: "Select Language",
      asynchLoad: true,
      dataUrl: "language",
      keyValue: "language_id",
      keylabel: "language_name",
      options: [],
    },
    description: {
      label: "Short Description (max 300 characters)",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "textarea",
      type: "text",
      maxLength: "300",
    },
    author: {
      label: "Author",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "text",
      type: "text",
      maxLength: "50",
    },
    sport_id: {
      label: "Sport",
      isRequired: true,
      colWidth: "col-md-6",
      inputType: "select",
      type: "select",
      placeholder: "Select Sport",
      asynchLoad: true,
      dataUrl: "sport/dropdown",
      filterParams: "filterField=status&filterValue=1",
      keyValue: "sport_id",
      keylabel: "sport_name",
      options: [],
    },
    // video_blob: {
    //   label: "Video",
    //   isRequired: true,
    //   colWidth: "col-md-6",
    //   inputType: "file",
    //   type: "file",
    //   maxLength: "50",
    //   accept: "video/*",
    // },
  },

  listColumnOrder: [
    "title",
    "credit",
    "language_id",
    "description",
    "author",
    "sport_id",
    // "video_blob",
  ],
}

export default ModuleConfig
